import SmartHeader from "../../../../components/site/SmartHeader";
import {
  CROP_CUSTOMER_CONTACT_US,
  CROP_CUSTOMER_CONTACT_US_LIGHT_RED_ICON,
  CROP_CUSTOMER_CONTACT_US_RED_ICON,
  CROP_CUSTOMER_CONTACT_US_SKY_BLUE_ICON,
  CROP_CUSTOMER_HELP_CONTACT_US,
} from "../../../../services/ImageService";
import "./ContactUs.css";

const ContactUs = () => {
  const sendMail = (e, index) => {
    let mail_to_send = "admin@cropglobalservices.com";
    const mailto = "mailto:" + mail_to_send;
    window.location.href = mailto;
    e.preventDefault();
  };

  const sendMailInfo = (e, index) => {
    let mail_to_send = "info@cropglobalservices.com";
    const mailto = "mailto:" + mail_to_send;
    window.location.href = mailto;
    e.preventDefault();
  };
  const card_display = (title, description, cardClass) => {
    return (
      <div className={"columns is-flex-mobile " + cardClass}>
        <div className="column is-9">
          <h1 className="is-size-5 has-text-weight-bold">{title}</h1>
          <p className="is-size-7">{description}</p>
        </div>
      </div>
    );
  };

  const card_one = {
    title: "Technical Support",
    description:
      // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      "For any issues related to system performance and technical troubleshooting.",
  };

  const card_two = {
    title: "General Enquiries",
    description:
      // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      "For all general queries related to your account and membership.",
  };

  const card_three = {
    title: "Complaint Follow-up",

    description:
      // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      "For any complaint that is unresolved beyond agreed turnaround time.",
  };

  const card_four = {
    title: "Business Enquiries",
    description:
      // "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      "For any queries related to business of CROP and its participating brands.",
  };

  return (
    <>
      <div className="px-5 m-5">
        <SmartHeader title={"Contact Us"} />
      </div>
      <div className="columns ">
        <div className="column "></div>
      </div>
      <div className=" has-text-weight-bold  mb-2 has-text-centered customer-header">
        Contact Us
      </div>

      <div className="columns  is-multiline m-5 is-centered">
        <div
          onClick={(event) => sendMail(event)}
          className="column  is-flex is-4 ContactUs-background ml-4 mr-4 mb-3 pointer"
        >
          <div className="p-3  ">
            <img
              className="pointer contactus-image-size"
              src={CROP_CUSTOMER_CONTACT_US}
              alt="customer_contact_us"
              height="120"
              width="120"
            />
          </div>
          <div className="pt-3">
            {card_display(card_one.title, card_one.description)}
          </div>
        </div>

        <div
          onClick={(event) => sendMailInfo(event)}
          className="is-flex  column is-4 ContactUs-background mobile-ContactUs-media ml-4 mr-4 mb-3 pointer"
        >
          <div className="p-3">
            <img
              className="contactus-image-size pointer"
              src={CROP_CUSTOMER_CONTACT_US_RED_ICON}
              alt="red_icon"
              height="100"
              width="100"
            />
          </div>
          <div className="pt-3">
            {card_display(card_two.title, card_two.description)}
          </div>
        </div>

        <div
          onClick={(event) => sendMail(event)}
          className="is-flex column is-4 ContactUs-background  ml-4 mr-4 mb-3 pointer "
        >
          <div className="p-3">
            <img
              src={CROP_CUSTOMER_CONTACT_US_LIGHT_RED_ICON}
              alt="light_red_icon"
              height="110"
              className="pointer contactus-image-size"
              width="110"
            />
          </div>
          <div className="pt-3">
            {card_display(card_three.title, card_three.description)}
          </div>
        </div>

        <div
          onClick={(event) => sendMailInfo(event)}
          className="is-flex column is-4 ContactUs-background  ml-4 mr-4 mb-3 pointer "
        >
          <div className="p-3">
            <img
              src={CROP_CUSTOMER_CONTACT_US_SKY_BLUE_ICON}
              alt="sky_blue_icon"
              height="110"
              className="pointer contactus-image-size"
              width="110"
            />
          </div>
          <div className="pt-3">
            {card_display(card_four.title, card_four.description)}
          </div>
        </div>
      </div>
      <div className="ContactUs-Image">
        <img
          src={CROP_CUSTOMER_HELP_CONTACT_US}
          alt="help_contact_us"
          className="ContactUs-Image pointer"
        />
      </div>
    </>
  );
};

export default ContactUs;
