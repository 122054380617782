import { useEffect, useState } from "react";
// import {
//   CROP_PROMO_IMAGE,
// } from "../../../../services/ImageService";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_OFFERS } from "../../../../api/OfferUrls";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import ProductDisplay from "../../../../components/site/ProductDisplay";
import StarDisplay from "../../../../components/site/StarDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { CROP_CUSTOMER_CROP_IMAGE } from "../../../../services/ImageService";
import {
  showAlertAutoClose
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import ProductQuickView from "./ProductQuickView";

const PromosView = () => {
  // const { type, id } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  // const [firstElement, setFirstElement] = useState({});
  const {
    setLoading,
    user,
    closeModal,
    openModal,
    handleUserChange,
    refreshUser,
  } = useSiteContext();

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      //showNotification("error", errorMessage);
      setData([]);
      setLoading(false);
    };
    const subscription = post(
      CUSTOMER_OFFERS.GET_PROMOS,
      {},
      handleError
    ).subscribe((response) => {
      setData(response.data);

      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const loginCheck = () => {
    return user ? true : false;
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const updateCart = (id, type) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      //setData([])
    };
    setLoading(true, "Loading.... Please Wait");
    let url = CUSTOMER_OFFERS.ADD_TO_CART;
    let offer_type = "EARN";
    let data_in = { sd_offers_id: id, type: type, offer_type: offer_type };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // console.log("response " , response);
        let msg = response.data.msg;
        setLoading(false);
        showAlertAutoClose(msg, "success");

        if (handleUserChange) {
          // console.log("response data updated the cart data" )
          handleUserChange("cart", response.data.cart);
          handleUserChange("wishlist", response.data.wish);
        }
        //updateItemLikes(id,1);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const openNewForm = (item) => {
    let modelObject = {
      body: (
        <ProductQuickView
          item={item}
          setLoading={setLoading}
          offer_type={"EARN"}
          closeModal={closeModal}
          loginCheck={loginCheck}
          refreshUser={refreshUser}
          navigate={navigate}
        />
      ),
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const offer_image = (data) => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data.image_id !== undefined ? data.image_id : 0;
    return (
      <div className="isLoaded">
        <SmartImageDisplay
          srcType="URL"
          url={url}
          id={id}
          imageClass="promo-images"
          cacheEnable={true}
        />
      </div>
    );
  };
  // const first_element = data.slice(0, 1);
  // console.log("first_element",first_element)
  return (
    <>
      <div className="customer-promo-view-container customer-primary-font">
        <div className="container my-6">
          <div className="columns is-multiline Promos-media-screen ">
            <div className="column is-12 is-flex is-justify-content-space-between  ">
              <div className="smart-customer-promos-crop-text">
                <div className="left-border-earn-crop"></div>Promos
              </div>
              <div className=" has-text-right">
               
              </div>
            </div>

            {/* <>
              {data && data.length > 0 && (
                <div className="column is-4 mb-1">
                  {data.slice(0, 1)?.map((item) => (
                    <>
                      <div className="columns is-multiline">
                        <div className="column is-12 ">
                          <div
                            className="promo-image"
                            onClick={() => openNewForm(item)}
                          >
                            {offer_image(item)}
                          </div>
                        </div>
                        <div className="column is-12">
                          <div className="promos-item-details-container ">
                            <div
                              className="promos-item-title promos-offer-title is-size-5 ml-4  mb-2"
                              onClick={() => openNewForm(item)}
                            >
                              {item.offer_title}
                            </div>
                          </div>

                          <div className="columns is-flex media-screen-promo-start">
                            <div className="column p-0 m-0 promos-item-star-rating is-flex ml-5">
                              <StarDisplay item={item} />
                              <span className="mt-1">0</span>
                            </div>

                            <div className=" column p-0 m-0 promos-item-price ml-5">
                              <div className="mt-2">
                                A$ {item.price}
                              </div>
                            </div>
                            <div className="column p-0 m-0 promos-item-crops-price ">
                              <div className="promos-item-price is-flex ml-4 mt-2">
                                <img
                                  className="image product-crop-image "
                                  src={CROP_CUSTOMER_CROP_IMAGE}
                                  alt=""
                                />
                                <span className="ml-1">
                                  {" "}
                                  {item.earn_crop_points}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="columns promos-items-view-bo is-flex media-screen-promo-eye ">
                            <div
                              className=" column promos-active-buttons "
                              onClick={() => openNewForm(item)}
                            >
                              <i
                                className="fa fa-eye mr-6"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div className=" column promos-active-buttons mr-5">
                              <i
                                onClick={() => updateCart(item.ID, "WISHLIST")}
                                className="fa fa-heart-o "
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div className=" column promos-active-buttons mr-5 ">
                              <i
                                onClick={() => updateCart(item.ID, "CART")}
                                className="fa fa-shopping-cart mr-1"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </> */}

            <div className="column is-12">
              <div className="columns is-multiline">
                {data &&
                  data.slice(0, 12)?.map((item, index) => {
                    return (
                      <div className="column is-3 pl-5 pr-5 pb-3" key={index}>
                        <ProductDisplay
                          item={item}
                          setLoading={setLoading}
                          setData={setData}
                          offer_type={"EARN"}
                          loginCheck={loginCheck}
                          closeModal={closeModal}
                          refreshUser={refreshUser}
                          navigate={navigate}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromosView;
