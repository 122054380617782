import { CROP_LOGO, CROP_PAID } from '../../../services/ImageService';
const MyCropTax = () => {
    return (
      <>
        <div className="container mx-6 my-6">
          <div>
            <img src={CROP_LOGO} alt="logo" />
          </div>
          <div className="columns crop-services-background has-text-white">
            <div className=" column is-6">
              <p>CROP SERVICE PTY LTD</p>
              <p>ABN 4366424011</p>
            </div>
            <div className="column is-5 has-background-white has-text-black">
              <p className="is-size-3 has-text-weight-semibold is-flex is-justify-content-center">
                SALES INVOICE
              </p>
            </div>
            <div className="column is-1"></div>
          </div>
          <div>{MyCropsBilling()}</div>
        </div>
      </>
    );
  };

  const MyCropsBilling = () => {
    return (
      <>
        <div className="container">
          <div className="columns has-text-weight-semibold">
            <div className="column">
              <p>Billing Address:</p>
              <div className="column is-2 is-offset-10">
                <p>Invoice No: 0001</p>
                <p>Date: 10-Jul-23</p>
              </div>
              <div className="columns">
                <div className="column is-8">
                  <div className="mb-4">
                    <p>Bill To:</p>
                    <p>Customer or Business Name</p>
                    <p>ABN if Business</p>
                  </div>
                  <p>Reference:</p>
                  <p>Purchase Invoice</p>
                </div>
                <div className="column is-4">
                  <img
                    className="image is-128x128"
                    src={CROP_PAID}
                    alt="paid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>{MyCropsTable()}</div>
        </div>
      </>
    );
  };
  const MyCropsTable = () => {
  const  listData=[
        {
      "s_no": 1,
      "disc": "Fey",
      "quont": "2",
      "price": "1000",
      "total_price": "2000"
    }, {
      "s_no": 2,
      "disc": "Martin",
      "quont": "Keepence",
      "price": "mkeepence1@wikispaces.com",
      "total_price": "Male"
    }, {
      "s_no": 3,
      "disc": "Irwin",
      "quont": "Elijahu",
      "price": "ielijahu2@dmoz.org",
      "total_price": "Male"
    }
]
    
    return (
      <>
        <div>
          <table className="crop-view-table-layout">
            <thead >
              <tr >
                <th>S.No</th>
                <th>Item Description</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
           {listData.map((item, index) =>(

               
              <tr key={index}>
                <td>{item.s_no}</td>
                <td>{item.disc}</td>
              
                <td>{item.quont}</td>
                <td>{item.price}</td>
                <td>{item.total_price}</td>
              </tr>
               ))}
            </tbody>
          </table>
        </div>
        <div className="columns has-text-weight-bold">
          <div className="column is-4 mt-6">
            <div className="mb-5">
              <p>Thank You for your business</p>
            </div>
            <p>T&C Apply</p>
            <div className="mt-6">
              <p>Payment Info</p>
              <p>Account Name: CROP Services</p>
              <p>BSB: 083 004</p>
              <p>Account Number: 408785753</p>
            </div>
          </div>
          <div className="column is-2 is-flex is-justify-content-center is-align-items-center">
            QR
          </div>
          <div className="column is-6 is-flex is-justify-content-center">
            <p>
              <table className="mt-6">
                <thead>
                  <tr>
                    <th>Sub Total:</th>
                    <td>20.00</td>
                  </tr>{" "}
                  <tr>
                    <th>GST:</th>
                    <td>0.00</td>
                  </tr>
                  <tr>
                    <th>Total:</th>
                    <td>0.00</td>
                  </tr>
                  <tr className="crop-services-background mx-5 my-5">
                    <th>Amount Payable:</th>
                    <td>$20.00</td>
                  </tr>
                  <p>Amount in words(A$): Twenty Only</p>
                </thead>
              </table>
            </p>
          </div>
        </div>
      </>
    );
  };


const Invoice = () => {
  return (
   <>
   <div className='container mt-3'>
   <div>{MyCropTax()}</div>
   </div>
   </>
  )
}

export default Invoice