import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { CUSTOMER_HELP_URLS } from "../../../../api/OfferUrls";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  CROP_CUSTOMER_BARCODE_1,
  CROP_CUSTOMER_BARCODE_2,
} from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
const AppBarCode = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const { setLoading, user } = useSiteContext();
  const [formErrors, setFormErrors] = useState(false);
  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const isLoggedIn = () => {
    //return true;
    return user && user.euserid ? true : false;
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // const { user } = useSiteContext();

  const handleFormSubmit = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details are updating....Please Wait");
    let url = CUSTOMER_HELP_URLS.SUBSCRIPTION_INSERT;
    let data_in = { ...formData };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Subscribe successfully", "success");
        setLoading(false);
        setFormData({});
        setFormSubmit(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };
  const FormValidation = {
    email: [
      // SmartValid.required("Email ID is Required"),
      SmartValid.email("Please Enter a Valid Email Address"),
    ],
  };

  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "email_id",
      element: {
        validations: FormValidation.email,
        placeHolder: "Enter Email ID",
      },
    },
  ];

  return (
    <>
      {" "}
      <div
        className={
          user
            ? "customer-bar-code-container"
            : "customer-bar-code-container-without-login"
        }
      >
        {isLoggedIn() && (
          <div className="customer-subscribe-container ">
            <div className="subscribe-text mt-6">
              Subscribe for Latest Trends & Offers
            </div>
            <div className="columns is-centered">
              <div className="column is-6">
                <div className="smart-customer-earn-crop-input">
                  {/* <SmartSoftInput
                    key="text-4"
                    value={formData?.email_id || ""}
                    onChange={(value) => handleInputChange("email_id", value)}
                    validations={FormValidation.email}
                    handleErrorChange={handleErrorChange}
                    max={255}
                  /> */}
                  <SmartSoftForm
                    formData={formData}
                    setFormData={handleInputChange}
                    elements={formElements}
                    formSubmit={formSubmit}
                    handleErrorChange={handleErrorChange}
                  />
                  <div className="smart-customer-button pt-1">
                    <SmartSoftButton
                      label="Subscribe"
                      classList={[
                        "smart-customer-primary-button",
                        "smart-input-button",
                      ]}
                      onClick={handleFormSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="customer-barcode-container-view p-2">
          <div className="columns ">
            <div className="column is-4">
              <div className="customer-app-text">
                <div className="mt-4 p-6">
                  Earn CROPs for every purchase. Download the App now.
                </div>
              </div>
            </div>
            <div className="column is-4">
              <figure className="image  mt-2 px-6 ">
                <img
                  className="crop-customer-barcode-image"
                  src={CROP_CUSTOMER_BARCODE_2}
                  alt=""
                />
              </figure>
            </div>
            <div className="column is-4">
              <figure className="image mt-2 mb-3 px-6">
                <img
                  className="crop-customer-barcode-image"
                  src={CROP_CUSTOMER_BARCODE_1}
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppBarCode;
