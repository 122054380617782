import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import { ORDER_ITEMS_URLS } from "../../../api/OrderUrls";
import SmartHeader from "../../../components/site/SmartHeader";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { displayDots } from "../../../services/core/CommonService";
import {
  CANCEL_ORDER,
  COMPLETE_ORDER,
  NEW_ORDER,
  RETURN_ORDER,
} from "../../../services/ImageService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { encrypt_data } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import StarRating from "./StarRating";

const MyOrders = () => {
  const { tabtype } = useParams();
  const [data, setData] = useState([]);
  // const [formData, setFormData] = useState({});
  // const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const [tab, setTab] = useState("NEW");
  const navigate = useNavigate();
  const { openModal, closeModal } = useSiteContext();

  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    let post_data = {
      type: tab,
    };
    const subscription = post(
      ORDER_ITEMS_URLS.GET_ALL,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const tabs = [
    {
      index: "NEW",
      label: "New Orders",
      icon: "fa fa-shopping-bag",
      image: NEW_ORDER,
    },
    {
      index: "COMPLETED",
      label: "Completed Orders",
      icon: "fa fa-file-text-o",
      image: COMPLETE_ORDER,
    },
    {
      index: "CANCELLED",
      label: "Cancelled Orders",
      icon: "fa fa-ban",
      image: CANCEL_ORDER,
    },
    {
      index: "RETURN",
      label: "Returned Orders",
      icon: "fa fa-retweet",
      image: RETURN_ORDER,
    },
  ];

  useEffect(() => {
    loadTableData();
  }, [tab]);

  useEffect(() => {
    console.log("tab type", tabtype);
    if (tabtype && tabtype.length > 2) {
      setTab(tabtype);
    }
  }, []);

  const handleTabs = (index) => {
    // console.log("index " , index);
    setTab(index);
  };

  // const handleInputChange = (name, value) => {
  //   //console.log(" name " ,name , "   value " , value)
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleFormSubmit = (type) => {
  //   //  console.log("formdata", formData);
  //   setFormSubmit(true);
  //   setLoading(true, "Loading....Please Wait");
  //   const handleError = (errorMessage) => {
  //     showAlertAutoClose(errorMessage, "error");
  //     setLoading(false);
  //   };
  //   let data_in = { ...formData, type: type };
  //   const subscription = post(
  //     ORDER_URLS.GET_ALL_CUSTOMER,
  //     data_in,
  //     handleError
  //   ).subscribe((response) => {
  //     setLoading(false);
  //     setData(response.data);
  //   });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  //   // console.log("form errors ", formErrors);
  // };

  const order_click = (id) => {
    let id_encr = encodeURIComponent(encrypt_data("" + id));
    // navigate("/whish-list/payment", { state: { id: id } });
    navigate("/profile/order-info/" + id_encr);
  };

  const order_id = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <span
        className="has-text-link Orders-Order-number smart-cursor-pointer"
        onClick={() => order_click(id)}
      >
        {data?.order_id}
      </span>
    );
  };

  const offer_crops = (data) => {
    let type = data.order_type !== undefined ? data.order_type : "";
    return type === "REDEEM" ? (
      <span className="has-text-danger">{data.order_crops}</span>
    ) : (
      <span className="has-text-success">{data.order_crops}</span>
    );
  };

  const offer_status = (data) => {
    return (
      <>
        <div className="customer-order-status-text">{data.status_short}</div>
      </>
    );
  };
  const offer_type = (data) => {
    return (
      <>
        <div className="customer-order-status-text">{data.offer_mode}</div>
      </>
    );
  };

  const openNewForm = (data) => {
    let modelObject = {
      body: (
        <StarRating
          closeModal={closeModal}
          data={data}
          setLoading={setLoading}
        />
      ),
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: false,
    };
    openModal(modelObject);
  };
  const offer_review = (data) => {
    // console.log("offer_review",data)
    const review_completed = data && data.review_completed && parseInt(data.review_completed) >= 0 ? true : false;
    return review_completed===false ? (
      <>
        {
          <div
            className="Orders-Order-number has-text-link smart-cursor-pointer"
            onClick={() => openNewForm(data)}
          >
            Review
          </div>
        }
      </>
    ) : (
      <div>
        {data.review_completed}
        <i className="fa fa-star mt-1 pr-1" aria-hidden="true"></i>        
      </div>
    );
  };

  const offer_title_disp = (data) => {
    // console.log("data" ,data)
    let varient_data = data.variant_info;
    const parsedData = JSON.parse(varient_data);
    // console.log("varient_data" ,parsedData)
    return (
      <>
        <p>
          {data.offer_title}
          <br />
          {data.variant_info != null &&
            parsedData.map((item, index) => {
              return (
                <span key={index}>
                  <b>{item.variant_group}</b> : {item.variant_name}
                  {/* <br /> */}{" "}
                </span>
              );
            })}
          {data?.custom_note && data?.custom_note?.length > 0 && (
            <div className="smart-lable-customer">
              Customer Notes:{" "}
              <span className="smart-font-style-customer ">
                {" "}
                {displayDots(data.custom_note)}
              </span>
            </div>
          )}
        </p>
      </>
    );
  };

  const get_table_columns = () => {
    switch (tab) {
      case "COMPLETED":
        return columnstwo;
      default:
        return columnsone;
    }
  };

  const columnsone = [
    {
      title: "Date",
      index: "order_time",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      width: "10",
      autoTimeZone: true,
    },
    {
      title: "Order No.",
      index: "order_id",
      valueFunction: order_id,
      width: "10",
    },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "25",
      valueFunction: offer_title_disp,
    },
    { title: "Order Type", index: "order_type", width: "10" },
    {
      title: "Type",
      index: "offer_mode",
      width: "10",
      valueFunction: offer_type,
    },
    { title: "Qty", index: "quantity", width: "5" },
    { title: "Price", index: "total_price", width: "10" },
    {
      title: "CROPs",
      index: "offer_crops",
      width: "10",
      valueFunction: offer_crops,
    },

    {
      title: "Status",
      index: "status_short",
      width: "25",
      valueFunction: offer_status,
    },

    // {
    //   title: "Review",
    //   index: "offer_crops",
    //   width: "10",
    //   valueFunction: offer_review,
    // },
  ];

  const columnstwo = [
    {
      title: "Date",
      index: "order_time",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      width: "10",
    },
    {
      title: "Order No.",
      index: "order_id",
      valueFunction: order_id,
      width: "10",
    },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "25",
      valueFunction: offer_title_disp,
    },
    { title: "Order Type", index: "order_type", width: "10" },
    {
      title: "Type",
      index: "offer_mode",
      width: "10",
      valueFunction: offer_type,
    },
    { title: "Qty", index: "quantity", width: "5" },
    { title: "Price", index: "total_price", width: "10" },
    {
      title: "CROPs",
      index: "offer_crops",
      width: "5",
      valueFunction: offer_crops,
    },

    {
      title: "Status",
      index: "status_short",
      width: "15",
      valueFunction: offer_status,
    },

    {
      title: "Review",
      index: "offer_crops",
      width: "20",
      valueFunction: offer_review,
    },
  ];

  const tabs_display = () => {
    return (
      <div className="tabs smart-customer-tabs  ml-0 pl-0">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={
                  tab === item.index ? "order-active-tab" : "tabs-list-items"
                }
              >
                <a href="#">
                  <span
                    className={
                      tab === item.index
                        ? "order-active-tab-text pr-5 pl-5"
                        : "pr-5 pl-5"
                    }
                  >
                    {item.label}
                  </span>
                  <span className="icon ">
                    <img
                      className="image smart-order-tab-images"
                      src={item.image}
                      alt=""
                    />
                    {/* <i className={item.icon} aria-hidden="true"></i> */}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="columns">
        {/* <div className="column is-2">
          <ProfileSideNav />
        </div> */}

        <div className="column is-12 pr-6 pl-4 px-6 ">
          <div className="">
            <SmartHeader title={"Orders"} />
          </div>
          <div className="content pl-0 has-background-white smart-customer-input">
            <div className=" column pl-0">{tabs_display()}</div>
            <SmartSoftTable
              columns={get_table_columns()}
              data={data}
              tableProps={{ className: "crop-customer-table-layout" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
