import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { CUSTOMER_MORE_URLS } from "../../../api/OfferUrls";
import SmartHeader from "../../../components/site/SmartHeader";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { CROP_CUSTOMER_MORE_GET_A_MATE } from "../../../services/ImageService";
import {
  ALLOW_NUMERIC,
} from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import "./CustomerMore.css";

const GetAMate = () => {
  // const [formData, setFormData] = useState({});

  const [formData, setFormData] = useState({
    country_code: "AU",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading,refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "mobile_no") {
      setFormData((prev) => ({ ...prev, ["email_id"]: "" }));
    } else {
      setFormData((prev) => ({ ...prev, ["mobile_no"]: "" }));
    }
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElement)) {
      return false;
    }
    // console.log(formData)
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Submitting....Please Wait");
    let url = CUSTOMER_MORE_URLS.INSERT_MATE;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Request Sent Successfully", "success");
        setLoading(false);
        setFormData({
          country_code: "AU",
          mobile_no: "",
          email_id: "",
        });
        refreshUser()
      }
    );
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

 

  const labelTest = () => {
    return (
      <div className="column  has-text-centered  is-size-7 has-text-weight-bold">
        OR
      </div>
    );
  };


  const loginFormValidations = {
    mobile_no: [
      SmartValid.required("Mobile Number is Required"),
    ],
    email: [
      SmartValid.required("Email ID is Required"),
      SmartValid.email("Please Enter Valid Email")
    ]
  };

  const formElement = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "email_id",
      element: {
        placeHolder: "Enter Email Address",
        rightIcon: "fa fa-envelope-o",
        validations: loginFormValidations.email,
        disablePaste: true,
        max:"255",
        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData.mobile_no && formData.mobile_no.length > 0 ? true : false;
      }
    },
    {
      type: "LABEL",
      width: "12",
      labelFunction: labelTest,
      hideFunction: (data) => {
        return (formData.mobile_no && formData.mobile_no.length > 0) || (formData.email_id && formData.email_id.length)  ? true : false;
      }
    },
    {
      type: "MOBILE",
      width: "12",
      name: "mobile_no",
      codeName: "country_code",
      element: {
        // label: "Mobile Number",
        codeSelected: formData?.country_code,
        value: formData?.mobile_no || "",
        countries: ["AU", "IN"],
        placeHolder: "Enter Mobile Number",
        rightIcon: "fa-mobile",
        max: 10,
        pattern: ALLOW_NUMERIC,
        validations: loginFormValidations.mobile_no,
      },
      hideFunction: (data) => {
        return formData.email_id && formData.email_id.length > 0 ? true : false;
      },
    },
  ];

  const formDisplay = () => {
    return (
      <div className="columns crop-customer-getmate-page is-multiline m-4  is-centered ">
        <div className="column is-9 ">
          {/* <SmartSoftInput
            key="text-4"
            label="Mobile Number"
            value={formData?.mobile_no || ""}
            onChange={(value) => handleInputChange("mobile_no", value)}
            placeHolder="Enter Mobile Number "
            pattern={ALLOW_NUMERIC}
            isRequired={true}
            max={10}
            inputProps={{ isFocussed: true }}
            // validations={FormValidations.mobile_no}
            errorEnable={formSubmit}
            handleErrorChange={(value) => handleErrorChange("mobile_no", value)}
            errorUpdate={(value) => handleErrorChange("mobile_no", value)}
          /> */}
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElement}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            onChange={(value) => handleInputChange("mobile_no", value)}
          />
        </div>
        {/* <div className="column is-9 has-text-centered  is-size-7 has-text-weight-bold">
          OR
        </div>
        <div className="column is-9">
          <SmartSoftInput
            key="text-4"
            label="Email Address"
            value={formData?.email_id || ""}
            onChange={(value) => handleInputChange("email_id", value)}
            placeHolder="Enter Email Address"
            inputProps={{ isFocussed: true }}
            // validations={FormValidations.email}
            errorEnable={formSubmit}
            handleErrorChange={(value) => handleErrorChange("email_id", value)}
            errorUpdate={(value) => handleErrorChange("email_id", value)}
          />
        </div> */}
        <div className="column is-6 has-text-centered mt-4">
          <SmartSoftButton
            label="Submit"
            classList={["smart-customer-primary-button", "px-5"]}
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    );
  };

  return (
    <>
    <div className="px-5 ml-5 ">
    <SmartHeader title={"Get a Mate"} />
    </div>
      <div className="columns smart-customer-input   ">
        <div className="column is-6   mt-6">
          <div className="column is-12  customer-header has-text-centered">
        Referee Details
          </div>
          {formDisplay()}
        </div>
        <div className="column is-6 p-6 mt-5 has-text-centered customer-primary-font">
          <img src={CROP_CUSTOMER_MORE_GET_A_MATE} alt="" />
          <div className="mt-4">Invite your friends and family to enroll </div>
          <div className="">into CROP, Refer Now!</div>
        </div>
      </div>
    </>
  );
};

export default GetAMate;
