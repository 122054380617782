import { useEffect, useState } from "react";
import { getImageContent } from "../../../services/fileService";
import { get, post } from "../../../services/smartApiService";
import "./SmartFormElements.css";
const SmartImageDisplay = (props) => {
  const {
    srcType = "DATA",
    url = "",
    data,
    id = 0,
    imageClass = "",
    isMulti = false,
    updateImages,
    default_img = "",
    cacheEnable = false,
  } = props;
  //  console.log("props  ", props);
  const [src, setSrc] = useState(null);
  const [loading, setLoading] = useState(true);

  const storeSrc = (img_data) => {
    if (id > 0) {
      let src_data = src ? [...src] : [];
      src_data.push({ id: id, img_data: img_data });
      setSrc(src_data);
    } else {
      setSrc(img_data);
    }
  };

  const getSrc = () => {
    if (id > 0) {
      return src ? src.find((item) => item.id == id)?.img_data : null;
    } else {
      return src;
    }
  };

  const loadImageData = () => {
    if (cacheEnable === true && id > 0) {
      let src_data = getSrc();
      // console.log("entering here " , id , " src _data  " , src_data);
      if (src_data && src_data.length > 5) {
        return true;
      }
    }
    setLoading(true);
    // //  console.log("src " , src.length , " cach enable " , cacheEnable);
    // if (src && src.length > 5 && cacheEnable === true) {
    //  // return false;
    // }
    // setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      setLoading(false);
      //setSrc("");
      storeSrc("");
    };

    const subscription = post(url, { id: id }, handleError).subscribe(
      (response) => {
        setLoading(false);
        let data_image =
          response.data.content !== undefined
            ? response.data.content
            : response.data;
        //  console.log(response);
        storeSrc(data_image);
        // setSrc(data_image);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadImageGetData = () => {
    // setLoading(true, "Logging in Please Wait....");
    const handleError = (errorMessage) => {
      setLoading(false);
      setSrc("");
    };
    const subscription = get(url, handleError).subscribe((response) => {
      setLoading(false);
      let data_image =
        response.data.content !== undefined
          ? response.data.content
          : response.data;
      //  console.log(response);
      if (data_image && data_image.length > 15) {
        setSrc(data_image);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    if (srcType === "URL" && url.length > 10) {
      // console.log("entered in length post request ");
      loadImageData();
    } else if (srcType === "URL_GET" && url.length > 10) {
      loadImageGetData();
    } else {
      setLoading(false);
      setSrc(data);
    }
    //console.log("refreshed data ", id);
  }, [props]);

  const get_image = () => {
    //console.log("update ", id);
    if (id > 0) {
      let src_data = getSrc();
      return `data:image/png;base64,${src_data}`;
    }
    if (src && src.length > 17) {
      return `data:image/png;base64,${src}`;
    } else {
      return default_img;
    }
  };

  const singleImage = () => {
    let img_class = ["image"];
    if (loading) {
      img_class.push("isLoading");
    } else {
      img_class.push("isLoaded");
    }
    if (imageClass) {
      img_class.push(imageClass);
    }
    //console.log("updating this " , id );
    return (
      <>
        <figure className={img_class.join(" ")}>
          {!loading && <img src={get_image()} alt="" />}
        </figure>
      </>
    );
  };

  const deleteImage = (indexToRemove) => {
    const updatedItems = [...src];
    // Remove the element at the specified index
    updatedItems.splice(indexToRemove, 1);
    //
    if (updateImages) updateImages(updatedItems);
  };

  const edit_options = (index) => {
    return (
      <div className="image-edit-options image-delete-background">
        {/* <i className="fa fa-file has-text-link"></i> */}
        <i
          className="fa fa-trash has-text-danger"
          onClick={() => deleteImage(index)}
        ></i>
      </div>
    );
  };

  const singleImageDisplay = (item, index) => {
    return (
      <div
        className={"column smart-image-display-options " + imageClass}
        key={index}
      >
        <img src={getImageContent(item)} alt="" />
        {edit_options(index)}
      </div>
    );
  };

  const multipleImages = () => {
    return (
      <div className="columns is-multiline">
        {src &&
          Array.isArray(src) &&
          src.map((item, index) => {
            return singleImageDisplay(item, index);
          })}
      </div>
    );
  };

  return isMulti ? multipleImages() : singleImage();
};

export default SmartImageDisplay;
