import React from 'react';
import SmartImageDisplay from '../../../components/site/FormElements/SmartImageDisplay';
import { CUSTOMER_OFFERS } from '../../../api/OfferUrls';

const StarRatingImageDsiplay = ({ data }) => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data?.sd_offers_id !== undefined ? data.sd_offers_id : 0;
    return (
        <div className="mt-4">
        <div className="is-size-5 has-text-weight-bold my-1">
          {data?.offer_title}
        </div>
 <div className="order-info-image is-flex is-justify-content-center">
        <div>
          <SmartImageDisplay
            srcType="URL"
            url={url}
            id={id}
          />
          </div>
        </div>
      </div>
    );
  };
  
  export default StarRatingImageDsiplay;