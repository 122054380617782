import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SmartHeader from '../../../components/site/SmartHeader';
import { ABOUT_US } from '../../../services/ImageService';
import "./AboutUs.css";

const AboutUs = () => {
  const navigate = useNavigate();
  const baseNav = "";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
    <div className="px-5 ml-5">
    <SmartHeader title={"About Us"} />
    </div>
  
      <div className="customer-primary-font p-5 smart-terms-conditions pt-3">
        <div className="mt-3 customer-header has-text-centered">
          About Us
        </div>
        <div className="terms-and-conditions mx-5">
          <p className="mt-2">
            CROP Services Pty Ltd is an independent loyalty and rewards
            management company with an aim and objective of consolidating
            ‘Customer Loyalty Programs’ across businesses and trades.
            Headquartered in Melbourne, Australia the company aspires to extend
            a unified loyalty program to customers and trades alike.
          </p>
          <p className="mt-2">
            Customer loyalty in its current state and form is diversified and
            territorial in nature. Businesses currently offer loyalty benefits
            confined either to their sector or their associated affiliations and
            partnerships. This current positioning is age-old and well poised
            for market disruption. We intend to diminish the sectoral boundaries
            of customer loyalty through a ‘One Community One Approach’ model
            that is not only trade or sector agnostic but also extends
            progressive rewards for long term associations. Our offerings are
            simple, user-friendly and easily accessible through our website or
            mobile app.{" "}
          </p>
          <p className="mt-2">
            Our team comprises of a bunch of enthusiastic individuals that are
            passionate about innovation and customer experience. We're
            constantly thinking of new ways to deliver an experience that is
            unique, delightful and memorable. Its perhaps this inclination for
            innovation that curated the need for Community Rewards Operating
            Program ‘CROP’ – A program that empowers you and rewards your daily
            spends. In our quest to unify loyalty, we will continue to develop
            the program from strength to strength and while we do this, be rest
            assured that our progress, in no way, affects the CROP accrued by
            you and the balance in your account.{" "}
          </p>
          <p className="mt-2">
            The only other thing that we are passionate about is your privacy.
            So, if you have shared your contact information, your likes and
            dislikes, your preferences and interests or any other personal
            information that helps us customize personalized offers for you –
            then be rest assured that your data is safe and stays only with us.
            You can refer to our Privacy Policy for a deeper understanding of
            our data confidentiality practices as also Email us on
            privacy@cropglobalservices.com for any specific query.{" "}
          </p>
          <p className="mt-2">
            Whether you are a customer or a business owner you can register for
            our membership. To understand our offers or avail full benefits of
            our program register as a member today!!
          </p>
        </div>
        <span className="is-flex is-justify-content-center">
          <img src={ABOUT_US} className="AboutUs-image mt-5" alt="" />
        </span>
      </div>
    </>
  );
}

export default AboutUs