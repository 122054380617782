import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  // SmartSoftButton,
  SmartSoftForm,
  // SmartSoftInput,
  SmartValid,
} from "soft_digi";
import croplogo from "../../../assets/images/croplogo.png";
import { SAID_IMAGE } from "../../../services/ImageService";
// import "./LoginPage.css";
import SmartCheckRadio from "soft_digi/dist/forms/SmartCheckRadio";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { ADMIN_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import {
  getRemeberMe,
  getSessionStorage,
  removeRemeberMe,
  setRemeberMe,
  setSessionStorage,
} from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import CustomerLoginLayout from "../../../themes/CustomerLayout/CustomerLoginLayout";
import FirstPassWord from "./FirstPassWord";

const CustomerLogin = () => {
  const [formData, setFormData] = useState({
    login_type: "1",
    country_code: "AU",
    euserid: "C-",
    user_type: "CUSTOMER",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, setUser, refreshUser, openModal, closeModal } =
    useSiteContext();
  const navigate = useNavigate();

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "login_type" || name === "country_code") {
      resetData();
      if (value == 1) {
        setFormData((prev) => ({ ...prev, euserid: "C-" }));
      }
    }
  };

  const resetData = () => {
   // console.log("test input");
    handleInputChange("epassword", "");
    handleInputChange("euserid", "");
    handleInputChange("checkbox_remember_me","")

  };

  const handleErrorChange = (name, value) => {
    // console.log(" name " ,name , "   value " , value)
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const handleRemeberMe = () => {
    let checkbox_remember_me =
      formData.checkbox_remember_me !== undefined
        ? formData.checkbox_remember_me
        : "0";
    // console.log("check box rmeber me " , checkbox_remember_me, formData);
    if (checkbox_remember_me === "1") {
      setRemeberMe(formData);
    } else {
      removeRemeberMe();
    }
  };

  const handleRemeberMeLoad = () => {
    // let remember_data = getRemeberMe();
    let remember_data_original = getRemeberMe();
    const remember_data = { ...remember_data_original };
    delete remember_data.epassword;
    if (remember_data) {
      let checkbox_remember_me =
        remember_data.checkbox_remember_me !== undefined
          ? remember_data.checkbox_remember_me
          : "0";
      if (checkbox_remember_me === "1") {
        setFormData(remember_data);
      }
    }
  };
  useEffect(() => {
    handleRemeberMeLoad();
  }, []);

  useEffect(() => {
    if (formData.login_type === "1") {
      if (formData?.euserid?.length < 2) {
        setFormData((prev) => ({ ...prev, euserid: "C-" }));
      }
    }
  }, [formData]);

  const openNewForm = (data_in) => {
    let modelObject = {
      body: (
        <FirstPassWord
          data_in={data_in}
          setLoading={setLoading}
          closeModal={closeModal}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const checkUser = () => {
    // if (!ValidateFormNew(formData, formElements)) {
    //   return false;
    // }
    const handleError = (errorMessage) => {
      //  showAlertAutoClose(errorMessage, "error");
      // setLoading(false);
    };
    //setLoading(true, "Checking.... Please Wait");
    let url = ADMIN_LOGIN_URLS.CHECK_USER;
    let data_in = { ...formData };
    data_in["user_type"] = "CUSTOMER";
    // data_in["euserid"] = value;
    const subscription = post(url, data_in, handleError, false).subscribe(
      (response) => {
        if (
          response.data &&
          response.data.checkFlag &&
          response.data.checkFlag == 2
        ) {
          // handleInputChange("crop_id", response.data.crop_id);
          // handleInputChange("first_name", response.data.first_name);
          //setStage(2);
        } else {
          // navigate to first set pin box
          let data_in = {};
          data_in["otp_id"] = response.data.otp_id;
          data_in["uid"] = response.data.uid;
          data_in["login_type"] = "3";
          data_in["email_id"] = response.data.email_id;
          data_in["euserid"] = response.data.email_id;
          openNewForm(data_in);
          showAlertAutoClose("OTP sent to Email successfully", "info");
        }
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    //console.log(formData);
    // return false;
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      handleInputChange("epassword", "");
    };
    setLoading(true, "Logging in....Please Wait");
    let url = ADMIN_LOGIN_URLS.LOGIN_CUSTOMER;
    const subscription = post(url, formData, handleError, false).subscribe(
      (response) => {
        setFormSubmit(false);
        let path_to_navigate = getSessionStorage("CROP_CUSTOMER_LOGIN");
        showAlertAutoClose("Log In Successful", "success");
        setLoading(false);
        setUser(response.data);
        refreshUser();
        handleRemeberMe();
        let path_to =
          path_to_navigate && path_to_navigate.length > 3
            ? path_to_navigate
            : "/";
        navigate(path_to);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    // console.log("errors ", formErrors);
    /*
    //setFormSubmit(true);
   
    */
  };

  const goToForgot = (e) => {
    setSessionStorage("loginFormData", formData);
    navigate("/forgot-password");
  };

  const loginLogo = () => {
    return (
      <div>
        <figure className=" is-flex is-justify-content-center crop-image-logo">
          <img src={croplogo} alt="Crop Logo" />
        </figure>
      </div>
    );
  };
  const loginTitle = () => {
    return (
      <div className="mt-2 mb-2 login-title customer-primary-font">
        <p className="has-text-centered is-size-4  mb-3 customer-login-textcolor">
          Login
        </p>
        <p className="has-text-centered mb-5 has-text-black">
          Your access to a world of offers and rewards
        </p>
      </div>
    );
  };

  const loginFormValidations = {
    cropId: [SmartValid.required("CROP ID is Required")],
    email: [
      SmartValid.required("Email ID is Required"),
      SmartValid.email("Please Enter a Valid Email Address"),
      //SmartValid.pattern("Please Enter a Valid Email Address", /^[^\s@!#$%&'*+/=?^`{|}~]+@[^\s@!#$%&'*+/=?^`{|}~]+\.[^\s@!#$%&'*+/=?^`{|}~]+$/)
    ],
    password: [SmartValid.required("Password is Required")],
    mobile_no: [SmartValid.required("Mobile Number is Required")],
  };

  const passWordPin = () => {
    return (
      <div className="is-flex is-justify-content-space-between mb-4 has-text-weight-medium">
        <span className="">
          <SmartCheckRadio
            options={options_remember_me}
            name="checkbox_remember_me"
            value={formData?.checkbox_remember_me}
            onChange={(value) =>
              handleInputChange("checkbox_remember_me", value)
            }
            isRight={false}
          />
        </span>
        <span>
          <span
            className=" forgot-password-text pointer"
            // onClick={() => navigate("/forgot-password")}
            onClick={goToForgot}
          >
            Forgot Password?
          </span>
        </span>
      </div>
    );
  };

  const handle_blur = (value) => {
    //console.log("entered value ", value);
    if (formData.euserid && formData.euserid.length > 5) {
      checkUser();
    }
  };

  const options = [
    { value: "1", label: "CROP ID" },
    { value: "2", label: "Mobile" },
    { value: "3", label: "Email" },
  ];
  const options_remember_me = [{ value: "1", label: "Remember Me" }];
  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "login_type",
      element: {
        options: options,
        isMulti: true,
        classList: ["has-text-weight-medium"],
        type: "radio",
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "euserid",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        pattern: ALLOW_NUMERIC,
        value: formData?.euserid || "",
        //validations:numericValidations
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        placeHolder: "Enter Mobile Number",
        rightIcon: "fa-mobile",
        validations: loginFormValidations.mobile_no,
        disablePaste: true,
        onBlur: handle_blur,
        autoComplete: "new-password",
      },
      hideFunction: (data) => {
        return formData?.login_type !== "2" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "CROP ID",
        rightIcon: "fa fa-user-o",
        validations: loginFormValidations.cropId,
        disablePaste: true,
        max: 15,
        onBlur: handle_blur,
        autoComplete: "new-password",

        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData?.login_type !== "1" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter Email ID",
        rightIcon: "fa fa-envelope-o",
        validations: loginFormValidations.email,
        disablePaste: true,
        autoComplete: "new-password",
        onBlur: handle_blur,
        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData?.login_type !== "3" ? true : false;
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "epassword",
      element: {
        placeHolder: "Enter Password",
        validations: loginFormValidations.password,
        disablePaste: true,
        autoComplete: "new-password",
        // rightIcon:"lock"
        //  passwordValidator:true
      },
    },
    {
      type: "LABEL",
      labelFunction: passWordPin,
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Login",
        classList: ["customer-login-action-button"],
        onClick: () => handleLogin(),
      },
    },
  ];

  const formDisplay = () => {
    return (
      <div className="crop-business-login-form ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  const loginSignUp = () => {
    return (
      <div>
        <p className="has-text-centered mt-4 mb-6 pb-6 member-text">
          Not a Member?
          <Link to="/register" className="">
            {" "}
            Sign up now
          </Link>
        </p>
      </div>
    );
  };
  // const checkbox = () => {
  //   return <div></div>;
  // };

  // return (
  //   <div className="Login">
  //     <div className="columns card Customer-Login-contentpage">
  //       <div className="column is-6  is-hidden-mobile">
  //         <div className="Customer-Login-said-image p-6 ">
  //           <img src={SAID_IMAGE} alt=""></img>
  //         </div>
  //       </div>

  //       <div className="column customer-login-formmain card  is-6">
  //         <div className="customer-login-cardform ">
  //           <span>{loginLogo()}</span>
  //           <div className=" is-hidden-tablet has-text-centered smart-mobile-view-image  my-2 mt-3">
  //             <img src={SAID_IMAGE} alt=""></img>
  //           </div>
  //           <span>{loginTitle()}</span>
  //           <span> {formDisplay()}</span>
  //           <span> {loginSignUp()}</span>
  //           <span>{colorshaderounded()}</span>
  //         </div>
  //       </div>
  //     </div>

  //     {/* <CustomerFooter /> */}
  //   </div>
  // );

  return (
    <CustomerLoginLayout sideImage={croplogo} tabImage={SAID_IMAGE}>
      <span>{loginTitle()}</span>
      <span> {formDisplay()}</span>
      <span> {loginSignUp()}</span>
    </CustomerLoginLayout>
  );
};

export default CustomerLogin;
