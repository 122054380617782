import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SmartSoftButton } from "soft_digi";
import { ADMIN_OPERATIONS_SECTOR } from "../../../../api/OfferUrls";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { get } from "../../../../services/smartApiService";
const EarnCropsView = ({ homePage = false,handleClick }) => {
  const [data, setData] = useState([]);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();
  const baseNav = "";
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    const subscription = get(
      ADMIN_OPERATIONS_SECTOR.GET_ALL,
      handleError
    ).subscribe((response) => {
      if (homePage === true) {
        let firstFourElements = response.data.slice(0, 4);
        setData(firstFourElements);
      } else {
        setData(response.data);
      }

      //console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
    if(handleClick){
      handleClick()
    }
  };
  const location = useLocation();
  const hideButtonPaths = ["/earn-crops"];
  const shouldHideButton = hideButtonPaths.includes(location.pathname);

  const purchase_image = (data) => {
    let url = ADMIN_OPERATIONS_SECTOR.GET_IMAGE_PURCHASE;
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <SmartImageDisplay srcType="URL" url={url} id={id} cacheEnable={true} />
    );
  };

  return (
    <>
      <div className="earn-crop-container m-0 p-0">
        <div className="customer-earn-crop-view  customer-primary-font">
          <div className="container ">
            <div className="columns">
              <div className="column">
                {shouldHideButton && (
                  <div className="">
                    <Link to="/">
                      <span className=" Earn-Crops-Home-btn-hover">
                        <i
                          className="fa fa-home  mr-1"
                          aria-hidden="true"
                        ></i>
                        Home{" "}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      </span>
                      <span className="has-text-white is-size-6 ml-1 ">
                        Earn CROPs
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="columns is-multiline ">
              <div className="column is-12">
                <div className="is-flex is-justify-content-space-between margin-top">
                  <div className="smart-customer-earn-crop-text ">
                    <div className="left-border-earn-crop"></div>
                    <span>Earn CROPs</span>
                  </div>

                  <div className=" has-text-right">
                    {!shouldHideButton && (
                      <SmartSoftButton
                        label="Others"
                        classList={["smart-customer-secondary-button"]}
                        onClick={() => navigateLink("earn-crops")}
                      />
                    )}
                  </div>
                </div>
                <div className="smart-earn-crop-sub-text">
                  Enjoy loyalty benefits for all your purchase! Make every
                  dollar count
                </div>
              </div>
              {data.map((item, index) => (
                <div key={index} className="column is-3">
                  <div
                    className="earn-crop-images-files earn-crop-images-files-mediascreen"
                    onClick={() =>
                      navigateLink("offer-details/earn/" + item.ID)
                    }
                  >
                    {purchase_image(item)}
                    <div className="crop-redeem-image-name-box">
                      {item.sector_name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarnCropsView;
