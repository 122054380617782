import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  // SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { CUSTOMER_PIN_CHANGE } from "../../../services/ImageService";
// import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import ProfileSideNav from "./ProfileSideNav";
// import SmartPassWord from "../../../components/site/FormElements/SmartPassWord";
import { SmartSoftForm } from "soft_digi";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";

import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { CUSTOMER_USER_URLS, SERVICE_URLS } from "../../../api/Services/UserUrls";
import SmartHeader from "../../../components/site/SmartHeader";
import { get, post } from "../../../services/smartApiService";

const PinChange = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading,refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    const subscription = get(
      SERVICE_URLS.GET_ONE,
      handleError
    ).subscribe((response) => {
      setFormData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadTableData();
  }, []);
  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElementPassword)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    setLoading(true, "Updating....Please Wait");
    // console.log(formData);
    let url = CUSTOMER_USER_URLS.CHANGE_PASSWORD;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
         showAlertAutoClose("Password Changed Successfully","success" );
         refreshUser();
         setTimeout(() => {
          window.scrollTo(0, 0);
        }, 2500);
        //showNotification("success", "Password Changed Successfully");
        setLoading(false);
        // console.log(response.data);
        setFormData({});
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const handleCommunictionFormSubmit = () => {
    formData.user_app_notification = formData.user_app_notification!=='Yes'?'No':'Yes'
    formData.user_sms_notification = formData.user_sms_notification!=='Yes'?'No':'Yes'
    formData.user_email_notification = formData.user_email_notification!=='Yes'?'No':'Yes'
    // setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error" );
      setLoading(false);
    };
    setLoading(true, " Updating....Please Wait");
    // console.log(formData);
    let url = SERVICE_URLS.INSERT;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Notification Updated Successfully","success" );
       // showNotification("success", "Notification Updated Successfully");
        setLoading(false);
        // console.log(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const handleErrorChange = (name, value) => {
    // console.log(" name ", name, "   value ", value);
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  // const handleFormSubmit = () => {
  //   console.log("formdata", formData);
  //   setFormSubmit(true);
  //   console.log("form errors ", formErrors);
  // };

  const loginFormValidations = {
    current_password: [
      SmartValid.required("Current Password Required"),
      //  SmartValid.maxLength("Number should be maximum 20 digit",19)
    ],
    new_password: [
      SmartValid.required("New Password Required"),
      // SmartValid.maxLength("Number should be maximum 20 digit",19)
    ],
    confirm_password: [
      SmartValid.required("Confirm Password Required"),
      // SmartValid.maxlength("Number should be maximum 20 digit",19)
    ],
  };

  const options = [
    { value: "Yes", label: "" },
  ];
 const formElementPassword = [
   {
     type: "PASSWORD",
     width: "12",
     name: "currentPassword",
     element: {
       label: "Current Password",
       isRequired: true,
       placeHolder: "Current Password",
       passwordValidator: true,
       validations: loginFormValidations.current_password,
       min:4,
       max:16,
     },
   },

   {
     type: "PASSWORD",
     width: "12",
     name: "newPassword",
     element: {
      label: "New Password",
      isRequired: true,
       placeHolder: "New Password",
       passwordValidator: true,
       validations: loginFormValidations.new_password,
       min:4,
       max:16,
     },
   },

   {
     type: "PASSWORD",
     width: "12",
     name: "confirmPassword",
     element: {
      label: "Confirm Password",
      isRequired: true,
       placeHolder: "Confirm Password",
       passwordValidator: true,
       validations: loginFormValidations.confirm_password,
       min:4,
       max:16,
     },
   },
 ];

  return (
    <>
      <div className="columns">
        {/* <div className="column is-2">
          <ProfileSideNav />
        </div> */}
        <div className="column is-12 px-6">
        <div className="pl-1">
    <SmartHeader title={"Settings"} />
    </div>
          <div className="has-background-white">
            <div className="columns">
              <div className="column is-5 ml-5 smart-flex-container">
                <div className="smart-customer-input">
                  <div className="m-6">
                    <div className="customer-header has-text-centered mb-4">
                      Password Change
                    </div>
                    <div className="column">
                      <div className="pb-3">
                        {/* <SmartPassWord
                          key="currentPassword"
                          name="currentPassword"
                          value={formData?.currentPassword || ""}
                          handleInputChange={handleInputChange}
                          label="Enter Current Password"
                          validations={loginFormValidations.current_password}
                          errorEnable={formSubmit}
                          errorUpdate={(value) =>
                            handleErrorChange("current_password", value)
                          }
                          // passwordValidator={true}
                        /> */}
                         <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElementPassword}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
                      </div>
                      {/* <div className="pb-3">
                        <SmartPassWord
                          key="newPassword"
                          name="newPassword"
                          label="Enter New Password"
                          value={formData?.newPassword || ""}
                          handleInputChange={handleInputChange}
                          passwordValidator={true}
                          validations={loginFormValidations.new_password}
                          errorEnable={formSubmit}
                          errorUpdate={(value) =>
                            handleErrorChange("new_password", value)
                          }
                        />
                      </div> */}
                      {/* <div className="pb-3">
                        <SmartPassWord
                          key="confirmPassword"
                          name="confirmPassword"
                          value={formData?.confirmPassword || ""}
                          handleInputChange={handleInputChange}
                          label="Confirm New Password"
                          passwordValidator={true}
                          validations={loginFormValidations.confirm_password}
                          errorEnable={formSubmit}
                          errorUpdate={(value) =>
                            handleErrorChange("confirm_password", value)
                          }
                        />
                      </div> */}
                    </div>
                  </div>

                  <div className="has-text-centered">
                    <SmartSoftButton
                      label="Update"
                      classList={["smart-customer-primary-button", "mb-6"]}
                      onClick={handleFormSubmit}
                    />
                  </div>
                  <div className="is-size-5 has-text-weight-bold has-text-centered customer-header">
                    Communication Preferences
                  </div>

                  <div className="columns mt-4 ml-6 mb-3 my-6">
                    <div className="smart-crop-app-notification-lable">
                      {/* <SmartSoftCheckRadioSwitch
                        isMulti={true}
                        options={options}
                        type={"switch"}
                        label="App Notifications:"
                        name="user_app_notification"
                        isHorizontal="true"
                        value={formData?.user_app_notification || ""}
                        onChange={(value) =>
                          handleInputChange("user_app_notification", value)
                        }
                      /> */}
                       {/* <SmartSoftCheckRadioSwitch
              options={options}
              switchMode={true}
              isRight={true}
              label="App Notifications:"
              inputProps={{ isRounded: true, }}
              name="user_app_notification"
              isHorizontal="true"
               value={formData?.user_app_notification || ""}
               onChange={(value) =>
                handleInputChange("user_app_notification", value)
              }
            /> */}
                    </div>
                    <div className="ml-2 smart-comunication-box">
                    <SmartSoftCheckRadioSwitch
              options={options}
              switchMode={true}
              isRight={true}
              label="SMS:"
              isHorizontal="true"
              inputProps={{ isRounded: true, }}
              name="user_sms_notification"
              value={formData?.user_sms_notification || ""}
              onChange={(value) =>
                handleInputChange("user_sms_notification", value)
              }
            />
                      {/* <SmartSoftCheckRadioSwitch
                        isMulti={true}
                        options={options}
                        type={"switch"}
                        label="SMS:"
                        name="user_sms_notification"
                        isHorizontal="true"
                        value={formData?.user_sms_notification || ""}
                        onChange={(value) =>
                          handleInputChange("user_sms_notification", value)
                        }
                      /> */}
                    </div>
                    <div className="ml-2 smart-comunication-box">
                      {/* <SmartSoftCheckRadioSwitch
                        isMulti={true}
                        options={options}
                        type={"switch"}
                        name="user_email_notification"
                        label="Email:"
                        isHorizontal="true"
                        value={formData?.user_email_notification || ""}
                        onChange={(value) =>
                          handleInputChange("user_email_notification", value)
                        }
                      /> */}
                      <SmartSoftCheckRadioSwitch
              options={options}
              switchMode={true}
              isRight={true}
              label="Email:"
              inputProps={{ isRounded: true, }}
              name="user_email_notification"
              isHorizontal="true"
              value={formData?.user_email_notification || ""}
              onChange={(value) =>
                          handleInputChange("user_email_notification", value)
                        }
            />
                    </div>
                  </div>

                  <div className="has-text-centered mb-6">
                    <SmartSoftButton
                      label="Save"
                      classList={["smart-customer-primary-button", "px-6"]}
                      onClick={handleCommunictionFormSubmit}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-6 ">
                <div className="my-card-view-form-image my-6 ">
                  <img src={CUSTOMER_PIN_CHANGE} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PinChange;
