
import { Route, Routes } from "react-router-dom";
import { CustomerForgot, CustomerLogin, NotificationView, PrivacyAndDataRights, RegisterPage } from '../../pages/Customer';
import CustomerLayout from '../../themes/CustomerLayout/CustomerLayout';
import PrivateRoute from '../PrivateRoute';
import Help from './Help';
import HomeRoute from './HomeRoute';
import MoreRoute from './MoreRoute';
import ProfileButton from './ProfileButton';
import WhishList from './WhishList';
// import Notification from "./Notification";


const CustomerRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<CustomerLayout> <HomeRoute /></CustomerLayout>} />
      <Route path="/help/*" element={<CustomerLayout><Help /></CustomerLayout>} />
      <Route path="/profile/*" element={<PrivateRoute><CustomerLayout><ProfileButton /></CustomerLayout></PrivateRoute>} />
      <Route path="/whish-list/*" element={<PrivateRoute><CustomerLayout><WhishList /></CustomerLayout></PrivateRoute>} />
      <Route path="/more/*" element={<CustomerLayout><MoreRoute /></CustomerLayout>} />
      <Route path="/login" element={<CustomerLayout><CustomerLogin /></CustomerLayout>} />
      <Route path="/forgot-password" element={<CustomerLayout><CustomerForgot /></CustomerLayout>} />
      <Route path="/register" element={<CustomerLayout><RegisterPage /></CustomerLayout>} />
      <Route path="/notification/:type" element={<PrivateRoute><CustomerLayout> <NotificationView /></CustomerLayout></PrivateRoute>} />
      <Route path="/privacy-data-rights" element={<PrivacyAndDataRights />} />

    </Routes>
  )
}

export default CustomerRoute