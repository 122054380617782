import React, { useState } from 'react'
import "./CustomerLayoutCss.css";
import MobileSideNav from './MobileSideNav';
import { CUSTOMER_MY_ACCOUNT } from '../../api/OfferUrls';
import SmartImageDisplay from '../../components/site/FormElements/SmartImageDisplay';
import { ADMIN_USER_LOGO } from '../../services/ImageService';
import { useNavigate } from 'react-router-dom';
import CustomerNotification from './CustomerNotification';

const MobileHeader = ({  open, openNav, user, logout,  }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [sidebarOpen, setSidebarOpen] = useState(open);
  const navigate = useNavigate();
  const basenav = "";
  // const location = useLocation();
  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the state
  };
//   const handleToggle = () => {
//     setSidebarOpen(prevState => !prevState);
//     openNav()
// };
const isLoggedIn = () => {
  //return true;
  return user && user.euserid ? true : false;
};

//let img_url = CUSTOMER_MY_ACCOUNT.GET_PROFILE_IMAGE;

const LoginButtons =()=>{
  return(
    <>
      {!isLoggedIn() && (
        <>
          <div className=" is-flex">
            {/* <button
              key="business"
              className="button is-rounded crop-customer-laytout-top-button"
            >
              BUSINESS USERS
            </button> */}

            <button
              key="login"
              className="button is-rounded crop-customer-laytout-top-button"
              onClick={() => navigateLink("login")}
            >
              <span className="icon">
                <i className="fa fa-user"></i>
              </span>
              <span>Login / Register</span>
            </button>
          </div>
        </>
      )}
    </>
  )
}
const EmployeeIcons = () => {
  // const navigate = useNavigate();
  // const basenav = "/customer/whish-list";
  // const navigateLink = (index) => {
  //   navigate(basenav + "/" + index);
  // };

  return (
    <>
      <div className="is-flex mt-3 smart-customer-icons-color">
        

        <div className="dropdown is-active" key="s2"></div>
        <div className="is-flex" key="s3">
        <span
              className="icon customer-header-icon"
              key="2"
              onClick={() => navigateLink("whish-list/whish-list")}
            >
              {user.wishlist > 0 && (
                <span className="user-bade"> {user?.wishlist}</span>
              )}
              <i className="fa fa-heart" aria-hidden="true"></i>
            </span>
            <span
              className="icon customer-header-icon"
              key="3"
              onClick={() => navigateLink("whish-list/view-cart")}
            >
            {user.cart >0&&<span className="user-bade">{user.cart}</span>}
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              {/* <img className="header-top-bag-image" src={HOME_BAG} alt=""/> */}
            </span>
        </div>
        <div className="dropdown is-hoverable is-right" key="s1">
        {user && (
            <CustomerNotification
              noteData={user?.notification}
              // loadProfileData={loadProfileData}
            />
          )}
          {/* <span className="icon dropdown-trigger">
            <i
              className="fa fa-bell"
              aria-hidden="true"
              aria-controls="dropdown-menu"
            >
            
            </i>
          </span> */}
          {/* <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              
            </div>
          </div> */}
        </div>

      </div>
    </>
  );
};
  return (
   <>
   <div className='mobile-header-container'>
      <div className='columns p-0 is-flex-mobile'>
        <div className='column is-4 '>
          <div className=' is-size-5 has-text-weight-bold my-3 ml-4' onClick={()=>toggleSidebar()}>
          <i className="fa fa-bars " aria-hidden="true"></i>
          </div>
          {isOpen&&(
      <div className={!isOpen?"smart-crop-sidenav":"smart-mobile-sidenav-active"}>
      <MobileSideNav ToggleSidebar={toggleSidebar} isLoggedIn={isLoggedIn}/>
     </div>
   )}
        </div>
        <div className='column is-4'>
        {isLoggedIn() && (
        <>
        {EmployeeIcons()}
        </>
        ) }
        </div>
        <div className='column is-4 has-text-right'>
        <div className="dropdown is-hoverable is-right mt-2">
        {isLoggedIn() && (
            <button
              key="user-logged-in"
              className="button is-rounded crop-customer-laytout-top-button dropdown-trigger "
            >
              {/* <span className="icon dropdown-trigger" key="1">
            <i className="fa fa-bell" aria-hidden="true" aria-controls="dropdown-menu"></i>
          </span> */}
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {/* <CustomerNotification /> */}
                  <div className="is-flex is-justify-content-center" key="g6">
                    <div className="customer-profile-image is-flex is-justify-content-center is-rounded m-2">
                      <SmartImageDisplay
                        srcType="DATA"
                        data={user?.profile}
                        //url={img_url}
                        imageClass="is-64x64 is-rounded"
                        default_img={ADMIN_USER_LOGO}
                      />
                      {/* <figure className="image is-64x64 ">
                      <img className="is-rounded crop-customer-top-button-img" src={ADMIN_USER_LOGO} />
                    </figure> */}
                    </div>
                  </div>
                  <div className="customer-profile-border mt-1"  key="g7" ></div>
                  <div className=""  key="g9">
                    <ul className=" has-text-left px-6">
                      <li
                        className="customer-profile-test"
                        onClick={() => navigateLink("profile/my-profile")}
                      >
                        <span className="customer-home-icon-text-color">
                          <i className="fa fa-user-o" aria-hidden="true"></i>
                        </span>
                        <span>My Profile</span>
                      </li>
                      <li
                        className="customer-profile-test"
                        onClick={() => navigateLink("profile/pin-change")}
                      >
                        <span className="customer-home-icon-text-color">
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </span>
                        <span>Settings</span>
                      </li>
                      <li
                        className="customer-profile-test"
                        onClick={() => logout()}
                      >
                        <span className="customer-home-icon-text-color">
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                        </span>
                        <span>Logout</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <span className="icon">
                <i className="f
                a fa-user"></i>
              </span> */}
              <div className="customer-user-image-display" key="j9">
                {/* <SmartImageDisplay
                  srcType="URL_GET"
                  url={img_url}
                  imageClass="  "
                  default_img={ADMIN_USER_LOGO}
                /> */}
                 <SmartImageDisplay
                        srcType="DATA"
                        data={user?.profile}
                        //url={img_url}
                        imageClass="is-64x64 is-rounded"
                        default_img={ADMIN_USER_LOGO}
                      />
              </div>
              {/* <figure className="image is-24x24 ">
                      <img className="is-rounded" src={ADMIN_USER_LOGO} />
                    </figure> */}

              <span>Hi {user?.ename}</span>
              <i
                class="fa fa-sort-desc ml-1 is-size-6 mb-1"
                aria-hidden="true"
              ></i>
            </button>
        )}
           {!isLoggedIn() && (
            <>
            {LoginButtons()}
            </>
           )}


          </div>
        </div>
      </div>
   </div>
   
    
   </>
  )
}

export default MobileHeader