import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN_USER_LOGO,
  BUSSINESS_HEAD_TOP_CENT_lOGO,
  CLAIM_MISSING_CROPS,
  CONTACT_US,
  CROP_BALANCE,
  CROP_CARD,
  // BUSSINESS_HEAD_TOP_D_lOGO,
  CROP_CUSTOMER_HOME_TOP_ICON,
  CROP_LOGO,
  FAQ,
  HOME_BAG,
  MATE,
  MY_ACCOUNT,
  PRIVACY,
  RATE,
  REQUEST_COMPLAINT,
  SHOPPING_CART,
  TERMS,
} from "../../services/ImageService";
import CustomerNotification from "./CustomerNotification";
import CustomerDropDownMenu from "./CustomerDropDownMenu";
import { useState, useEffect } from "react";
import { CUSTOMER_MY_ACCOUNT } from "../../api/OfferUrls";
import SmartImageDisplay from "../../components/site/FormElements/SmartImageDisplay";
import MobileHeader from "./MobileHeader";
import { showAlertAutoClose } from "../../services/notifyService";
import { get } from "../../services/smartApiService";
import { IoCart } from "react-icons/io5";

const CustomerHeader = ({ user, logout, setPage, setLoading }) => {
  const [data, setData] = useState({});
  // console.log("user = " , user);
  const navigate = useNavigate();
  // const location = useLocation();
  //const history = useHistory();
  const basenav = "";
  const location = useLocation();
  //console.log("location.pathname " , location.pathname)
  const isActive = (pathname) => {
    if (pathname == "/") {
      return location.pathname == pathname
        ? "crop-customer-theme-top-buttons-active button crop-customer-theme-top-buttons is-inverted is-small icon  is-hoverable is-right mx-4 mt-0"
        : "button crop-customer-theme-top-buttons is-inverted is-small";
    } else {
      return location.pathname.includes(pathname)
        ? "crop-customer-theme-top-buttons-active button crop-customer-theme-top-buttons is-inverted is-small icon  is-hoverable is-right mx-4 mt-0"
        : "button crop-customer-theme-top-buttons is-inverted is-small";
    }
  };
  // console.log(location);
  const navigateLink = (index) => {
    navigate(basenav + "/" + index);
  };
  // profile/my-account
  const isLoggedIn = () => {
    //return true;
    return user && user.euserid ? true : false;
  };
  const handleClick = () => {
    window.location.href = "https://business.cropglobalservices.com/";
  };

  const loadProfileData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.GET_PROFILE,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      // console.log("Data 123",response.data )
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    //  loadProfileData();
  }, [location.pathname]);

  const help_sub_nav = "/help/";

  const help_data = [
    {
      title: "Support",
      menus: [
        {
          title: "Contact Us",
          link: help_sub_nav + "contact-us",
          icon: CONTACT_US,
          isLoggedIn: false,
        },
        {
          title: "Claim Missing CROPs",
          link: help_sub_nav + "claim-missing-crops",
          icon: CLAIM_MISSING_CROPS,
          isLoggedIn: true,
        },
        {
          title: "Request or Complaint",
          link: help_sub_nav + "request-and-complaint",
          icon: REQUEST_COMPLAINT,
          isLoggedIn: true,
        },
      ],
    },
  ];

  const help_data_devide = [
    {
      title: "Important Information",
      menus: [
        {
          title: "Terms and Conditions",
          link: help_sub_nav + "terms-conditions-new",
          icon: TERMS,
        },
        {
          title: "Privacy and Data Rights",
          link: help_sub_nav + "privacy-data-rights-new",
          icon: PRIVACY,
        },
        {
          title: "Frequently Asked Questions",
          link: help_sub_nav + "FAQ-new",
          icon: FAQ,
        },
      ],
    },
    {
      title: "Feedback",
      isLoggedIn: true,
      menus: [
        {
          title: "Rate Our Service",
          link: help_sub_nav + "feedback",
          icon: RATE,
          isLoggedIn: true,
        },
      ],
    },
  ];

  const more_sub_menu = "/more/";

  const more_menu_data = [
    {
      title: "",
      menus: [
        // {
        //   title: "Purchase CROPs",
        //   link: more_sub_menu + "purchase-crops",
        //   icon: "fa fa-usd",
        // },
        {
          title: "Get a Mate",
          link: more_sub_menu + "get-a-mate",
          icon: MATE,
          isLoggedIn: true,
        },
      ],
    },
  ];

  const crop_sub_menu = "/profile/";

  const crop_menu_data = [


    {
      title: "",
      menus: [
        {
          title: "Orders",
          link: crop_sub_menu + "my-orders",
          icon: SHOPPING_CART,
          isLoggedIn: true,
        },
      ],
    },

    {
      title: "",
      menus: [
        {
          title: "My Account",
          link: crop_sub_menu + "my-account",
          icon: MY_ACCOUNT,
          isLoggedIn: true,
        },
      ],
    },

   
    {
      title: "",
      menus: [
        {
          title: "CROP Card",
          link: crop_sub_menu + "my-crop-card",
          icon: CROP_CARD,
          isLoggedIn: true,
        },
      ],
    },
  ];

  const help_menu = () => {
    return (
      <div
        className="button crop-customer-theme-top-buttons is-inverted 
          is-small icon dropdown is-hoverable is-right"
        key="buttons_one"
      >
        <span
          key="HELP"
          className="dropdown-trigger"
          aria-controls="help-dropdown-menu"
        >
          <span className={isActive("/help/")}> HELP</span>
        </span>
        <div
          className="dropdown-menu customer-menu-drop-down-box"
          id="help-dropdown-menu"
          role="menu"
          key="menu"
        >
          <div key="4" className="dropdown-content">
            <i className="fa fa-sort-asc drop-down-arrow"></i>

            <CustomerDropDownMenu key="help-1" menu={help_data} user={user} />
            <div className="help-more-information">
              <CustomerDropDownMenu
                key="help-2"
                menu={help_data_devide}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const More_menu = () => {
    // const [isActive, setIsActive] = useState(false);

    return (
      <div
        className={
          "button crop-customer-theme-top-buttons is-inverted is-small icon dropdown is-hoverable is-right ml-4"
        }
        //   onClick={handleClick}
        // className="
        // button crop-customer-theme-top-buttons is-inverted
        //   is-small icon dropdown is-hoverable is-right"
        key="5"
      >
        <span className="dropdown-trigger" aria-controls="help-dropdown-menu">
          <span className={isActive("/more/")}> MORE </span>
        </span>

        <div
          className="dropdown-menu customer-menu-drop-down-box"
          id="help-dropdown-menu"
          role="menu"
          key="6"
        >
          <div className="dropdown-content">
            <i className="fa fa-sort-asc drop-down-arrow"></i>

            <CustomerDropDownMenu  menu={more_menu_data} user={user} />
          </div>
        </div>
      </div>
    );
  };

  const crop_menu = () => {
    // const [isActive, setIsActive] = useState(false);

    return (
      <div
        className={
          "button crop-customer-theme-top-buttons is-inverted is-small icon dropdown is-hoverable is-right mx-4 mt-0"
        }
        //   onClick={handleClick}
        // className="
        // button crop-customer-theme-top-buttons is-inverted
        //   is-small icon dropdown is-hoverable is-right"
        key="test"
      >
        <span className="dropdown-trigger" aria-controls="help-dropdown-menu">
          <span className={isActive("/profile/")}> CROP </span>
        </span>

        <div
          className="dropdown-menu customer-menu-drop-down-box"
          id="help-dropdown-menu"
          role="menu"
          key="crop_text"
        >
          <div className="dropdown-content">
            <i className="fa fa-sort-asc drop-down-arrow"></i>

            <CustomerDropDownMenu key={"Crop_menu_one"} menu={crop_menu_data} user={user} />
          </div>
        </div>
      </div>
    );
  };


  const homeMenu = () => {
    // const [isActive, setIsActive] = useState(false);

    return (
      <div
      onClick={()=>navigateLink("")}
        className={
          user?
          "button crop-customer-theme-top-buttons is-inverted is-small icon dropdown is-hoverable is-right mx-2 mt-0":
            "button crop-customer-theme-top-buttons is-inverted is-small icon dropdown is-hoverable is-right mx-4 mt-0"
        }
      
        key="6"
      >   
        <span className="dropdown-trigger" aria-controls="help-dropdown-menu">
          <span className={isActive("/")}>HOME </span>
        </span>

      
        </div>
      
    );
  };


  // const getActiveLink = (index) => {
  //   return getPage() === index ? "is-active" : "";
  // }

  const employeeDetails = () => {
    return (
      <>
        <div className="columns fredoka-font-family">
          <div className="column is-flex" key="10">
            <span className="icon-text is-flex">
              <span className="icon crop-business-theme-more">
                {/* <i className="fa fa-address-book" aria-hidden="true"></i> */}
                <img src={CROP_CUSTOMER_HOME_TOP_ICON} alt="" />
              </span>
              <span className="crop-bussiness-theme-title">
                {user?.euserid}
              </span>
            </span>
          </div>
          <div className="column " key="11">
            <span className="icon-text pointer">
              <span
                className="icon "
                onClick={() => navigateLink("profile/my-account")}
              >
                <img src={CROP_BALANCE } alt="" />
              </span>
              <span
                className="crop-bussiness-theme-title "
                onClick={() => navigateLink("profile/my-account")}
              >
                {user?.crop_balance}
              </span>
            </span>
          </div>
          {/* <div className="column" key="12">
            <span className="icon-text">
              <span className="icon">
                <img src={BUSSINESS_HEAD_TOP_D_lOGO} alt="" />
              </span>
              <span className="crop-bussiness-theme-title is-size-7">
                {" "}
                Silver{" "}
              </span>
            </span>
          </div> */}
        </div>
      </>
    );
  };

  const EmployeeIcons = () => {
    // const navigate = useNavigate();
    // const basenav = "/customer/whish-list";
    // const navigateLink = (index) => {
    //   navigate(basenav + "/" + index);
    // };
    //  console.log("data " , data);
    return (
      <>
        <div className="is-flex">
          {user && (
            <CustomerNotification
              noteData={user?.notification}
              loadProfileData={loadProfileData}
            />
          )}
          <div className="dropdown is-active" key="s2"></div>
          <div className="is-flex" key="s3">
            <span
              className="icon customer-header-icon header-icon-size"
              key="2"
              onClick={() => navigateLink("whish-list/whish-list")}
            >
              {user.wishlist > 0 && (
                <span className="user-bade"> {user?.wishlist}</span>
              )}
             <span className="wishlist-active-buttons">
             <i className="fa fa-heart " aria-hidden="true"></i>
             </span>
            </span>
            <span
              className="icon customer-header-icon is-size-4"
              key="3"
              onClick={() => navigateLink("whish-list/view-cart")}
            >
            {user.cart >0&&<span className="user-bade">{user.cart}</span>}
              {/* <i className="fa fa-shopping-cart" aria-hidden="true"></i> */}
              <IoCart className="is smart-cart-icon" />
              {/* <img className="header-top-bag-image" src={HOME_BAG} alt=""/> */}
            </span>
          </div>
        </div>
      </>
    );
  };

  const LoginButtons = () => {
    return (
      <>
        {!isLoggedIn() && (
          <>
            <div className=" is-flex">
              <button
                key="business"
                className="button is-rounded crop-customer-laytout-top-button-home"
                onClick={handleClick}
              >
               For Business
              </button>

              <button
                key="login"
                className="button is-rounded crop-customer-laytout-top-button-home"
                onClick={() => navigateLink("login")}
              >
                <span className="icon">
                  <i className="fa fa-user"></i>
                </span>
                <span>Login / Sign Up</span>
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  const lastButtons = () => {
    // let img_url = CUSTOMER_MY_ACCOUNT.GET_PROFILE_IMAGE;
    return (
      <>
        {isLoggedIn() && (
          <div className="dropdown is-hoverable is-right">
            <button
              key="user-logged-in"
              className="button is-rounded crop-customer-laytout-top-button dropdown-trigger mr-6"
            >
              {/* <span className="icon dropdown-trigger" key="1">
            <i className="fa fa-bell" aria-hidden="true" aria-controls="dropdown-menu"></i>
          </span> */}
              <div className="dropdown-menu " id="dropdown-menu" role="menu">
                <div className="dropdown-content with-out-pointer">
                  <i className="fa fa-sort-asc drop-down-arrow profile-dropdown-button"></i>
                  {/* <CustomerNotification /> */}
                  <div className="is-flex is-justify-content-center" key="g6">
                    <div className="customer-profile-image is-flex is-justify-content-center is-rounded m-2">
                      {/* <SmartImageDisplay
                        srcType="URL_GET"
                        url={img_url}
                        imageClass="is-64x64 is-rounded"
                        default_img={ADMIN_USER_LOGO}
                      /> */}
                      <SmartImageDisplay
                        srcType="DATA"
                        // url={img_url}
                        data={user?.profile_image}
                        imageClass="is-64x64 is-rounded with-out-pointer"
                        default_img={ADMIN_USER_LOGO}
                      />

                      {/* <figure className="image is-64x64 ">
                      <img className="is-rounded crop-customer-top-button-img" src={ADMIN_USER_LOGO} />
                    </figure> */}
                    </div>
                  </div>
                  <div className="customer-profile-border mt-1" key="g7"></div>
                  <div className="" key="g9">
                    <ul className=" has-text-left px-6">
                      <li
                        className="customer-profile-test pointer"
                        onClick={() => navigateLink("profile/my-profile")}
                      >
                        <span className="customer-home-icon-text-color">
                          <i className="fa fa-user-o" aria-hidden="true"></i>
                        </span>
                        <span>My Profile</span>
                      </li>
                      <li
                        className="customer-profile-test pointer"
                        onClick={() => navigateLink("profile/pin-change")}
                      >
                        <span className="customer-home-icon-text-color">
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </span>
                        <span>Settings</span>
                      </li>
                      <li
                        className="customer-profile-test pointer"
                        onClick={() => logout()}
                      >
                        <span className="customer-home-icon-text-color">
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                        </span>
                        <span>Logout</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <span className="icon">
                <i className="f
                a fa-user"></i>
              </span> */}
              <div className="customer-user-image-display" key="j9">
                {/* <SmartImageDisplay
                  srcType="URL_GET"
                  url={img_url}
                  imageClass="  "
                  default_img={ADMIN_USER_LOGO}
                /> */}
                <SmartImageDisplay
                  srcType="DATA"
                  // url={img_url}
                  data={user?.profile_image}
                  imageClass="is-64x64 is-rounded"
                  default_img={ADMIN_USER_LOGO}
                />
              </div>
              {/* <figure className="image is-24x24 ">
                      <img className="is-rounded" src={ADMIN_USER_LOGO} />
                    </figure> */}

              <span>Hi {user?.ename}</span>
              <i
                class="fa fa-sort-desc ml-1 is-size-6 mb-1"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        )}
        {/* {!isLoggedIn() && (
          <>
            <div className=" is-flex">
              <button
                key="business"
                className="button is-rounded crop-customer-laytout-top-button"
              >
                BUSINESS USERS
              </button>

              <button
                key="login"
                className="button is-rounded crop-customer-laytout-top-button"
                onClick={() => navigateLink("login")}
              >
                <span className="icon">
                  <i className="fa fa-user"></i>
                </span>
                <span>Login / Register</span>
              </button>
            </div>
          </>
        )} */}
      </>
    );
  };

  return (
    <>
      {/* mobile view */}
      <div className="is-hidden-tablet">
        <MobileHeader user={user} logout={logout} />
      </div>

      {/* desktop View */}

      <div className="columns smart-crop-customer-header-bar is-vcentered is-hidden-mobile">
        <div className="column is-2 pl-6 ml-3" key="mn1">
          <img className="crops-logo smart-crop-head-logo" src={CROP_LOGO} alt="" />
        </div>
        <div className="column  has-text-centered" key="mn2">
          {homeMenu()}
          {isLoggedIn() && crop_menu()}
          {help_menu()}
          {isLoggedIn() && More_menu()}
        </div>
        {isLoggedIn() && (
          <>
            <div className="column is-3" key="mn3">
              {employeeDetails()}
            </div>
            <div className="column is-1 crop-layout-employee-icons" key="mn4">
              {EmployeeIcons()}
            </div>
          </>
        )}
        {isLoggedIn() && (
          <>
            <div className="column is-2 has-text-right" key="5">
              {lastButtons()}
            </div>
          </>
        )}
        {!isLoggedIn() && (
          <div className="column is-4 has-text-right" key="mn5">
            {LoginButtons()}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerHeader;

/*
   <a className="navbar-content smart-pdf-site-title">
            Soft Digi Solutions
          </a>
  */
