import { useLocation, useNavigate } from "react-router-dom";

const ProfileSideNav = () => {
    const navigate = useNavigate();
    const basenav = "/profile";
    const location = useLocation();
    const { pathname } = location;

    const navigateLink = (index) => {
        navigate(basenav + "/" + index);
    };

    const get_active_class=(index)=>{
        let subString = "profile/" + index;
        if (pathname.indexOf(subString) !== -1) {
            return true; 
        }
        return false;
    }

    const sideNavItems = [
        {
            title: "My Profile",
            link: "my-profile",
            icon: <i className="fa fa-user" aria-hidden="true"></i>
        },
        
        {
            title: "Orders",
            link: "my-orders",
            icon: <i className="fa fa-first-order" aria-hidden="true"></i>
        },

        {
            title: "My Account",
            link: "my-account",
            icon: <i className="fa fa-th-large" aria-hidden="true"></i>
        },
       
        {
            title: "CROP Card",
            link: "my-crop-card",
            icon: <i className="fa fa-id-card-o" aria-hidden="true"></i>
        },
        {
            title: "Settings",
            link: "pin-change",
            icon: <i className="fa fa-cog" aria-hidden="true"></i>
        },
    ]
    return (
        <>
            <div className='profile-side-nav'>
                <div className=''>
                    <ul>
                        {
                            sideNavItems.map((item, index) => (
                                <li key={index}
                                    className='customer-side-nav-items my-3 ml-3'
                                    onClick={() => navigateLink(item.link)}
                                >
                                    <div className={get_active_class(item.link) ? 'profile-list-item-active' : 'list-items '}>
                                        <span className='profile-side-nav-icon'>{item.icon}</span>
                                        <span>{item.title}</span>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </>
    )
}

export default ProfileSideNav
