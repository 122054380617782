
import { Route, Routes } from "react-router-dom";
import {
  AppFAQs,
  AppFeedback,
  ClaimMissingCrops,
  ContactUs,
  PrivacyAndDataRights,
  RequestAndComplaint,
  TermAndConditions,
  TermAndConditionNew,
  PrivacyAndDataRightsNew,
  FAQnew,
} from "../../pages/Customer";
import PrivateRoute from "../PrivateRoute";

const Help = () => {
  return (
    <Routes>
      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="/claim-missing-crops"
        element={
          <PrivateRoute>
            <ClaimMissingCrops />
          </PrivateRoute>
        }
      />
      <Route path="/feedback" element={<AppFeedback />} />
      <Route path="/terms-conditions" element={<TermAndConditions />} />
      <Route path="/terms-conditions-new" element={<TermAndConditionNew />} />
      <Route path="/privacy-data-rights" element={<PrivacyAndDataRights />} />
      <Route
        path="/privacy-data-rights-new"
        element={<PrivacyAndDataRightsNew />}
      />
      <Route path="/app-faqs" element={<AppFAQs />} />
      <Route path="/FAQ-new" element={<FAQnew />} />
      <Route
        path="/request-and-complaint"
        element={
          <PrivateRoute>
            <RequestAndComplaint />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default Help;
