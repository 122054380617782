const  downloadPDF = (base64Data) => {
    // Decode base64 data
    const binaryData = atob(base64Data);
    // Create Blob from binary data
    const blob = new Blob([binaryData], { type: 'application/pdf' });
    // Create URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create anchor element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'example.pdf');
    // Append anchor to body
    document.body.appendChild(link);
    // Trigger download
    link.click();
    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const base64ToFileSize=(base64Data)=> {    // Remove the Base64 metadata if present
     // Calculate the padding (if any)
    const padding = (base64Data.match(/=*$/)[0].length);    
    // Calculate the Base64 string length and adjust for padding
    const base64Length = base64Data.length;
    const sizeInBytes = (base64Length * 3) / 4 - padding;  
    // Convert bytes to kilobytes
    const sizeInKB = sizeInBytes / 1024;  
    return sizeInKB;
  }

  export {
  base64ToFileSize, downloadPDF
};
