import { useEffect, useState } from "react";
import { CUSTOMER_MY_ACCOUNT } from "../../../api/OfferUrls";
import QrCode from "../../../components/QrCode";
import { CARD_INFINITY_IMAGE, CARD_NAME, CROP_BLACK_LOGO, CROP_LOGO } from "../../../services/ImageService";
import "./CropCard.css";
import ProfileSideNav from "./ProfileSideNav";

import moment from "moment";
import SmartHeader from "../../../components/site/SmartHeader";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get } from "../../../services/smartApiService";
import QRCodeOpen from "./QRCodeOpen";

const MyCROPCard = () => {
  const [data, setData] = useState(null);
  const { openModal, closeModal } = useSiteContext();
  const { setLoading } = useSiteContext();
  const loadProfileData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    const subscription = get(
      CUSTOMER_MY_ACCOUNT.GET_PROFILE,
      handleError
    ).subscribe((response) => {
      setData(response.data.userData);
      // console.log("test data",response.data)
      // setFormAddressData(response.data.addrData);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadProfileData();
  }, []);

  const displayCropCord = (crop_card) => {
    let card_display = "";
    if (crop_card.length >= 5) {
      card_display += crop_card.substring(0, 5) + " ";
    }
    if (crop_card.length >= 8) {
      card_display += crop_card.substring(5, 8) + " ";
    }
    if (crop_card.length >= 10) {
      card_display += crop_card.substring(8, 11) + " ";
    }
    // console.log("crop card " , crop_card , " display   ", card_display)
    return card_display;
  }

  const getQRCode = (data) => {
    console.log('QrCode clicked!', data);
    let modelObject = {
      body: (
        <QRCodeOpen
          item={data}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90 modal-card",
      bodyClose: true,
    };
    openModal(modelObject);

  };

  // let str = data?.crop_id;
  // let modifiedStr = str.slice(0, 5) + " " + str.slice(5, 8) + " " + str.slice(8);
  // console.log(modifiedStr); 

  const formattedDateOnly = moment(data?.created_time).format("MM/YY");
  return (
    <>
      <div className="columns">
        {/* <div className="column is-2">
          <ProfileSideNav />
        </div> */}

        {/* <div className="columns"> */}
        <div className="column is-12 px-6">
          <div className="pl-1">
            <SmartHeader title={"CROP Card"} />
          </div>
          {data && data.ID &&
            <div className="my-crop-card-body">
              <div className="card_header ">
                <div className="columns is-multiline p-0 m-0 is-mobile">
                  <div className="column is-8 p-0 m-0 is-flex-mobile">
                    {/* <div className="scannercode">
                    {data && data.crop_id && <QrCode item={data?.crop_id} />}
                  </div> */}

                    <div className="scannercode mt-3">
                      {data && data.crop_id && (
                        <div onClick={() => getQRCode(data.crop_id)}>
                          <QrCode item={data.crop_id} size={65} />
                        </div>
                      )}
                    </div>

                  </div>
                  <div className="column is-4 p-0 m-0 ">
                    <div className="cropname ">
                      <img className="crop-card-crop-logo" src={CROP_BLACK_LOGO} alt="" />
                    </div>
                  </div>
                  <div className="column is-12 p-0 m-0">
                    <h2 className="has-text-white is-size-4">  {displayCropCord(data?.crop_id)} </h2>
                  </div>
                  <div className="column is-8 p-0 m-0 ">
                    <div className="is-size-4 has-text-medium mt-1 has-text-white">
                      <h1>Member Since</h1>
                    </div>
                  </div>
                  <div className="column is-4 p-0 m-0 ">
                    <h6 className="has-text-white mt-1 is-size-4">Valid Thru</h6>
                  </div>
                  <div className="column is-8 p-0 m-0 ">
                    <div className=" is-size-4 mt-1 has-text-white">{formattedDateOnly}</div>
                  </div>
                  <div className="column is-4 p-0 m-0 ">
                    <img
                      className=" has-text-centered mt-1 "
                      src={CARD_INFINITY_IMAGE}
                      alt=""
                      style={{ width: "50px", height: "40px" }}
                    />
                  </div>
                  {/* <div className="column is-12 p-0 m-0 ">
                  <h1 className="is-size-6 has-text-medium has-text-white">
                    CUSTOMER NAME
                  </h1>
                </div> */}
                  <div className="column is-12 p-0 m-0 "></div>
                  <h6 className="is-size-4 has-text-white">
                    {data?.first_name} {data?.last_name}
                  </h6>
                </div>


              </div>
            </div>
          }
        </div>

        {/* </div> */}
      </div>
    </>
  );
};

export default MyCROPCard;
