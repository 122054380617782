import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftDate,
  SmartSoftInput,
  SmartSoftTable,
} from "soft_digi";
import { ORDER_URLS } from "../../../api/OrderUrls";
import { USER_STATEMENT_URL } from "../../../api/Services/ServiceUrls";
import SmartAccordion from "../../../components/site/FormElements/SmartAccordion";
import SmartHeader from "../../../components/site/SmartHeader";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { changeDateTimeZone, monthNameDisplay } from "../../../services/core/CommonService";
import {
  filterIncludes
} from "../../../services/core/FilterService";
import { DownLoadPdf } from "../../../services/fileService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import ProfileSideNav from "./ProfileSideNav";

const SingleYearStateMent = ({ year }) => {
  const [data, setData] = useState([]);
  const { setLoading } = useSiteContext();

  const loadYearData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { statement_year: year };
    const subscription = post(
      USER_STATEMENT_URL.GET_ALL_MONTH,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const downloadReport = (id) => {
    // console.log("id = " , id);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: id };
    const subscription = post(
      USER_STATEMENT_URL.GET_FILE,
      post_data,
      handleError
    ).subscribe((response) => {
      // console.log(response);
      if (response.data.content) {
        DownLoadPdf(response.data.content, "CROP Statement.pdf");
      }
      // setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadYearData();
  }, []);

  return (
    <>
      <div className="columns is-multiline">
        {data.map((item, index) => (
          <div className="column is-2" key={index}>
            <SmartSoftButton
              key={index}
              label={monthNameDisplay(item.month)}
              rightIcon={"fa fa-download"}
              classList={[
                "button mx-1 smart-crop-primary-button is-small ",
              ]}
              onClick={() => downloadReport(item.ID)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

const StateMentYears = () => {
  const [data, setData] = useState([]);
  const { setLoading } = useSiteContext();
  const loadYearData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = {};
    const subscription = post(
      USER_STATEMENT_URL.GET_ALL_YEAR,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadYearData();
  }, []);

  const StatmentYearBody = (year) => {
    return <SingleYearStateMent year={year} key={year} />;
  };

  const getStateMent = () => {
    let out = [];
    data.forEach((item) => {
      out.push({
        title: item.year,
        bodyFunction: StatmentYearBody(item.year),
      });
    });
    return out;
  };

  return (
    <div className="m-6 smart-statement-card-container">
      <SmartAccordion data={getStateMent()} />
    </div>
  );
};

const MyAccount = () => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const [minEndDate, setMinEndDate] = useState();
  const [filterData, setFilterData] = useState([]);
  const [tab, setTab] = useState("RECENT");


  const handleTabs = (index) => {
    // console.log("index " , index);
    setTab(index);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "start_date") {
      setMinEndDate(value);
    }
    if(name == 'search_string'){
      const out_data = data.filter(filterFunction);
      setFilterData(out_data);
    }

  };

  const handleFormSubmit = (type) => {
    //  console.log("formdata", formData);
    setFormSubmit(true);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let data_in = { ...formData, type: type };
    const subscription = post(
      ORDER_URLS.GET_ALL_CUSTOMER,
      data_in,
      handleError
    ).subscribe((response) => {
      setLoading(false);
      setData(response.data);
      setFilterData(response.data);
    });
    return () => {
      subscription.unsubscribe();
    };
    // console.log("form errors ", formErrors);
  };

  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { ...formData };
    post_data["start_date"] = post_data["start_date"]
      ? changeDateTimeZone(formData.start_date)
      : "";
    post_data["end_date"] = post_data["end_date"]
      ? changeDateTimeZone(formData.end_date)
      : "";
   // console.log(post_data);
    const subscription = post(
      USER_STATEMENT_URL.GET_ALL_STATEMENT,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      setFilterData(response.data);
      if(formData.search_string)
      searchData();
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadTableData();
  }, [formData]);
  const filterFunction = (item) => {
    let output;
    output = filterIncludes(
      item,
      ["offer_title", "order_id"],
      formData.search_string
    );
    return output;
  };
  useEffect(() => {
    noDataFound();
  }, [filterData]);

  const searchData = () => {
    const out_data = data.filter(filterFunction);
    setFilterData(out_data);
  };

  const refreshData = () => {
    console.log(data);
    setFilterData(data);
  };

  const EarnCropsShow = (data) => {
    return (
      <>
        {data && data.sd_transaction_type === "DEBIT" && (
          <div>{data.sd_crop_points}</div>
        )}
        {data && data.sd_transaction_type === "CREDIT" && <div>-</div>}
      </>
    );
  };
  const RedeemCropsShow = (data) => {
    return (
      <>
        {data && data.sd_transaction_type === "CREDIT" && (
          <div>{data.sd_crop_points}</div>
        )}
        {data && data.sd_transaction_type === "DEBIT" && <div>-</div>}
      </>
    );
  };

  // const offer_title_disp = (data) => {
  //   // console.log("data" ,data)
  //   let varient_data = data.variant_info
  //   const parsedData = JSON.parse(varient_data);
  //   // console.log("varient_data" ,parsedData)
  //   return (
  //     <>
  //     <p>
  //       {data.offer_title}
  //       <br />
  //       {data.variant_info != null && parsedData.map((item, index) => {
  //         return (
  //           <span key={index}>
  //             <b>{item.variant_group}</b> : {item.variant_name}
  //             <br />
  //           </span>
  //         );
  //       })}
  //     </p>
  //   </>
  //   );

  // };

  const offer_title=(data)=>{
    let offer_title = data.offer_title ? data.offer_title : "";
    if(data.class_astric && data.class_astric==1){
      offer_title = "*" + offer_title;
    }
    if(data.class_red && data.class_red==1){
     // return <span className="has-text-danger">{data.offer_title}</span>
    }
    return data.offer_title;
  }

  const columns = [
    {
      title: "Date",
      index: "sd_last_modified_time",
      type: "date",
      dateFormat: "DD-MM-YYYY",
      width: "10",
      autoTimeZone: true,
    },
    { title: " Offer Title ", index: "offer_title", width: "20",valueFunction: offer_title },
   // { title: "Order No", index: "order_id", width: "10" },
    { title: "Payout", index: "sd_amount_aud", width: "10" },
    {
      title: "Earned CROPs",
      index: "sd_crop_points",
      width: "10",
      valueFunction: RedeemCropsShow,
    },
    {
      title: "Redeemed CROPs",
      index: "sd_crop_points",
      width: "15",
      valueFunction: EarnCropsShow,
    },
  ];

  // const data_old = [
  //   {
  //     order_time: "2019/09/25",
  //     offer_title: "Family HSP",
  //     order_number: "0",
  //     order_total: "$30",
  //     order_earn_crop: "30",
  //     redeemed: "-",
  //   },
  //   {
  //     order_time: "2020/09/25",
  //     offer_title: "Earphones -  Samsung",
  //     order_number: "0",
  //     order_total: "$45",
  //     order_earn_crop: "45",
  //     redeemed: "-",
  //   },
  //   {
  //     order_time: "2021/09/25",
  //     offer_title: "T-Shirt -  Cottonwear",
  //     order_number: "0",
  //     order_total: "$20",
  //     order_earn_crop: "20",
  //     redeemed: "-",
  //   },
  //   {
  //     order_time: "2022/09/25",
  //     offer_title: "Groceries – HR  Quality Foods",
  //     order_number: "0",
  //     order_total: "$  25",
  //     order_earn_crop: "25",
  //     redeemed: "-",
  //   },
  //   {
  //     order_time: "2023/09/25",
  //     offer_title: "Coffee – Coles  Express",
  //     order_number: "0",
  //     order_total: "-",
  //     order_earn_crop: "-",
  //     redeemed: "15",
  //   },
  // ];

  // const StatementData = () => {
  //   const statementDown = () => {
  //     const statementDownData = [
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //       {
  //         label: "02-Jan-2024.pdf",
  //         icon: "fa fa-download",
  //       },
  //     ];
  //     return (
  //       <>
  //         <div className="columns is-multiline">
  //           {statementDownData.map((item, index) => (
  //             <div className="column is-2" key={index}>
  //               <SmartSoftButton
  //                 key={index}
  //                 label={item.label}
  //                 rightIcon={item.icon}
  //                 classList={[
  //                   "button mx-1 smart-crop-primary-button is-small is-rounded",
  //                 ]}
  //               />
  //             </div>
  //           ))}
  //         </div>
  //       </>
  //     );
  //   };
  //   const Statementitem = [
  //     { title: "Jan 2024", bodyFunction: statementDown() },
  //     { title: "Jan-Dec 2023 Statements", bodyFunction: statementDown() },
  //     { title: "Jan-Dec 2022 Statements", bodyFunction: statementDown() },
  //     { title: "Jan-Dec 2021 Statements", bodyFunction: statementDown() },
  //   ];
  //   return (
  //     <>
  //       <div className="m-6 smart-statement-card-container">
  //         <SmartAccordion data={Statementitem} />
  //       </div>
  //     </>
  //   );
  // };



  
  const tabs = [
    //  { index: "CROP", label: "Customer Orders" },
    { index: "RECENT", label: "Recent Transactions", icon: "fa fa-suitcase" },
    { index: "STATEMENT", label: "Statements", icon: "fa fa-stack-exchange" },
  
  ];

  const tabs_display = () => {
    return (
      <div className="tabs smart-customer-tabs mr-6 ml-6">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={tab === item.index ? "order-active-tab" : "tabs-list-items"}
              >
                <a href="#">
              
                  <span
                    className={
                      tab === item.index
                        ? "order-active-tab-text "
                        : " "
                    }
                  >
                    {item.label}
                  </span>
                  <span className="icon is-small">
                    <i className={item.icon} aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const noDataFound =()=>{
    return (
    <> 
        {data.length > 1 &&  filterData && filterData.length < 1 && (
      <div className="no-record-found-text-two">There are no contents to display for your current search</div>
    )}
    {data.length < 1 &&  filterData && filterData.length < 1 && (
      <div className="no-record-found-text-two">There are no contents to display for your current search</div>
    )}
    </>)
  }

  return (
    <>
      <div className="columns">
        {/* <div className="column is-2">
          <ProfileSideNav />
        </div> */}
        <div className="column is-12  m-4">
           <div className="pr-6 pl-1">
             <SmartHeader title={"My Account"} />
         </div>
<div className="mt-3 ">
{tabs_display() }
</div>
       
          <div className="content has-background-white smart-customer-input pr-4">
            {tab==="STATEMENT"&&(
   <div>
   <div className="is-size-5 fredoka-font-family  mt-4 has-text-weight-bold has-text-centered customer-primary-font">
     Statements
   </div>

   <div>
     <StateMentYears />{" "}
   </div>

   </div>
            )}
         
         
            {/* <div className="columns is-centered has-text-centered">
              <span className="column is-3">
                <SmartSoftButton
                  label="Download Statement"
                  classList={["smart-customer-primary-button", "px-5", "mx-4"]}
                  onClick={() => handleFormSubmit("DOWNLOAD")}
                />
              </span>
              <span className="column is-3">
                <SmartSoftButton
                  label="Send to Email"
                  classList={["smart-customer-primary-button", "px-6", "mx-4"]}
                  onClick={() => handleFormSubmit("EMAIL")}
                />
              </span>
            </div> */}
  
   {tab=="RECENT"&&(<div >
<div className="is-size-5 mt-3 fredoka-font-family has-text-weight-bold has-text-centered customer-primary-font">
              Recent Transactions
            </div>
            <div className="columns is-centered is-10  mt-3 ml-5 mr-5 pl-3 pr-3">
              <div className="column is-3" key={"1"}>
                <SmartSoftDate
                  key="text-1"
                  label="From"
                  value={formData?.start_date || ""}
                  onChange={(value) => handleInputChange("start_date", value)}
                  inputProps={{ isFocussed: true }}
                  dateFormat="dd/MM/yyyy"
                  placeHolder="DD/MM/YYYY"
                  maxDate={new Date()}
                />
              </div>

              <div className="column is-3" key={"2"}>
                <SmartSoftDate
                  key="text-2"
                  label="To"
                  value={formData?.end_date || ""}
                  onChange={(value) => handleInputChange("end_date", value)}
                  inputProps={{ isFocussed: true }}
                  dateFormat="dd/MM/yyyy"
                  placeHolder="DD/MM/YYYY"
                  minDate={minEndDate}
                  maxDate={new Date()}
                />
              </div>
              <div className="smart-customer-earn-crop-input-statement column is-4">
                <SmartSoftInput
                  key="text-4"
                  width="6"
                  label="Search"
                  rightIcon="fa fa-search"
                  placeHolder="Search by Offer"
                  value={formData?.search_string || ""}
                  onChange={(value) =>
                    handleInputChange("search_string", value)
                  }
                />
                {/* <div className="smart-customer-button mr-3 ">
                  <SmartSoftButton
                    label="Search"
                    classList={["smart-customer-primary-button-three", ""]}
                    onClick={() => searchData()}
                  />
                </div> */}
              </div>
            </div>
            <div className="ml-6 mr-6 mt-3 mb-3">
              {/* {data && data.length > 0 && (
                )} */}
              <SmartSoftTable
                columns={columns}
                data={filterData}
                tableProps={{ className: "crop-customer-table-layout" }}
              />
              {noDataFound()}
               {/* {data.length > 1 &&  filterData && filterData.length < 1 && (
            <div className="no-record-found-text-two">There are no contents to display for your current search</div>
          )} */}
               
            </div>
    </div>)}
          
          </div>
      
        </div>
     
      </div>
    </>
  );
};

export default MyAccount;
