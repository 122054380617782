import { SmartSoftButton } from "soft_digi";
import {
  ADMIN_USER_LOGO,
  CROP_CUSTOMER_PROMOS_VIEW_IMAGES,
} from "../../../../services/ImageService";

const Tittle = () => {
  return (
    <>
      <div className="container customer-quick-view-container P-5">
        <div className="columns p-4 is-multiline">
          <div className="column is-6 ">
            <div className="columns is-multiline">
              <div className="column is-12">
                <img
                  className="promos-items-images-view"
                  src={CROP_CUSTOMER_PROMOS_VIEW_IMAGES} alt=""
                />
              </div>
              <div className="column is-4">
                <img
                  className="promos-items-images-view"
                  src={CROP_CUSTOMER_PROMOS_VIEW_IMAGES} alt=""
                />
              </div>
              <div className="column is-4">
                <img
                  className="promos-items-images-view"
                  src={CROP_CUSTOMER_PROMOS_VIEW_IMAGES} alt=""
                />
              </div>
              <div className="column is-4">
                <img
                  className="promos-items-images-view"
                  src={CROP_CUSTOMER_PROMOS_VIEW_IMAGES} alt=""
                />
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="">
              <p className="is-size-5 has-text-weight-bold mb-4 mr-4">
                Product Name
              </p>
              <div className="my-3">C 718</div>
              <div className="is-flex">
                <div className="mr-4">
                  <s>A$ 520.00</s>
                </div>
                <div className="">AUD 416.00</div>
              </div>
              <div className="is-flex my-4">
                <div className="mr-4">
                  <i
                    class="fa fa-star has-text-warning mr-2"
                    aria-hidden="true"
                  ></i>
                  4.4
                </div>
                <div className="">
                  <i
                    className="fa fa-thumbs-up smart-customer-text mr-2"
                    aria-hidden="true"
                  ></i>
                  20
                </div>
              </div>
              <div className="is-flex is-justify-content-space-between">
                <SmartSoftButton
                  label="Buy Now"
                  rightIcon="fa fa-file-o"
                  classList={["smart-customer-secondary-button", "px-3"]}
                />
                <SmartSoftButton
                  label="Add To Cart"
                  rightIcon="fa fa-shopping-cart"
                  classList={["smart-customer-secondary-button", "px-3"]}
                />
                <SmartSoftButton
                  label="Add To Wishlist"
                  rightIcon="fa fa-heart-o"
                  classList={["smart-customer-secondary-button", "px-3"]}
                />
              </div>
              <div className=" my-3">
                <span className=" has-text-weight-bold">Brand:</span> Boat
              </div>
              <div className=" my-3">
                <div className=" has-text-weight-bold my-3">
                  Brief Description:
                </div>
                The iPhone is a line of smartphones developed by Apple Inc.
                Known for their sleek design, powerful performance, and
                integration with Apple's ecosystem, iPhones run on the iOS
                operating system and feature high-resolution Retina displays,
                advanced camera capabilities, and strong security features. They
                seamlessly integrate with other Apple devices and services,
                providing a cohesive user experience. The iPhone's regular
                software updates and vast selection of apps from the App Store
                contribute to its popularity and continued success in the
                smartphone market.
              </div>
            </div>
          </div>
          <div className="column is-12">
            <div className="has-text-weight-bold">Detailed Description</div>
            <div>
              The iPhone is a line of smartphones developed by Apple Inc. Known
              for their sleek design, powerful performance, and integration with
              Apple's ecosystem, iPhones run on the iOS operating system and
              feature high-resolution Retina displays, advanced camera
              capabilities, and strong security features. They seamlessly
              integrate with other Apple devices and services, providing a
              cohesive user experience. The iPhone's regular software updates
              and vast selection of apps from the App Store contribute to its
              popularity and continued success in the smartphone market.
            </div>
            <div>
              <div className="">
                <div className="has-text-weight-bold my-4"> Reviews</div>
                <div className="is-flex is-justify-content-space-between">
                  <div className="is-flex">
                    <figure className="image is-48x48 ">
                      <img
                        className="is-rounded admin-account-image "
                        src={ADMIN_USER_LOGO} alt=""
                      />
                    </figure>
                    <div className="mx-4 mt-3">veerahema</div>
                    <div className="mx-4 mt-3">
                      <i
                        className="fa fa-thumbs-up smart-customer-text"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div className=" has-text-right mt-2">
                    2023-12-06T10:22:39.952Z
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tittle;
