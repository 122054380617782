import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SmartSoftButton } from "soft_digi";
import { ADMIN_OPERATIONS_SECTOR } from "../../../../api/OfferUrls";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { get } from "../../../../services/smartApiService";

const RedeemCropsView = ({ homePage = false,handleClick }) => {
  const [data, setData] = useState([]);
  const { setLoading } = useSiteContext();
  const navigate = useNavigate();
  const baseNav = "";

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    const subscription = get(
      ADMIN_OPERATIONS_SECTOR.GET_ALL,
      handleError
    ).subscribe((response) => {
      if (homePage === true) {
        let firstFourElements = response.data.slice(0, 4);
        setData(firstFourElements);
      } else {
        setData(response.data);
      }
      //setData(response.data);
      //console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
    if(handleClick){
      handleClick()
    }
  };

  const purchase_image = (data) => {
    let url = ADMIN_OPERATIONS_SECTOR.GET_IMAGE_REDEEM;
    let id = data.ID !== undefined ? data.ID : 0;
    return <SmartImageDisplay srcType="URL" url={url} id={id}  cacheEnable={true} />;
  };
  const location = useLocation();
  const hideButtonPaths = ["/redeem-crops"];
  const shouldHideButton = hideButtonPaths.includes(location.pathname);

  return (
    <>
      <div
        key="crop_container_one"
        className={
          !shouldHideButton
            ? "redeem-crop-container"
            : "redeem-crop-container-active"
        }
      >
        <div key="earn_crop_view_one" className="customer-earn-crop-view  ">
          <div key="container_one" className="container ">
            <div className="columns">
              {shouldHideButton && (
                <div className="column">
                  <div className="">
                    <Link to="/">
                      <span className=" Earn-Crops-Home-btn-hover">
                        <i className="fa fa-home mr-1" aria-hidden="true"></i>
                        Home{" "}
                         <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      </span>
                      <span className="has-text-white is-size-6">
                       
                        Redeem CROPs
                      </span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div key="columns_one" className="columns is-multiline py-6 ">
              <div
                key="column_one"
                className="column is-12   "
              >
                  <div className="is-flex is-justify-content-space-between mb-4">
                <div
                  key="earn_crop_one"
                  className="smart-customer-earn-crop-text"
                >
                  <div key="redeem_one" className="left-border-earn-crop"></div>
                  Redeem CROPs
                </div>
                <div key="earn_crop_two" className=" has-text-right">
                  {!shouldHideButton && (
                    <SmartSoftButton
                      label="Others"
                      classList={["smart-customer-secondary-button"]}
                      onClick={() => navigateLink("redeem-crops")}
                    />
                  )}
                </div>
              

                </div>
                <div className="smart-earn-crop-sub-text">
                A world of benefit awaits you! Redeems CROPs for your choicest offer
                </div>
              </div>
              {data.map((item, index) => (
                <div key={index} className="column RedeemCrops-View-responsive is-3 ">
                  <div
                    key={index}
                    className="earn-crop-images-files"
                    onClick={() =>
                      navigateLink("offer-details/redeem/" + item.ID)
                    }
                  >
                    {purchase_image(item)}
                    <div
                      key="redeem_two"
                      className="crop-redeem-image-name-box"
                    >
                      {item.sector_name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemCropsView;
