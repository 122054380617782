// import { showAlertAutoClose } from "../../services/notifyService";
// import { post } from "../../services/smartApiService";
// import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
// import { useSiteContext } from "../../contexts/SiteProvider";
import moment from "moment";
import SmartHeader from "../../components/site/SmartHeader";
import { changeDateTimeFormat, changeDateTimeZoneFormat } from "../../services/core/CommonService";
import NotificationMessage from "./NotificationMessage"
// import SmartAccordion from "../../components/site/FormElements/SmartAccordion";
const AccountNotification = ({NotificationData,pats}) => { 
  //console.log("notification " , NotificationData)
  return NotificationData && (
    <div className="NotificationAccount customer-primary-font">
      <div className="ml-6">
      <SmartHeader title={"Account Notification"} />
      </div>

    
      <div className="smart-admin-crop-container mt-2 ">
        <p className=" pb-6 pl-3 pt-4 has-text-centered has-text-weight-bold is-size-4">
          <span className=" "> Account Notification</span>
        </p>
      </div>
      <div className="columns">
        <div className="column mx-6 ">
          <ul>
            {NotificationData.map((item,index) => {
              return (
                <>
                  <div className="columns">
                    <div className="column is-1 ml-3 pl-6 pr-6 mt-1 pt-3">
                      <li className="icon is-size-5 is-medium crop-notification-icon mt-1 ">
                        <i class="fa fa-bell" aria-hidden="true"></i>
                      </li>
                    </div>
                    <div className="column is-9">
                      {" "}
                      <li className={item.notification_status===0?"ml-3 mt-1 has-text-danger":"ml-3 mt-1 has-text-black"}>
                      {/* {item.notification_message} */}
                      <NotificationMessage message={item.notification_message} pats={pats} />
                      </li>
                    </div>
                    <div className="column is-2  pr-6">
                      <li className={item.notification_status===0?"ml-3 mt-1 has-text-danger":"ml-3 mt-1 has-text-black"}>{changeDateTimeZoneFormat(item.notification_date)}</li>
                    </div>
                  </div>

                  <hr />
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccountNotification;

