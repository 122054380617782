import { CROP_CUSTOMER_HOME_IMAGE_2, CROP_CUSTOMER_HOME_IMAGE_3, CUSTOMER_HOME_IMAGE } from '../../../services/ImageService'

const HomePageImage = () => {
  return (
   <div className='container  '>
    <div className='columns smart-mobile-columns is-flex-touch'>
        <div className='column smart-image-column '>
        <img className='customer-home-image-container-image' src={CUSTOMER_HOME_IMAGE} alt=''/>
        </div>
        <div className='column is-4 smart-image-column smart-home-cloud-image  '>
        <img className='customer-home-image-container-image ' src={CROP_CUSTOMER_HOME_IMAGE_2} alt=''/>
        </div>
        <div className='column is-4 smart-image-column '>
        <img className='customer-home-image-container-image' src={CROP_CUSTOMER_HOME_IMAGE_3} alt=''/>
        </div>
    </div>
   </div>
  )
}

export default HomePageImage