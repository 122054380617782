import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  CROP_CUSTOMER_HOME_IMAGE_2,
} from '../../services/ImageService';
import "./ImageCarousel.css";


const ImageCarousel = (props) => {

  const {
    data = [],
    isMulti = false,
    showImages = 4,
    showButtons = false,
    buttonsClassName = "",
    dots = false,
    showImageNumber = 0,
    containerClass = "iamge-sldier-single-div"
  } = props;

  const [index, setIndex] = useState(0);
  const [cindex, setCIndex] = useState(0);
  const [content, setContent] = useState("");

  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const gotoSlide = (slideNumber) => {
    sliderRef.slickGoTo(slideNumber)
  }

  useEffect(() => {
    gotoSlide(showImageNumber);
    if(data && data.length > 0){
      setContent(data[0].content)
    }
  }, [showImageNumber]);


  const settings = {
    customPaging: function (i) {
      // console.log("customer paheong " , i);
      return (
        <a>
          <img src={CROP_CUSTOMER_HOME_IMAGE_2} alt='' />
        </a>
      );
    },
    infinite: false,
    speed: 3000
  };

  const beforeChange = (prev, index) => {
    setIndex(index);
    //console.log("prev ", prev , " index " , index);
  }

  const prevNextButtons = () => {
    return (
      <div className={buttonsClassName}>
        <button disabled={index == 0} className="button is-previous " onClick={previous} key="prev">
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button disabled={index == data?.length - 1} className="button is-next " onClick={next} key="next">
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    )
  }
  //console.log(settings);

  if (showButtons === false) {
    settings["autoplay"] = false;
    settings["autoplaySpeed"] = 5000;
    settings["cssEase"] = "linear";
  }
  if (isMulti === true) {
    settings["slidesToShow"] = showImages;
    settings["slidesToScroll"] = 1;
  }
  if (dots === true) {
    settings["dots"] = true;
  }

  settings["beforeChange"] = beforeChange

  
  const getClassBasedOnLength = () => {
    if (data.length === 2) {
      return 'react-slick-two-images';
    } else if (data.length === 3) {
      return 'react-slick-three-images';
    } else {
      return 'multiple-images';
    }
  };

  return (
    <>
      {content &&
        <figure class="image is-4by3">
          <img
            className="is-square "
            src={`data:image/png;base64,${content}`}
            alt=""
          />
        </figure>
      }
      <div className={getClassBasedOnLength()}>
      <Slider ref={slider => {
        sliderRef = slider;
      }} {...settings}>
        {data.map((item, index) => {
          const imgage_class  = (index==cindex ? "smart-image-active" : "");
          return <div key={index} className={isMulti ? "image-slider-multi-div " + imgage_class: containerClass}
            onClick={() => {setCIndex(index); setContent(item.content)}}>
            {item.data}
          </div>
        })}
      </Slider>
      </div>
      {showButtons === true && prevNextButtons()}
    </>
  )
}

export default ImageCarousel