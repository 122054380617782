import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SmartSoftForm, SmartValid } from "soft_digi";
import croplogo from "../../../assets/images/croplogo.png";
import {
  COLOR_BLUE,
  COLOR_ORAGE_LIGHT,
  COLOR_ORANGE,
  FORGOT_PIN_PIC,
} from "../../../services/ImageService";
// import "./LoginPage.css";

import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import { getRemeberMe, getSessionStorage } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import CustomerLoginLayout from "../../../themes/CustomerLayout/CustomerLoginLayout";

const CustomerForgot = () => {
  const [formData, setFormData] = useState({
    login_type: "1",
    user_type: "CUSTOMER",
    country_code: "AU",
    euserid: "C-",
  });
  const [formSubmit, setFormSubmit] = useState(false);

  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const [stage, setStage] = useState("FIRST");
  const navigate = useNavigate();

  const resetData = () => {
    handleInputChange("euserid", "");
    //handleInputChange("password","");
    // handleInputChange("confirm_password","");
  };
  const resetOTPData = () => {
    handleInputChange("otp_value", "");
    handleInputChange("password", "");
    //console.log("resetOTPData")
  };
  const handleInputChange = (name, value) => {
    // console.log(" name " ,name , "   value " , value)
    //debugger;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name == "login_type") {
      resetData();
      if (value == 1) {
        setFormData((prev) => ({ ...prev, euserid: "C-" }));
      }
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  useEffect(() => {
    if (formData.login_type === "1") {
      if (formData?.euserid?.length < 2) {
        setFormData((prev) => ({ ...prev, euserid: "C-" }));
      }
    }
  }, [formData]);

  const handleRemeberMeLoad = () => {
    let remember_data = getRemeberMe();
    //console.log(" rember  " , remember_data);
    if (remember_data) {
      let checkbox_remember_me =
        remember_data.checkbox_remember_me !== undefined
          ? remember_data.checkbox_remember_me
          : "0";
      if (checkbox_remember_me === "1") {
        setFormData(remember_data);
      }
    }
  };
  useEffect(() => {
    // handleRemeberMeLoad();
    resetOTPData();
    let formData_session = getSessionStorage("loginFormData");
    if (formData_session) {
        setFormData(formData_session)
    }   
  }, []);

  
  useEffect(() => {
    resetOTPData();
  }, [stage]);

  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");
    let url = BUSINESS_LOGIN_URLS.SEND_OTP_FORGOT;
    let login_data = { ...formData };
    login_data["user_type"] = "CUSTOMER";
    const subscription = post(url, login_data, handleError, false).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        handleInputChange("uid", response.data.uid);
        setFormSubmit(false);
        showAlertAutoClose("OTP sent successfully ", "success");
        setLoading(false);
        setFormSubmit(false);
        setStage("SECOND");
        resetOTPData();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };


  const handleVerify = () => {
    //setFormSubmit(true);
    setFormSubmit(true);
    if (!ValidateFormNew(formData, stage2_elements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Verifying the details....Please Wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.RESET_PASSWORD_OTP;
    const subscription = post(
      url,
      { ...formData, user_type: "CUSTOMER" },
      handleError
    ).subscribe((response) => {
      let msg = response?.data?.msg || "Password Reset Successful";
      showAlertAutoClose(msg, "success");
      setFormSubmit(false);
      setLoading(false);
      navigate("/login");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const forgotPinValidations = {
    email: [SmartValid.required("Email Id is Required")],
    cropid: [SmartValid.required("Crop Id is Required")],
    mobile_no: [
      SmartValid.required("Mobile Number is Required"),
      //  SmartValid.minLength("Number should be minimum 10 digit",9)
    ],
  };

  const verifyValidations = {
    otp: [
      SmartValid.required("OTP Required"),
      SmartValid.minLength("Minimun 6 Digit", 6),
    ],
    password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit",8),
      // SmartValid.maxLength("Maximum 15 Digit",15),
    ],
    cnf_password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit",8),
      // SmartValid.maxLength("Maximum 15 Digit",15),
      SmartValid.custom((value) => {
        return formData.password !== value
          ? "Password & Confirm Password are not Same"
          : "";
      }),
    ],
  };

  const loginLogo = () => {
    return (
      <div>
        <figure className=" is-flex is-justify-content-center Forgot-cropimage-logo pt-3">
          <img src={croplogo} height="80" width="200" alt="Crop Logo" />
        </figure>
      </div>
    );
  };
  const loginTitle = () => {
    return (
      <div className="mt-2 mb-2">
        <p className="has-text-centered is-size-4 mb-3 customer-login-textcolor">
          Forgot Password?
        </p>
        <p className="has-text-centered mb-5 has-text-black customer-primary-font">
          Enter your details to reset Password.
        </p>
      </div>
    );
  };
  const options = [
    { value: "1", label: "CROP ID" },
    { value: "2", label: "Mobile" },
    { value: "3", label: "Email" },
  ];
  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "login_type",
      classList: "has-text-black",
      element: {
        options: options,
        isMulti: true,
        type: "radio",
        classList: ["has-text-weight-medium"],
        //validations:numericValidations
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "euserid",
      codeName: "country_code",

      classList: "has-text-black",
      element: {
        pattern: ALLOW_NUMERIC,
        //validations:numericValidations
        value: formData?.euserid || "",
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        placeHolder: "Enter Mobile Number",
        rightIcon: "fa-mobile",
        validations: forgotPinValidations.mobile_no,
         autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.login_type !== "2" ? true : false;
      },
    },

    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter CROP ID",
        rightIcon: "fa fa-user-o",
        validations: forgotPinValidations.cropid,
         autoComplete:"new-password"

        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData?.login_type !== "1" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        placeHolder: "Enter EMAIL ID",
        rightIcon: "fa fa-envelope-o",
        validations: forgotPinValidations.email,
         autoComplete:"new-password"
        // validations:numericValidations
      },
      hideFunction: (data) => {
        return formData?.login_type !== "3" ? true : false;
      },
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Reset",
        classList: ["customer-login-action-button"],
        onClick: () => handleLogin(),
      },
    },
  ];

  const formDisplay = () => {
    return (
      <div className="crop-business-login-form ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  const loginSignUp = () => {
    return (
      <div>
        <p className="has-text-centered mt-4 has-text-black has-text-weight-medium">
          {/* Remember your password ? */}
          <Link to="/login" className="ml-3">
            <span className="back-to-login-text customer-primary-font">
              {" "}
              Back to Login
            </span>
          </Link>
        </p>
      </div>
    );
  };

  const colorshaderounded = () => {
    return (
      <>
        <div className="customer-login-colorshade is-hidden-mobile">
          <span className="customer-login-colororange">
            <img src={COLOR_ORANGE} alt=""></img>
          </span>
          <span className="customer-login-colorblue">
            <img src={COLOR_BLUE} alt=""></img>
          </span>
          <span className="customer-login-colororangelight">
            <img src={COLOR_ORAGE_LIGHT} alt=""></img>
          </span>
        </div>
      </>
    );
  };
  const stage2_elements = [
    {
      type: "OTP",
      width: "12",
      name: "otp_value",
      element: {
        digits: 6,
        rightIcon: "fa-envelope-o",
        inputType: "BORDER_LABEL",
        // label: "Enter Code",
        validations: verifyValidations.otp,
        pattern: ALLOW_NUMERIC,
        max: 6,
         autoComplete:"new-password",
        resendOtpFunction: (callback) => {
          handleLogin(null);
          callback();
        },

        otpButtonText: (status, timer) => {
          return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
        },
        // validations:[SmartValid.required("OTP Required"),
        // SmartValid.minLength("Minimun 6 Digit", 6),]
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "password",
      element: {
        placeHolder: "Enter Password",
        validations: verifyValidations.password,
        passwordValidator: true,
         autoComplete:"new-password"
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "confirm_password",
      element: {
        placeHolder: "Confirm Password",
        validations: verifyValidations.cnf_password,
        passwordValidator: true,
         autoComplete:"new-password"
      },
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Reset",
        classList: ["customer-login-action-button"],
        onClick: () => handleVerify(),
      },
    },
  ];
  const verification_form = () => {
    return (
      <div className="crop-business-login-form crop-business-form ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={stage2_elements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  const stage1_forms = () => {
    return (
      <>
        {loginTitle()}
        {formDisplay()}
        {loginSignUp()}
      </>
    );
  };

  const stage2_forms = () => {
    return (
      <>
        <div className="">
          <p className="has-text-centered is-size-4 customer-login-textcolor mt-1 mb-3">
            {" "}
            Forgot Password
          </p>
          <p className="has-text-centered mb-4 crop-login-desc">
            <span> Enter verification code to verify your </span>
            <span>account and create a new Password </span>
          </p>
        </div>
        {verification_form()}
        {loginSignUp()}
      </>
    );
  };

  // return (
  //   <div className="Login">
  //     <div className="">
  //       <div className="columns  card Customer-Login-Forgot-contentpage" >
  //         <div className="column is-6 is-hidden-mobile">
  //           <div className="Customer-Login-said-image ">
  //             <img src={FORGOT_PIN_PIC} alt=""></img>
  //           </div>
  //         </div>

  //         <div className="column customer-login-formmain  card is-6">
  //           <div className="Customer-Forgot-cardform ">
  //             <span>{loginLogo()}</span>
  //             <div className=" is-hidden-tablet smart-mobile-view-image has-text-centered my-2">
  //             <img src={FORGOT_PIN_PIC} alt=""></img>
  //           </div>
  //             {stage === "FIRST" && stage1_forms()}
  //             {stage === "SECOND" && stage2_forms()}
  //             <span>{colorshaderounded()}</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <CustomerLoginLayout sideImage={FORGOT_PIN_PIC} tabImage={FORGOT_PIN_PIC}>
      {stage === "FIRST" && stage1_forms()}
      {stage === "SECOND" && stage2_forms()}
    </CustomerLoginLayout>
  );
};

export default CustomerForgot;
