const SERVICES_REQUEST_URLS = {
  INSERT: "/services/request/insert",
  GET_ALL_ADMIN: "/services/request/get_all/admin",
  GET_ALL_USER: "/services/request/get_all/user",
  UPDATE: "/services/request/update",
  GET_ONE: "/services/request/get_one",
};

const SERVICES_COMPLAINT_URLS = {
  INSERT: "/services/complain/insert",
  GET_ALL_ADMIN: "/services/complain/get_all/admin",
  GET_ALL_USER: "/services/complain/get_all/user",
  UPDATE: "/services/complain/update",
  GET_ONE: "/services/complain/get_one",
  GET_DROP_DOWN: "/services/user_site/get_all_const",
};

const SERVICES_NOTIFICATION_URLS = {
  GET_ALL: "/services/notification/get_all",
  MARK_ALL_READ: "/services/notification/update_by_user_id",
};
const FEEDBACK_URL = {
  FEEDBACK_INSERT: "/services/feedback/insert",
};

const USER_STATEMENT_URL = {
  GET_ALL_STATEMENT: "/services/user/get_all_statement",
  GET_ALL_YEAR: "/services/user/get_all_year",
  GET_ALL_MONTH: "/services/user/get_all_month",
  GET_FILE: "/services/user/download_statement",
  GET_RETURN_POLICY: "services/user_site/get_return_policy",
};
const REVIEW_STATUS ={
  REVIEW_INSERT:"/business/ratings/insert"
}

export {
  SERVICES_COMPLAINT_URLS,
  SERVICES_REQUEST_URLS,
  SERVICES_NOTIFICATION_URLS,
  FEEDBACK_URL,
  USER_STATEMENT_URL,
  REVIEW_STATUS,
};
