import "./SmartFormElements.css";
const SmartFileDisplay = (props) => {
  const { updateImages, files, isMulti = false } = props;

  const deleteImage = (indexToRemove) => {
    if (!isMulti) {
      updateImages(null);
    } else {
      const updatedItems = [...files];
      // Remove the element at the specified index
      updatedItems.splice(indexToRemove, 1);
      //
      if (updateImages) updateImages(updatedItems);
    }
  };

  const singleFile = (input, index) => {
    return input && (
      <li>
        {input?.name}
        <i
          className="fa fa-trash has-text-danger"
          onClick={() => deleteImage(index)}
        ></i>
      </li>
    );
  };

  return <ul className="smart-file-disp">{singleFile(files)}</ul>;
};

export default SmartFileDisplay;
