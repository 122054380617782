import { useState } from "react";
import { SmartSoftForm, SmartValid } from "soft_digi";
import SmartSoftButton from "soft_digi/dist/forms/SmartButton";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { FEEDBACK_URL } from "../../../../api/Services/ServiceUrls";
import SmartHeader from "../../../../components/site/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  CROP_CUSTOMER_HELP_APP_FEEDBACK,
  SMAIL_PIC_FIVE,
  SMAIL_PIC_FOUR,
  // SMAIL_PIC_FullSTAR,
  SMAIL_PIC_ONE,
  SMAIL_PIC_THREE,
  SMAIL_PIC_TWO,
} from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import "./AppFeedback.css";
import { useNavigate } from "react-router-dom";

const AppFeedback = () => {
  const [formData, setFormData] = useState({});
  const [feedbackValue, setFeedbackValue] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading } = useSiteContext();
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  // const [sideKey, setSideKey] = useState("1");

  const baseNav = "";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Submitting.... Please Wait");
    // let url = MANAGE_OFFER_URLS.INSERT;
    let url = FEEDBACK_URL.FEEDBACK_INSERT;
    formData.feedback_value = formData.rating;
    //console.log()
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setLoading(false);
        // console.log(response);
        showAlertAutoClose("Feedback Submitted Succcessfully", "success");
        setFormData({});
        setFormSubmit(false);
       // setFeedbackValue("");
       navigateLink("");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const FormValidations = {
    description: [SmartValid.required("Description is Required")],
  };
  const formElements = [
    {
      type: "TEXTAREA",
      width: "12",
      name: "feedback_remark",
      element: {
        // label:"Describe your experience here",
        // validations: FormValidations.description,
        max:"255",
         placeHolder: "Describe your experience here (Optional)",
        // isRequired: true,
      },
    },
  ];
  const get_feedback_value = (type) => {
    // console.log("HAI", type);
    setFeedbackValue(type);
    setIsActive(!isActive);
  };
  // const handleFormSubmit = () => {};
  const formDisplay = () => {
    return (
      <>
        <div className="mt-3">
          <SmartSoftButton
            label="Submit"
            classList={["smart-customer-primary-button", "px-6"]}
            onClick={handleSubmit}
          />
        </div>
      </>
    );
  };

  const items = [
    {
      rating: 1,
      img_src: SMAIL_PIC_ONE,
      title: "Poor",
    },
    {
      rating: 2,
      img_src: SMAIL_PIC_TWO,
      title: "Average",
    },
    {
      rating: 3,
      img_src: SMAIL_PIC_THREE,
      title: "Good",
    },
    {
      rating: 4,
      img_src: SMAIL_PIC_FOUR,
      title: "Very Good",
    },
    {
      rating: 5,
      img_src: SMAIL_PIC_FIVE,
      title: "Excellent",
    },
  ];

  return (
    <>
      <div className="px-5 ml-5">
        <SmartHeader title={"Rate Our Service"} />
      </div>
      <div className="column is-12 has-text-centered">
        <h1 className="mt-5 customer-header">Feedback</h1>
        <p className="mt-3 App-Feedback-Form-subheader-title customer-primary-font">
          How would you rate our service?
        </p>
        <div
          key={"AppFeedbackFormHeader-columns-two"}
          className="columns is-centered"
        >
          <div
            key={"AppFeedbackFormHeader-column-two"}
            className="column is-12 is-flex is-justify-content-space-around has-text-centered  AppFeedbackFormimage-responsive"
          >
            <div className="columns is-flex media-screen-feedback-columns">
              {items.map((obj, index) => {
                return (
                  <div className="column media-screen-feedback mt-4 mb-3" key={index}>
                    <img
                      src={obj?.img_src}
                      alt=""
                      className={
                        formData.rating === obj.rating
                          ? "AppFeedbackFormimagehovering-active "
                          : ""
                      }
                      onClick={() => handleInputChange("rating", obj.rating)}
                    />
                    <div className="has-text-centered customer-primary-font">
                      {obj.title}{" "}
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="column ">
              <img
                src={SMAIL_PIC_ONE}
                alt=""
                className={
                  isActive
                    ? "AppFeedbackFormimagehovering"
                    : "AppFeedbackFormimagehovering-active"
                }
                // className={"AppFeedBack-Image-smail"}
                onClick={() => get_feedback_value("Poor")}
              />

              <div className="has-text-centered">Poor </div>
            </div>

            <div className="column ">
              <img
                src={SMAIL_PIC_TWO}
                alt=""
                className={
                  isActive
                    ? "AppFeedbackFormimagehovering"
                    : "AppFeedbackFormimagehovering-active"
                }
                // className={"AppFeedBack-Image-smail"}
                onClick={() => get_feedback_value("Average")}
              />

              <div className="has-text-centered">Average</div>
            </div>

            <div className="column ">
              {" "}
              <img
                src={SMAIL_PIC_THREE}
                alt=""
                className={
                  isActive
                    ? "AppFeedbackFormimagehovering"
                    : "AppFeedbackFormimagehovering-active"
                }
                // className={"AppFeedBack-Image-smail"}
                onClick={() => get_feedback_value("Good")}
              />
              <div className="has-text-centered">Good</div>
            </div>

            <div className="column ">
              <img
                src={SMAIL_PIC_FOUR}
                alt=""
                className={
                  isActive
                    ? "AppFeedbackFormimagehovering"
                    : "AppFeedbackFormimagehovering-active"
                }
                // className={"AppFeedBack-Image-smail"}
                onClick={() => get_feedback_value("Very Good")}
              />

              <div className="has-text-centered">Very Good</div>
            </div>

            <div className="column ">
              {" "}
              <img
                src={SMAIL_PIC_FIVE}
                alt=""
                className={
                  isActive
                    ? "AppFeedbackFormimagehovering"
                    : "AppFeedbackFormimagehovering-active"
                }
                // className={"AppFeedBack-Image-smail"}
                onClick={() => get_feedback_value("Excellent")}
              />
              <div className="has-text-centered">Excellent</div>
            </div> */}
            {/* <div className="column is-1"></div> */}
          </div>
        </div>
        <div
          key={"AppFeedbackFormHeader-columns-three"}
          className="columns is-centered"
        >
          <div
            key={"AppFeedbackFormHeader-column-three"}
            className="column is-4"
          >
            <div className="AppFeedbackFormTextarea ">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={formElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>
          </div>
        </div>

        {formDisplay()}
        <div className="">
          <img
            src={CROP_CUSTOMER_HELP_APP_FEEDBACK}
            className="AppFeedback-image is-justify-content-center mt-3"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default AppFeedback;
