import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import SmartButton from "soft_digi/dist/forms/SmartButton";
import { CUSTOMER_WISHLIST_URLS, ORDER_URLS } from "../../../api/OrderUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
// import {
//   CROP_CUSTOMER_CROP_IMAGE,
// } from "../../../services/ImageService";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../services/notifyService";
import { get, post } from "../../../services/smartApiService";
import Address from "./Address";
// import SmartStripePayment from "../../../components/site/SmartStripePayment";
import { CUSTOMER_OFFERS } from "../../../api/OfferUrls";
import { CUSTOMER_USER_URLS } from "../../../api/Services/UserUrls";
import SmartImageDisplayNew from "../../../components/site/FormElements/SmartImageDisplayNew";
import SmartHeader from "../../../components/site/SmartHeader";
import {
  displayDots,
  getLocalTime,
} from "../../../services/core/CommonService";
import { encrypt_data } from "../../../services/sessionService";

const CheckOut = () => {
  const [data, setData] = useState([]);
  const { type, order } = useParams();
  const state = type === "redeem" ? "REDEEM" : "EARN";
  const [address, setAddress] = useState(null);
  const [addressOpt, setAddressOpt] = useState([]);
  // const [state, setState] = useState(type_state);
  const { setLoading, user, refreshUser } = useSiteContext();
  const navigate = useNavigate();
  const {
    //  isLoading,

    closeModal,
  } = useSiteContext();
  // const [formData, setFormData] = useState({});
  // const [formSubmit, setFormSubmit] = useState(false);

  // const [formErrors, setFormErrors] = useState(false);

  //console.log("table with project ", )

  const loadWishListData = (e) => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Loading....Please Wait");
    let url = CUSTOMER_WISHLIST_URLS.GET_USER_WISHLIST;
    let offer_type = state;
    let cart_type = order !== undefined ? "BUY" : "CART";
    let data_in = { type: cart_type, offer_type: offer_type, confirm_order: 1 };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        setData(response.data);
        // console.log("response", response);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadAddressData = (e) => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Loading....Please Wait");
    let url = CUSTOMER_USER_URLS.GET_ALL_ADDRESS;
    const subscription = get(url, handleError).subscribe((response) => {
      setLoading(false);
      setAddressOpt(response.data);
      if (response.data && response.data.length > 0) {
        setAddress(response.data[0]);
      }

      // console.log("response", response);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  // const handleInputChange = (name, value) => {
  //   //console.log(" name ", name, "   value ", value)
  //   setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  // const handleErrorChange = (name, value) => {
  //   setFormErrors((prev) => {
  //     // Create a copy of the previous state
  //     const updatedFormData = { ...prev };
  //     // Check if the value is null or empty
  //     if (value === null || value === "") {
  //       // If true, remove the property from the updated state
  //       delete updatedFormData[name];
  //     } else {
  //       // Otherwise, update or add the property in the updated state
  //       updatedFormData[name] = value;
  //     }

  //     return updatedFormData;
  //   });
  // };

  const checkDelivery = () => {
    return data.find((item) => item.pickupDelivery === 2) ? true : false;
  };

  const checkOfferNotAvailble = () => {
    return data.find((item) => item.offer_status === 1) ? true : false;
  };

  const checkOfferBlueDay = () => {
    return data.find((item) => item?.blue_day === 1) ? true : false;
  };

  const checkPickup = () => {
    //console.log("item ", data);
    return data.find(
      (item) =>
        item.pickupDelivery === 1 &&
        (item.end_flag === 1 || item.open_close == 0)
    )
      ? true
      : false;
  };

  const submitOrderAndPayment = () => {
    // console.log("delivery address ", address);
    if (
      checkDelivery() &&
      (!address || address == null || !address.ID || !address.state)
    ) {
      showAlertAutoClose("Please add a delivery Address", "error");
      return false;
    }
    // return false;
    if (checkPickup()) {
      showAlertAutoClose(
        "Your order includes offer(s) for business(es) that are currently closed.Please move these offer(s) to wishlist to proceed further.",
        "error",
        10000
      );
      return false;
    }
    //return false;
    if (checkOfferNotAvailble()) {
      showAlertAutoClose(
        "The offer(s) is not available. Please remove to proceed. ",
        "error",
        10000
      );
      return false;
    }

    if (state == "REDEEM" && checkOfferBlueDay()) {
      showAlertAutoClose(
        "The offer(s) is not avaiable for redemption today. Please remove to proceed.",
        "error",
        50000
      );
      return false;
    }

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading....Please Wait");
    let url = ORDER_URLS.INSERT;
    let data_in = {
      order_type: state,
      order_data: [...data],
      address: address,
    };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // console.log("response ", response);
        if (response.data.payment && response.data.payment === "no") {
          // console.log("entererd with out payment");
          updatePayment(response.data);
        } else {
          setLoading(false);
          // console.log("entered in payment");
          let id = encodeURIComponent(encrypt_data("" + response.data.id));
          navigate("/whish-list/payment", { state: { id: id } });
          //  let id = encodeURIComponent(encrypt_data("" + response.data.id));
          //navigate("/whish-list/payment/" + id);
        }
        // console.log("response ", response);
        // let id = encodeURIComponent(encrypt_data("" + response.data.id));
        //setLoading(false);
        // navigate("/whish-list/payment/" + id);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const updatePayment = (data_i) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading.... Please Wait");
    let url = ORDER_URLS.UPDATE;
    let data_in = { id: data_i.id };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // console.log("response ", response);
        setLoading(false);
        // showAlertAutoClose("Order Placed Successfully ", "success");
        // let id = encodeURIComponent(encrypt_data("" + data_i.id));
        // navigate("/whish-list/invoice/" + id);
        refreshUser();
        navigate("/");
        showAlertAutoClose("Order Placed Successfully", "success");
        // need to navigate to confirm order
        //navigate("/");
        // showAlertAutoClose("Offer Moved to Cart", "success");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };
  const deleteWhishList = (id) => {
    showYesOrNoAlert(
      "Do you wish to delete this offer?",
      (selection) => deleteWhishlist(id, selection),
      "danger"
    );
  };
  const deleteWhishlist = (id, action) => {
    if (action === "yes") {
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
        setData([]);
      };
      setLoading(true, "Deleting.... Please Wait");
      let url = CUSTOMER_WISHLIST_URLS.DELETE_WISHLIST;
      let data_in = { id: id };
      const subscription = post(url, data_in, handleError).subscribe(
        (response) => {
          setLoading(false);
          loadWishListData();
          showAlertAutoClose("Offer Deleted from Cart", "success");
          refreshUser();
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  };

  useEffect(() => {
    loadWishListData();
    loadAddressData();
  }, [state]);

  // const options = [
  //   { value: "1", label: "Delivery" },
  //   { value: "2", label: "Pickup" },
  // ];

  // const formElements = [
  //   {
  //     type: "CHECK_RADIO",
  //     width: "6",
  //     name: "radio_one",
  //     element: {
  //       // label: "Shipping:",
  //       // isHorizontal: true,
  //       options: options,
  //       isMulti: true,
  //       type: "radio",
  //       // classList: ["has-text-white"],
  //       //validations:numericValidations
  //     },
  //   },
  // ];
  // const displayAmount = (totalPrice) => {
  //   return Number(totalPrice).toLocaleString(undefined, {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  // };
  const baseNav = "";
  const navigateLinksTo = (index) => {
    navigate(baseNav + "/whish-list/view-cart" + index);
  };

  const totalRounded = (totalPrice) => {
    return Math.ceil(totalPrice);
  };
  const displayAmount = (totalPrice) => {
    return Math.round(totalPrice * 100) / 100;
  };

  const getDeliveryCharges = (data) => {
    if (state === "REDEEM") {
      return data?.del_crop && data?.del_crop === 1
        ? data?.del_crop_value
        : data?.del_aud;
    } else {
      return data?.del_aud;
    }
  };

  const getDeliverySymbol = (data) => {
    if (state === "REDEEM") {
      return data?.del_crop && data?.del_crop === 1 ? "" : "A$";
    } else {
      return "A$";
    }
  };

  const ShippingType = (data) => {
    //console.log("dev", data);
    return (
      <>
        <div className="shipping-type">
          <div className="">
            {data?.offer_title}
            <br />
            {data.variants.map((item) => {
              return (
                <span className="is-size-7 ml-2">
                  <b>{item.variant_group}</b> : {item.variant_name}
                </span>
              );
            })}
            {data?.custom_note && data?.custom_note?.length > 0 && (
              <div className="">
                <div className="smart-lable-customer">
                  Customer Notes:{" "}
                  <span className="smart-font-style-customer ">
                    {" "}
                    {displayDots(data.custom_note)}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="control is-flex shipping-type-button is-justify-content-center">
            {data?.pickup ? (
              <label className="radio is-flex">
                <input
                  type="radio"
                  name={"delivery_" + data.ID}
                  checked={data?.pickupDelivery === 1}
                  onChange={(event) => updatePickUpDelivery(data?.ID, 1)}
                  disabled={data?.pickupDisabled || data?.open_close == 0}
                />
                {data?.open_close != 0 && (
                  <span className=" shipping-type-label">
                    Pickup ({getLocalTime(data?.start_time)} -{" "}
                    {getLocalTime(data?.end_time)})
                  </span>
                )}
                {data.open_close == 0 && (
                  <span className=" shipping-type-label">Pickup (Closed)</span>
                )}
              </label>
            ) : (
              ""
            )}
            {data.delivery ? (
              <label className="radio is-flex">
                <input
                  type="radio"
                  name={"delivery_" + data.ID}
                  checked={data?.pickupDelivery === 2}
                  onChange={(event) => updatePickUpDelivery(data?.ID, 2)}
                  disabled={data?.deliveryDisabled}
                />
                <span className=" shipping-type-label">
                  {" "}
                  Delivery (
                  {data && data.del_aud === 0
                    ? "FREE"
                    : getDeliverySymbol(data) + " " + getDeliveryCharges(data)}
                  )
                </span>
              </label>
            ) : (
              ""
            )}
          </div>
          <div className="blue-days">
            {data.blue_day == 1 ? "Blue Days" : ""}
          </div>
        </div>
      </>
    );
  };

  const offer_image = (data) => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data.sd_offers_id !== undefined ? data.sd_offers_id : 0;
    return (
      <>
        <div className="is-flex is-justify-content-center smart-table-order-image">
          <SmartImageDisplayNew
            srcType="URL"
            url={url}
            id={id}
            imageClass="is-64x64 image "
          />
        </div>
      </>
    );
  };
  const offer_title_disp = (data) => {
    return (
      <>
        <p>
          {data.offer_title} <br />
          {data.variants.map((item) => {
            return (
              <span>
                <b>{item.variant_group}</b> : {item.variant_name}
              </span>
            );
          })}
        </p>
      </>
    );
  };
  const moveToCart = (id) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Moving Please Wait....");
    let url = CUSTOMER_WISHLIST_URLS.UPDTAE_WISHLIST;
    let data_in = { id: id, type: "WISHLIST" };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        refreshUser();
        setLoading(false);
        loadWishListData();
        showAlertAutoClose("Offer Moved to Wishlist", "success");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["delete-color is-clickable is-size-5"],
      onClick: (data) => {
        deleteWhishList(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      // leftIcon: "fa fa-heart-o",
      leftIconFunction: () => {
        return (
          <>
            <div className="back-heart-icon-view">
              <i class="fa fa-heart" aria-hidden="true"></i>
              <span>
                <i class="fa fa-reply" aria-hidden="true"></i>
              </span>
            </div>
          </>
        );
      },
      classList: [" delete-color is-clickable is-size-5 mt-2 ml-2"],
      onClick: (data) => {
        moveToCart(data["ID"]);
      },
    },
  ];

  const updateItemQuantity = (itemId, amount) => {
    const itemcheck = data.find((item) => item.ID === itemId);
    const updated_quant = Math.max(1, parseInt(itemcheck.quantity) + amount);
    updateSingleQuant(itemId, updated_quant);
    setData((prevItems) =>
      prevItems.map((item) =>
        item.ID === itemId
          ? { ...item, quantity: Math.max(1, parseInt(item.quantity) + amount) } // Ensure quantity is not less than 1
          : item
      )
    );
  };

  const updateSingleQuant = (id, quant) => {
    const handleError = (errorMessage) => {
      setLoading(false);
    };
    //  setLoading(true, "Please Wait....");
    let url = CUSTOMER_WISHLIST_URLS.UPDATE_SINGLE_QUANTITY;
    let data_in = { id: id, quantity: quant };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const updatePickUpDelivery = (itemId, flag) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.ID === itemId
          ? { ...item, pickupDelivery: flag } // Ensure quantity is not less than 1
          : item
      )
    );
  };

  const get_total_order_cost = () => {
    const totalPrice = data
      ? data.reduce((acc, item) => acc + item.quantity * item.total_price, 0)
      : 0;
    // let total = Number(totalPrice).toLocaleString(undefined, {
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2,
    // });
    return totalPrice;
  };

  const get_total_crops = () => {
    const totalPrice = data
      ? data.reduce((acc, item) => acc + item.quantity * item.crop_points, 0)
      : 0;
    return totalPrice;
  };

  const get_del_aud = (item) => {
    if (item.pickupDelivery !== 2) {
      return 0;
    }
    if (state === "REDEEM") {
      return item?.del_crop && item?.del_crop === 1 ? 0 : item?.del_aud;
    } else {
      return item.pickupDelivery === 2 ? item?.del_aud : 0;
    }
  };

  const get_del_crops = (item) => {
    if (item.pickupDelivery !== 2) {
      return 0;
    }
    if (state === "REDEEM") {
      return item?.del_crop && item?.del_crop === 1 ? item?.del_crop_value : 0;
    } else {
      return 0;
    }
  };

  const get_total_delivery_cost = () => {
    const totalPrice = data
      ? data.reduce((acc, item) => acc + get_del_aud(item), 0)
      : 0;
    // const delivery_gst = totalPrice > 0 ? totalPrice * 0.1 : 0;
    return totalPrice;
    //return 0;
  };

  const getDeliveryGst = () => {
    let totalPrice = get_total_delivery_cost();
    const delivery_gst = totalPrice > 0 ? totalPrice * 0.1 : 0;
    return delivery_gst;
  };

  const get_total_gst_cost = () => {
    const totalPrice = data
      ? data.reduce(
          (acc, item) =>
            acc + (item.quantity * item.total_price * item.gst_sector) / 100,
          0
        )
      : 0;
    const gst_delivery = getDeliveryGst();
    return parseFloat(totalPrice) + parseFloat(gst_delivery);
  };

  const get_total_delivery_crops = () => {
    const totalPrice = data
      ? data.reduce((acc, item) => acc + get_del_crops(item), 0)
      : 0;
    return totalPrice;
    // return 0;
  };

  const get_total_payable_cost = () => {
    let order_price = get_total_order_cost();
    let delivery_price = get_total_delivery_cost();
    let gst_amount = get_total_gst_cost();
    // console.log()
    let final_price =
      parseFloat(order_price) +
      parseFloat(delivery_price) +
      parseFloat(gst_amount);
    // console.log(" final price " , final_price);
    return final_price;
  };

  const get_total_reddemable_crops = () => {
    let order_price = get_total_crops();
    let delivery_price = get_total_delivery_crops();
    let final_price = parseFloat(order_price) + parseFloat(delivery_price);
    return parseInt(final_price);
  };

  const AddFunction = (data) => {
    return (
      <>
        <div className="is-flex is-justify-content-center">
          <button
            className="button is-size-7 mr-3 customer-increment-decrement"
            onClick={() => updateItemQuantity(data.ID, -1)}
          >
            -
          </button>
          <p>{data?.quantity}</p>
          <button
            className="button is-size-7 ml-3 customer-increment-decrement"
            onClick={() => updateItemQuantity(data.ID, 1)}
          >
            +
          </button>
        </div>
      </>
    );
  };

  const CropTableShowFunction = (data) => {
    return (
      <>
        <div className="is-flex is-justify-content-center">
          {/* <img
            className="image is-16x16 mt-1 mr-2"
            src={CROP_CUSTOMER_CROP_IMAGE}
            alt=""
          /> */}
          <p>{parseInt(data.crop_points)} </p>
        </div>
      </>
    );
  };

  const rounded_value = (input) => {
    let roundedTotal = Math.round(input * 100) / 100;
    return roundedTotal;
  };

  const PriceFunction = (row) => {
    let tot_price = row["quantity"] * row["total_price"];
    // Round the number to two decimal places

    return (
      <>
        <div className="has-text-success has-text-weight-bold">
          {parseInt(displayAmount(tot_price)) || ""}
        </div>
        <div className="offer-status">
          {row.offer_status == 1 ? "offer not available" : ""}
        </div>
      </>
    );
  };

  const noItemsMessage = () => {
    // const navigate = useNavigate();
    // const navigateLink = (index) => {};

    const baseNav = "";
    const navigateLink = (index) => {
      navigate(baseNav + "/" + index);
    };
    // const navigateLink = (index) => {};
    return (
      <div className=" has-text-centered customer-primary-font">
        <div className="my-2 has-text-weight-bold">
          No Offer Available
          <span
            className="login-forgot-corser pl-1 pr-1"
            onClick={() => navigateLink("")}
          >
            Click here
          </span>
          to Add Offer <i className="fa fa-gift" aria-hidden="true"></i>
        </div>

        {/* <SmartSoftButton
              label="Click here to Add items"
              classList={[" smart-customer-primary-button"]}
              onClick={() => navigateLink("")}
            /> */}
      </div>
    );
  };

  const columns = [
    {
      title: "Offer Image",
      index: "offerimage",
      width: "10",
      valueFunction: offer_image,
    },
    {
      title: "Offer Title",
      index: "shipping_type",
      width: "25",
      valueFunction: ShippingType,
    },
    // { title: "Offer Title", index: "offer_title", width: "20" },
    {
      title: "CROPs",
      index: "crop_points",
      width: "10",
      valueFunction: CropTableShowFunction,
    },
    {
      title: (
        <>
          Price <label className="customer-secondary-font ">(A$) </label>{" "}
        </>
      ),
      index: "total_price",
      width: "10",
    },
    {
      title: "Quantity",
      index: "qty",
      width: "10",
      valueFunction: AddFunction,
    },
    {
      title: "Total Price",
      index: "totalprice",
      width: "10",
      valueFunction: PriceFunction,
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];

  const cropsFunction = (row) => {
    let tot_price = row["quantity"] * row["crop_points"];
    return (
      <>
        <div className="has-text-success has-text-weight-bold">
          {tot_price || ""}
        </div>
        <div className="offer-status">
          {row.offer_status == 1 ? "offer not available" : ""}
        </div>
      </>
    );
  };

  const redeem_columns = [
    {
      title: "Offer Image",
      index: "offer_image",
      width: "10",
      valueFunction: offer_image,
    },
    {
      title: "Offer Title",
      index: "shipping_type",
      width: "25",
      valueFunction: ShippingType,
    },
    // { title: "Offer Title", index: "offer_title", width: "20" },
    {
      title: "CROPs",
      index: "crop_points",
      width: "10",
      valueFunction: CropTableShowFunction,
    },
    // { title: "Price(AUD)", index: "total_price", width: "10" },
    {
      title: "Quantity",
      index: "qty",
      width: "10",
      valueFunction: AddFunction,
    },
    {
      title: "Total CROPs",
      index: "totalprice",
      width: "10",
      valueFunction: cropsFunction,
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "5",
    },
  ];

  const TotalTable = () => {
    const { openModal } = useSiteContext();

    const openNewForm = () => {
      let modelObject = {
        body: (
          <Address closeModal={closeModal} loadAddressData={loadAddressData} />
        ),
        modelClass: "customer-model-layout smart-modal-90",
        bodyClose: false,
      };
      openModal(modelObject);
    };

    const fotter_cost_display = () => {
      return (
        <table className="table is-fullwidth">
          <tbody>
            <tr>
              <td>
                Sub Total{" "}
                <span className="customer-secondary-font ">(A$) </span>
              </td>
              <td>{displayAmount(get_total_order_cost())}</td>
            </tr>
            <tr>
              <td>
                Delivery Charges
                {/* (Inclusive of GST) */}
                <span className="customer-secondary-font "> (A$) </span>
              </td>
              <td>{displayAmount(get_total_delivery_cost())}</td>
            </tr>
            <tr>
              <td>
                {" "}
                GST <span className="customer-secondary-font ">(A$) </span>
              </td>
              <td>{displayAmount(get_total_gst_cost())}</td>
            </tr>
            <tr>
              <td>
                Total Payable{" "}
                <span className="customer-secondary-font ">(A$) </span>
              </td>
              <td>{displayAmount(get_total_payable_cost())}</td>
            </tr>
            <tr>
              <td>Earn CROPs</td>
              <td>{parseInt(get_total_crops())}</td>
            </tr>
          </tbody>
        </table>
      );
    };

    const fotter_crops_display = () => {
      return (
        <table className="table is-fullwidth">
          <thead></thead>
          <tbody>
            <tr>
              <td>Total CROPs</td>
              <td>{get_total_crops()}</td>
            </tr>
            <tr>
              <td>
                Delivery Charges{" "}
                <span className="customer-secondary-font ">(A$) </span>
              </td>
              <td>{displayAmount(get_total_delivery_cost())}</td>
            </tr>
            <tr>
              <td>Delivery CROPs</td>
              <td>{get_total_delivery_crops()}</td>
            </tr>
            <tr>
              <td>Total Redeemable CROPs</td>
              <td>{get_total_reddemable_crops()}</td>
            </tr>
            <tr>
              <td>Available CROPs</td>
              <td>{user?.crop_balance}</td>
            </tr>
          </tbody>
        </table>
      );
    };

    const paymentButton = () => {
      return (
        <SmartButton
          label="Proceed to Pay"
          classList={["smart-customer-primary-button"]}
          onClick={submitOrderAndPayment}
        />
      );
    };

    const crop_check_balance = () => {
      let crops_required = get_total_reddemable_crops();
      let user_crops = user?.crop_balance;
      let balance = parseFloat(user_crops) - parseFloat(crops_required);
      return balance;
    };

    const payOrderButton = () => {
      let balance = crop_check_balance();
      let delivery_cost = get_total_delivery_cost();
      // console.log("balance asdfasdf " , balance);
      // if (balance > 0) {
      return (
        <div className="has-text-centered">
          <SmartButton
            label={
              delivery_cost > 0 ? "Pay A$ " + delivery_cost : "Place Order"
            }
            classList={["smart-customer-primary-button "]}
            onClick={submitOrderAndPayment}
            disabled={balance > 0 ? false : true}
          />
          {balance <= 0 && (
            <p className="has-text-danger">
              You Don't Have Sufficient CROP's For this Order
            </p>
          )}
        </div>
      );
      // } else {
      //   return (
      //     <SmartButton
      //       label="Place Order"
      //       classList={["smart-customer-primary-button "]}
      //       onClick={submitOrderAndPayment}
      //       disabled={true}
      //     />
      //   );
      // }
    };

    const deliveryButton = () => {
      return (
        <>
          {" "}
          {/* <div className="is-flex "></div> */}
          <div className="columns is-flex">
            <div className="column is-8">
              <div className="is-flex">
                <div
                  className="mt-2 has-text-weight-bold is-size-5"
                  htmlFor="deliveryaddress mr-2 mt-5"
                >
                  Delivery Address:
                </div>
                <div
                  className="smart-customer-back ml-6 mt-3 is-size-5 px-5"
                  onClick={openNewForm}
                >
                  <u>
                    <i
                      className="fa fa-pencil-square-o mr-2"
                      aria-hidden="true"
                    ></i>
                    Change
                  </u>
                </div>
              </div>
              <div className="">
                {address?.addr_one && address?.addr_one.length > 0 && (
                  <p className="mt-2 has-text-weight-medium">
                    {address?.addr_one},
                  </p>
                )}
                {address?.addr_two && address?.addr_two.length > 0 && (
                  <p className="mt-2 has-text-weight-medium">
                    {address?.addr_two},
                  </p>
                )}
                {address?.city && address?.city.length > 0 && (
                  <p className="mt-2 has-text-weight-medium">
                    {address?.city},
                  </p>
                )}
                <div className="is-flex">
                  <p className="mt-2 has-text-weight-medium">
                    {address?.state}{" "}
                  </p>
                  {address?.pincode && (
                    <p className="mt-2 has-text-weight-medium ml-2">
                      - {address.pincode}.
                    </p>
                  )}
                </div>
                {/* <div className="is-flex">
                  <div className="column is-6 smart-state-address">
                    <div className="has-text-weight-bold mt-4">State</div>
                    <p className="mt-2 has-text-weight-medium">
                      {address?.state}
                    </p>
                  </div>
                  <div className="column is-6">
                    <div className="has-text-weight-bold">Zip Code</div>
                    <p className="mt-2 has-text-weight-medium">
                      {address?.pincode}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="">
            <div className="is-2 ml-1 mt-0">
              <SmartButton
                label="Change"
                classList={["smart-customer-primary-button "]}
                onClick={openNewForm}
              />
            </div>
            </div> */}
          </div>
          {/* <div className="column is-4 has-text-weight-medium">
            <SmartSoftSelect
              options={addressOpt}
              placeHolder="Select an Address"
              classList={["smart-customer-input-two "]}
              onChange={(value) => setAddress(value)}
              value={address || null}
            />
          </div> */}
        </>
      );
    };

    return (
      <>
        <div className="columns">
          <div className="column is-8">
            {checkDelivery() ? deliveryButton() : ""}
          </div>
          <div className=" column is-4 ">
            <div className="customer-checkout-Total-Table">
              {state === "EARN"
                ? fotter_cost_display()
                : fotter_crops_display()}
            </div>
            <div className=""></div>
          </div>
        </div>

        <div className="columns my-4">
          <div className="column  is-flex is-justify-content-end">
            {state === "REDEEM" ? payOrderButton() : paymentButton()}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <div className="px-5 ml-5 mt-0">
          <SmartHeader title={"Confirm Order"} />
        </div>
      </div>
      <div className="container">
        <div className=" is-flex is-justify-content-center  my-4 customer-header">
          Confirm Order
        </div>
        {data && data.length > 0 && (
          <>
            <div>
              <SmartSoftTable
                key={"checkout table"}
                columns={state === "REDEEM" ? redeem_columns : columns}
                data={data}
                tableProps={{ className: "crop-customer-table-layout" }}
                footerConfig={[]}
                paginationProps={{ pageSize: 2000 }}
              />
            </div>
            <div>{TotalTable()}</div>
          </>
        )}
        {data && data.length < 1 && noItemsMessage()}
      </div>
    </>
  );
};

export default CheckOut;
