import { useEffect } from "react";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
const OrderUpload = ({ data, setLoading, closeModal, loadOrderData }) => {
  useEffect(() => {
    // console.log(data);
  }, []);
 
  return (
    <div className="card-image" key="one-6">
      <div className="columns">
        <div className="column is-size-4 has-text-centered has-text-weight-bold">
          Order return
        </div>
        <div className="mb-3" onClick={closeModal}>
          <div className="customer-modal-close mb-3 ">
            <i class="fa fa-times has-text-white" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <hr />
      <div className="columns">
        <div className="column Order-upload-images">
          {/* {data.cancel_remarks} */}
          <p>
            <span className="has-text-weight-bold">Return Reason: </span>
            {data.cancel_reason}
          </p>
          <p>
            <span className="has-text-weight-bold">Return Remarks: </span>
            {data.cancel_remarks}
          </p>
          
          <hr />
        </div>
      </div>
      <div className="columns is-multiline">
        {data?.return_image.map((item, index) => (
          <div className="column card is-flex p-5 m-3 has-text-centered is-align-content-center">
            <SmartImageDisplay
              srcType="DATA"
              url={""}
              id={0}
              data={item.image_content}
              imageClass="is-128x128"
            />
          </div>
        ))}
      </div>

      {/* <SmartImageDisplay
              srcType="DATA"
              url={""}
              id={0}
              data={data?.return_image?.[0]?.image_content }
              imageClass="is-128x128"
            /> */}
      {/* <div className="columns is-multiline">
        <div className="column  Order-upload-images ">
          <div className="columns is-multiline">
            {object.map((item, index) => (
              <div className="column is-multiline is-flex">
                <div className="card Order-upload-images-images ">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OrderUpload;
