import { useEffect, useState } from "react";
import { SmartSoftInput } from "soft_digi";
// import { CROP_CUSTOMER_PROMOS_VIEW_IMAGES } from "../../../../services/ImageService";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN_OPERATIONS_SECTOR,
  CUSTOMER_OFFERS,
} from "../../../../api/OfferUrls";
import ProductDisplay from "../../../../components/site/ProductDisplay";
import SmartHeader from "../../../../components/site/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  showAlertAutoClose,
} from "../../../../services/notifyService";
import {
  post,
} from "../../../../services/smartApiService";

const EarnCropsDetails = () => {
  const { type, id ,} = useParams();
  const [data, setData] = useState(null);
  const [sectorData, setSectorData] = useState(null);
  const { setLoading, closeModal, user, handleUserChange,refreshUser } = useSiteContext();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const baseNav = "";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const loginCheck = () => {
    return user ? true : false;
  };

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
    search_data(value);
  };

  const loadTopRanking = () => {
    setLoading(true, "Loading....Please Wait ");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage,"error");
    //  setData([]);
      setLoading(false);
    };
    const subscription = post(CUSTOMER_OFFERS.GET_PROMOS,{type:"TOP",sector:id}, handleError).subscribe(
      (response) => {
        loadTableData(response.data)
       // setData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

 

  // this is api to the table data
  const loadTableData = (data_in) => {
    setLoading(true, "Loading.....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    let post_data = {
      sd_admin_sector_id: id,
      start: 1,
      limit: 20,
      earn_available: type === "redeem" ? "No" : "Yes",
      redeem_available: type === "redeem" ? "Yes" : "No",
    };
    const subscription = post(
      CUSTOMER_OFFERS.GET_ALL,
      post_data,
      handleError
    ).subscribe((response) => {
      let total_data = [...data_in,...response.data];
      const uniqueData = total_data.filter((item, index, self) =>
        index === self.findIndex((t) => t.ID === item.ID)
      );
      setData(uniqueData);
      //  console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadSectorData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setSectorData([]);
      setLoading(false);
    };
    let post_data = {
      id: id,
    };
    const subscription = post(
      ADMIN_OPERATIONS_SECTOR.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      setSectorData(response.data);
      // console.log("Success",response.data)
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTopRanking();
    loadSectorData();
  }, []);

  // const label_display = () => {
  //   switch (id) {
  //     case "15":
  //       return "Dine";
  //     case "16":
  //       return "Service";
  //     case "17":
  //       return "Hotel";
  //     case "18":
  //       return "Shop";
  //   }
  // };

  useEffect(() => {
    // Filter data based on the search term and set it to 'filteredData' state
    if (data) {
      if (formData.search_value && formData.search_value.length > 1) {
        // console.log("formdata search value " , formData);
        const filteredArray = data.filter((item) => {
          const result = item.offer_title
            .toLowerCase()
            .includes(formData.search_value.toLowerCase());
          // console.log("offer title " , item , " result " , result)
          return result;
        });
        setFilteredData(filteredArray);
      } else {
        setFilteredData(data);
        // console.log("data Details",data)
      }
    }
  }, [data, formData]);

  const search_data = (search_value) => {
    // let search_value = formData.search_value;
    if (!search_value || search_value.length < 3) {
      return data;
    }
    const filteredArray = data.filter((item) =>
      item.offer_title.toLowerCase().includes(search_value.toLowerCase())
    );
    return filteredArray;
    //setData(filteredArray);
  };

  const product_display = () => {
    return (
      <div className="columns is-multiline">
        {filteredData.map((item, index) => {
          return (
            <div className="column is-3 pl-5 pr-5 pb-3" key={index}>
              <ProductDisplay
                item={item}
                setLoading={setLoading}
                setData={setData}
                offer_type={type === "redeem" ? "REDEEM" : "EARN"}
                closeModal={closeModal}
                loginCheck={loginCheck}
                navigate={navigate}
                handleUserChange={handleUserChange}
                refreshUser={refreshUser}
              />
            </div>
          );
        })}
      </div>
    );
  };



  return (
    <>
      <div className="earn-crops-details-container container mb-3">
       
        <div className=" mt-5 mb-3 is-flex is-justify-content-space-between">
    <SmartHeader title={type === 'redeem' ? 'Redeem CROPs >' : 'Earn CROPs >'} sectorName= {sectorData?.sector_name} />
    <div className="smart-customer-earn-crop-input ">
                <SmartSoftInput
                  key="text-4"
                  placeHolder= "Search by Offer "
                  rightIcon= "fa-search"
                  value={formData?.search_value || ""}
                  onChange={(value) => handleInputChange("search_value", value)}
                />
                
              </div>
    </div>

        <div className="earn-crop-sub-container ">
          <div className="columns is-flex is-justify-content-end mr-1">
            <div className="column is-3 ">
             
            </div>
          </div>
          {filteredData && filteredData.length < 1 && (
            <div className="no-record-found-text">No Record Found</div>
          )}
          {filteredData && filteredData.length > 0 && product_display()}
        </div>
      </div>
    </>
  );
};

export default EarnCropsDetails;
