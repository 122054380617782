import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import {
  // SmartSOftTextArea,
  SmartSoftForm,
  SmartValid,
} from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { CUSTOMER_HELP_URLS } from "../../../../api/OfferUrls";
import SmartFileDisplay from "../../../../components/site/FormElements/SmartFileDisplay";
import SmartHeader from "../../../../components/site/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import { changeDateTimeZoneFormat } from "../../../../services/core/CommonService";
import { CROP_CUSTOMER_HELP_CLAIM_MISSING_CROPS } from "../../../../services/ImageService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { get, post } from "../../../../services/smartApiService";
import "./claim-missing-crops.css";

const ClaimMissingCrops = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const { setLoading, refreshUser } = useSiteContext();
  const [options, setOptions] = useState({});
  const [formErrors, setFormErrors] = useState(false);


  const handleInputChange = (name, value) => {
    console.log("selected value " , value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  // const options = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' }
  // ]
  const resetData = () => {
    handleInputChange("reason_missing", "");
    handleInputChange("invoice_number", "");
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, invoiceElements)) {
      return false;
    }
    // console.log("formdata", formData);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    formData.business_id = formData.business_id.value;
    let data_in = {...formData};
    data_in["invoice_date"] = changeDateTimeZoneFormat( ""+data_in["invoice_date"],"YYYY-MM-DD");
    //console.log("data in to be posted ", data_in);
    const subscription = post(
      CUSTOMER_HELP_URLS.INSERT_MISSING_CROPS,
      data_in,
      handleError
    ).subscribe((response) => {
      setFormSubmit(false);
      // console.log(response.data);
      showAlertAutoClose(response.data.msg, "success");
      setFormData({});
      setLoading(false);
      refreshUser();
      window.scrollTo(0, 0);
      handleInputChange("reason_missing", "");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadBusinessName = () => {
    setLoading(true, "Loading.... Please Wait");
    const handleError = (errorMessage) => {
      // showAlertAutoClose( errorMessage,"error");
      setLoading(false);
    };

    const subscription = post(
      CUSTOMER_HELP_URLS.GET_BUSINESS_NAME,
      handleError
    ).subscribe((response) => {
      // console.log(response.data);
      setOptions(response.data);
      setLoading(false);
      resetData();
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    loadBusinessName();
  }, []);

  const FormValidations = {
    invoice_number: [SmartValid.required("Invoice Number is Required")],
    business_id: [SmartValid.required("Business Name is Required")],
    invoice_date: [SmartValid.required("Date is Required")],
    reason_missing: [SmartValid.required("Reason is Required")],
    invoice_image: [SmartValid.required("Please  Upload Invoice")],
  };

  const filePreviewFunctionDisplay = (inputValue) => {
    // console.log("teting " , inputValue);
    return (
      <div className="ml-3">
        <SmartFileDisplay
          files={formData?.invoice_image}
          updateImages={(images) => handleInputChange("invoice_image", images)}
        />
      </div>
    );
  };
  // const textareaElements = [
  //   {
  //     type: "TEXTAREA",
  //     width: "6",
  //     name: "reason_missing",
  //     element: {
  //       label: "Reasons for Missing claim",
  //       placeHolder: "Reason",
  //       // pattern:  ALLOW_ALPHABET_SPACE ,
  //       isRequired: true,
  //       validations: FormValidations.reason_missing,
  //     },
  //   },
  // ];

  const ReasonOptions = [
    { value: "Forgot", label: "Forgot" },
    { value: "Left in a hurry", label: "Left in a hurry" },
    {
      value: "Not aware of Business' affiliation to CROP",
      label: "Not aware of Business' affiliation to CROP",
    },
    { value: "Technical", label: "Technical" },
    { value: "Others", label: "Others" },
  ];

  const invoiceElements = [
    {
      type: "TEXT_BOX",
      width: "6",
      name: "invoice_number",
      element: {
        label: "Invoice Number",
        placeHolder: "Enter Invoice Number",
        pattern: "[A-Za-z0-9 ]*",
        isRequired: true,
        inputProps: { isFocussed: true },
        validations: FormValidations.invoice_number,
        max: 15,
      },
    },
    {
      type: "SELECT_BOX",
      width: "6",
      name: "business_id",
      element: {
        label: "Business Name",
        options: options,
        placeHolder: "Select...",
        isRequired: true,
        inputProps: { isFocussed: true },
        validations: FormValidations.business_id,
      },
    },
    {
      type: "DATE",
      width: "6",
      name: "invoice_date",
      element: {
        label: "Date of Invoice",
        placeHolder: "DD-MM-YYYY",
        isRequired: true,
        inputProps: { isFocussed: true },
        validations: FormValidations.invoice_date,
        maxDate: new Date(),
        minDate: addDays(new Date(), -90),
        //  minDate:new Date()
      },
    },
    {
      type: "SELECT_BOX",
      width: "6",
      name: "reason_missing",
      element: {
        label: "Reason for Missing claim",
        placeHolder: "Reason",
        // pattern:  ALLOW_ALPHABET_SPACE ,
        isRequired: true,
        options: ReasonOptions,
        max: "255",
        validations: FormValidations.reason_missing,
      },
      // hideFunction:(data)=>{
      //   // console.log("formdata " , formData)
      //   return formData.reason_missing && formData.reason_missing.value && formData.reason_missing.value==="Others" ?  true:false;
      // }
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "reason_missing_others",
      element: {
        label: "Enter The Reason",
        placeHolder: "Reason",
        // pattern:  ALLOW_ALPHABET_SPACE ,
        isRequired: true,
        max: "255",
        validations: FormValidations.reason_missing,
      },
      hideFunction: (data) => {
        // console.log("formdata " , formData)
        return formData.reason_missing &&
          formData.reason_missing.value &&
          formData.reason_missing.value === "Others"
          ? false
          : true;
      },
    },
    {
      type: "FILE",
      width: "6",
      name: "invoice_image",
      element: {
        placeHolder: (
          <p>
            Upload Invoice <span className="smart-error">*</span>
          </p>
        ),
        fileNameEnable: false,
        leftIcon: "fa fa-cloud-upload",
        accept: "application/pdf",
        //  isMulti: true,
        isRequired: true,
        filePreview: true,
        validations: FormValidations.invoice_image,
        filePreviewFunction: filePreviewFunctionDisplay,
        classList: [""],
      },
    },
    {
      type: "BUTTON",
      width: "6",
      element: {
        label: "Submit",
        classList: [
          "smart-customer-primary-button",
          "px-6 has-text-centered ",
          "sreen-claimmissingcropss",
        ],
        onClick: () => handleFormSubmit(),
      },
    },
  ];
  const formDisplay = () => {
    return (
      <div className="columns ">
        <div className="column is-12 mx-6 ">
          {/* <SmartSoftInput
            key="text-4"
            label="Invoice Number"
            value={formData?.invoice_number || ""}
            onChange={(value) => handleInputChange("invoice_number", value)}
            placeHolder="Enter Invoice Number"
            isRequired={true}
            pattern={ALLOW_NUMERIC}
            inputProps={{ isFocussed: true }}
            validations={FormValidations.invoice_number}
            errorEnable={formSubmit}
            handleErrorChange={(value) =>
              handleErrorChange("invoice_number", value)
            }
            errorUpdate={(value) => handleErrorChange("invoice_number", value)}
          /> */}
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={invoiceElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          //  onChange={(value) => handleInputChange("invoice_image", value)}
          />

          {/* <div className="is-128x128">
            <SmartImageDisplay
              srcType="DATA"
              data={
                formData.invoice_image && formData.invoice_image.content
                  ? formData.invoice_image.content
                  : ""
              }
              // isMulti={true}

              imageClass="is-128x128"
              updateImages={(images) =>
                handleInputChange("invoice_image", images)
              }
            />
          </div> */}
          {/* <SmartSoftSelect
            key="text-5"
            label="Business Name"
            options={options}
            value={formData?.business_id || ""}
            onChange={(value) => handleInputChange("business_id", value)}
            validations={FormValidations.business_id}
            errorEnable={formSubmit}
            isRequired={true}
            handleErrorChange={(value) =>
              handleErrorChange("business_id", value)
            }
            errorUpdate={(value) => handleErrorChange("business_id", value)}
            // leftIcon="fa-user"
          /> */}
          {/* <SmartSoftDate
            key="text-6"
            label="Date of Invoice"
            value={formData?.invoice_date || ""}
            onChange={(value) => handleInputChange("invoice_date", value)}
            //  inputType="BORDER_LABEL_FOCUS"
            //  leftIcon="fa-user"
            inputProps={{ isFocussed: true }}
            isRequired={true}
            placeHolder="DD-MM-YYYY"
            errorEnable={formSubmit}
            validations={FormValidations.invoice_date}
            errorUpdate={(value) => handleErrorChange("invoice_date", value)}
            maxDate={new Date()}
          /> */}
          {/* <SmartSOftTextArea
            key="text-7"
            label="Reasons for Missing claim"
            value={formData?.reason_missing || ""}
            onChange={(value) => handleInputChange("reason_missing", value)}
            placeHolder="Reason"
            pattern={ALLOW_ALPHABET_SPACE}
            // inputProps={{ isFocussed: true }}
            isRequired={true}
            errorEnable={formSubmit}
            validations={FormValidations.reason_missing}
            errorUpdate={(value) => handleErrorChange("reason_missing", value)}
            
          /> */}
          {/* <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={textareaElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          /> */}
          {/* <SmartSoftButton
              label="Submit"
              classList={[
                "smart-customer-primary-button",
                "px-6 has-text-centered",
              ]}
              onClick={handleFormSubmit}
            /> */}
          {/* <figure className="image is-128x128  mb-4 ml-5 pl-5">
                <img
                  className="is-rounded "
                  src={getImageContent(formData?.invoice_image)}
                  alt=""
                />
              </figure> */}

          {/* <SmartSofFile
                placeHolder="Upload Invoice"
                value={formData?.invoice_image}
                onChange={(value) => handleInputChange("invoice_image", value)}
                errorEnable={formSubmit}
                fileNameEnable={false}
                validations={FormValidations.invoice_image}
              /> */}
          {/* <figure className="image is-128x128  mb-4 ml-5 pl-5">
                <img
                  className="is-rounded "
                  src={getImageContent(formData?.invoice_image)}
                  alt=""
                />
              </figure> */}
        </div>
        {/* <div className="columns">
          <div className="column  mt-6 has-text-centered ClaimMissingCrops-uplode-responsive">
             <div className="">
              <figure className="image is-128x128  mb-4 ml-5 pl-5">
                <img
                  className="is-rounded "
                  src={getImageContent(formData?.invoice_image)}
                  alt=""
                />
              </figure>

              <SmartSofFile
                placeHolder="Upload Invoice"
                value={formData?.invoice_image}
                onChange={(value) => handleInputChange("invoice_image", value)}
                errorEnable={formSubmit}
                fileNameEnable={false}
                validations={FormValidations.invoice_image}
              />
            </div> 
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <>
      <div className="px-5 ml-5 ">
        <SmartHeader title={"Claim Missing CROPs"} />
      </div>
      <div className="columns is-centered">
        <div className="column is-6 p-5 smart-customer-input">
          <h1 className="has-text-centered customer-header claim-missing-left-filds">
            Claim Missing CROPs
          </h1>
          {formDisplay()}
        </div>
        <div className="column  is-6">
          <div className="ClaimMissingCrops-card-image p-6 is-hidden-mobile">
            <img
              src={CROP_CUSTOMER_HELP_CLAIM_MISSING_CROPS}
              className="CropsMissing-image m-5"
              alt="Missing_crops_image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimMissingCrops;
