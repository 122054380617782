import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  //  SmartSoftSelect,
  SmartSoftTable,
} from "soft_digi";
// import { HEADSET_IMG } from "../../../services/ImageService";
// import ShoppingCart from "./ShoppingCart";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_OFFERS } from "../../../api/OfferUrls";
import { CUSTOMER_WISHLIST_URLS } from "../../../api/OrderUrls";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import SmartHeader from "../../../components/site/SmartHeader";
import { useSiteContext } from "../../../contexts/SiteProvider";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
// import { displayDots } from "../../services/core/CommonService";
import {displayFiftyDots, displayDots } from "../../../services/core/CommonService";
import { IoCartOutline, IoTrash } from "react-icons/io5";
import SmartImageDisplayNew from "../../../components/site/FormElements/SmartImageDisplayNew";

const SingleCart = ({ state }) => {
  const [data, setData] = useState([]);
  // const [state, setState] = useState( {value:"EARN",label:"Earn CROP's"});
  const { setLoading, refreshUser } = useSiteContext();
  const navigate = useNavigate();
  const baseNav = "";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  // const updateItemQuantity = (itemId, amount) => {
  //   setData((prevItems) =>
  //     prevItems.map((item) =>
  //       item.ID === itemId
  //         ? { ...item, quantity: Math.max(1, parseInt(item.quantity) + amount) } // Ensure quantity is not less than 1
  //         : item
  //     )
  //   );
  // };

  const loadWishListData = (e) => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Loading....Please Wait");
    let url = CUSTOMER_WISHLIST_URLS.GET_USER_WISHLIST;
    let offer_type = state ? state : "EARN";
    let data_in = { type: "WISHLIST", offer_type: offer_type };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        setData(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const moveToCart = (id) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Moving.... Please Wait");
    let url = CUSTOMER_WISHLIST_URLS.UPDTAE_WISHLIST;
    let data_in = { id: id, type: "CART" };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        loadWishListData();
        showAlertAutoClose("Offer Moved to Cart", "success");
        refreshUser();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const deleteWhishList = (id) => {
    showYesOrNoAlert(
      "Do you wish to remove this offer?",
      (selection) => deleteWhishlist(id, selection),
      "danger"
    );
  };

  const deleteWhishlist = (id, action) => {
    if (action === "yes") {
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
        setData([]);
      };
      setLoading(true, "Removing.... Please Wait");
      let url = CUSTOMER_WISHLIST_URLS.DELETE_WISHLIST;
      let data_in = { id: id };
      const subscription = post(url, data_in, handleError).subscribe(
        (response) => {
          setLoading(false);
          loadWishListData();
          showAlertAutoClose("Offer removed from Wishlist", "success");
          refreshUser();
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  };
  const offer_image = (data) => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data.sd_offers_id !== undefined ? data.sd_offers_id : 0;
    return (
      <>
        <div className="is-flex is-justify-content-center smart-table-order-image">
          <SmartImageDisplayNew
            srcType="URL"
            url={url}
            id={id}
            imageClass="is-64x64 is-centered"
          />
        </div>
      </>
    );
  };

  const offer_title_disp = (data) => {
    return (
      <>
        <p>
          {data.offer_title}
          <div>
            {data.variants.map((item) => {
              return (
                <span className="is-size-7 ml-2">
                  <b>{item.variant_group}</b> : {item.variant_name}
                </span>
              );
            })}
          </div>
          {data?.custom_note && data?.custom_note?.length >0 && (
   <div className="smart-lable-customer">Customer Notes: <span className="smart-font-style-customer ">  {displayDots(data.custom_note)}</span></div>
   
 
)}
        </p>
      </>
    );
  };

  useEffect(() => {
    loadWishListData();
  }, [state]);

  const buttons = [
    {
      label: "",
      type: "icon",
      leftIconFunction: () => {
        return (
          <>
            <IoCartOutline className="mt-1" />
          </>
        );
      },
      classList: ["cart-colour is-clickable is-size-5 "],
      onClick: (data) => {
        let id = data["ID"];
        moveToCart(id);
        //navigateLink("my-offer-view");
      },
    },
    {
      label: "",
      type: "icon",
      leftIconFunction: () => {
        return (
          <>
            <IoTrash className="mt-1" />
          </>
        );
      },
      //leftIcon: "fa fa-trash",
      classList: [" delete-color is-clickable is-size-5 ml-2"],
      onClick: (data) => {
        deleteWhishList(data["ID"]);
      },
    },
  ];
  const tableButton = () => {
    // const baseNav = "";
    // const navigate = useNavigate();
    // const navigateLink = (index) => {
    //   navigate(baseNav + "/" + index);
    // }
    return (
      <>
        <div className="mt-4">
          <SmartSoftTable
            columns={state === "EARN" ? columns : redeem_columns}
            data={data}
            tableProps={{ className: "crop-customer-table-layout" }}
            footerConfig={[]}
            paginationProps={{ pageSize: 2000 }}
          />
        </div>
        <div className="is-flex is-justify-content-center">
          <SmartSoftButton
            label="Go to Cart"
            onClick={() => navigateLink("whish-list/view-cart")}
            classList={[" smart-customer-primary-button"]}
          />
        </div>
      </>
    );
  };

  const card_title = () => {
    return state === "REDEEM" ? "Redeem CROPs" : "Earn CROPs";
  };
  const noItemsMessage = () => {
    // const baseNav = "";
    // const navigate = useNavigate();
    // const navigateLink = (index) => {
    //   navigate(baseNav + "/" + index);
    // }

    return (
      <div className=" has-text-centered customer-primary-font smart-crop-customer">
        <div className="my-2 has-text-weight-bold">
          No Offers Available
          <span
            className="login-forgot-corser pl-2 pr-2"
            onClick={() => navigateLink("")}
          >
            Click here
          </span>
          to Add Offers <i className="fa fa-gift" aria-hidden="true"></i>
        </div>

        {/* <SmartSoftButton
              label="Click here to Add items"
              classList={[" smart-customer-primary-button"]}
              onClick={() => navigateLink("")}
            /> */}
      </div>
    );
  };

  // const { openModal } = useSiteContext();

  // const openNewForm = () => {
  //   let modelObject = {
  //     body: <ShoppingCart />,
  //     modelClass: "crop-customer-modal smart-modal-80",
  //     bodyClose: false,
  //   };
  //   openModal(modelObject);
  // };

  const columns = [
    {
      title: "Offer Image",
      index: "offerimage",
      width: "20",
      valueFunction: offer_image,
    },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "30",
      valueFunction: offer_title_disp,
    },
    { title: "CROPs", index: "crop_points", width: "20" },
    {
      title: (
        <>
          Price<label className="customer-secondary-font ml-1">(A$) </label>{" "}
        </>
      ),
      index: "total_price",
      width: "10",
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "20",
    },
  ];

  const redeem_columns = [
    {
      title: "Offer Image",
      index: "offerimage",
      width: "20",
      valueFunction: offer_image,
    },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "30",
      valueFunction: offer_title_disp,
    },
    { title: "CROPs", index: "crop_points", width: "20" },
    // { title: "Price (A$)", index: "total_price", width: "20" },
    { title: "", index: "", width: "10" },

    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "20",
    },
  ];

  return (
    <>
      <div className=" ">
        <header className="">
          <p className="primary-color ">{card_title()}</p>
        </header>
        <div className="">
          {data && data.length > 0 && tableButton()}
          {data && data.length < 1 && noItemsMessage()}
        </div>
      </div>
    </>
  );
};

const WhishListTable = () => {
  const navigate = useNavigate();
  const baseNav = "";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <div className="px-5 ml-5 mt-0">
          <SmartHeader title={"My Wishlist"} />
        </div>
        {/* <div
          className="smart-customer-back mr-5 px-5 is-hidden-mobile"
          onClick={() => navigateLink("")}
        >
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          {""} Go Back
        </div> */}
      </div>

      <div className=" px-6 pb-6">
        {/* <SmartStripePayment /> */}
        <div className="is-flex is-justify-content-center customer-header mb-3">
          My Wishlist
        </div>
        <div className="is-flex is-justify-content-space-between">
          <div className="smart-customer-whish-list-input">
            {/* <SmartSoftSelect
              label="Select Type"
              isHorizontal
              value={state}
              options={selectOptions}
              onChange={(value) => setState(value)}
            /> */}
          </div>
        </div>
        <SingleCart state="EARN" key="earn" />
        <SingleCart state="REDEEM" key="redeem" />
      </div>
    </>
  );
};

export default WhishListTable;
