import { useState } from "react";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { CUSTOMER_MY_ACCOUNT } from "../../../api/OfferUrls";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
const MobileChange = ({data,setLoading,closeModal,loadProfileData,handleMobileClick,refreshUser}) => {
  
  // const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [verifyOtp, setverifyOtp] = useState(false)
  const [formData, setFormData] = useState({
    login_type: "1",
    country_code: "AU",
  });

  const handleInputChange = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  // const loadTableData = () => {
  //   const handleError = (errorMessage) => {
  //     showAlertAutoClose(errorMessage);
  //     setLoading(false);
  //   };
  //   const subscription = get(
  //     CUSTOMER_MY_ACCOUNT.SENT_OTP_EMAIL,
  //     handleError
  //   ).subscribe((response) => {
  //     console.log(response)
  //     setLoading(false);
  //   });
  //   return () => {
  //     subscription.unsubscribe();
  //   };
  // };
  // useEffect(() => {
  //   loadTableData()
  // }, []);

  
  const Validations = {
    otp: [SmartValid.required("OTP Required"),
    SmartValid.minLength("Minimun 6 Digit", 6),],
    email: [SmartValid.required("Email is Required")],
    mobile_number: [SmartValid.required("Mobile Number is Required")],
  };
  const formElementsOne = [
    {
      type: "OTP",
      width: "12",
      name: "otp_1",
      element: {
        digits:6,
        label: "Enter OTP",
        isRequired: true,
        pattern: ALLOW_NUMERIC,
        resendOtpFunction :(callback)=>{
          handleFormMobileOtpSend(null);
          callback() 
        },
        otpButtonText: (status, timer) => {
          return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
        },
      },
    },
  ];

  const formElements = [
    // {
    //   type: "OTP",
    //   width: "12",
    //   name: "otp",
    //   element: {
    //     digits: 6,
    //     label: "Enter OTP (OTP send in your Mail id)",
    //     validations: Validations.otp,
    //     isRequired: true,
    //     pattern: ALLOW_NUMERIC,
    //     max: 6,
    //     resendOtpFunction: (callback) => {
    //       handleMobileClick(null);
    //       callback();
    //     },
    //     otpButtonText: (status, timer) => {
    //       return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
    //     },
    //   },
    // },
    {
      type: "MOBILE",
      width: "12",
      name: "mobile_no",
      codeName: "country_code",
      element: {
        label: "Enter New Mobile Number",
        codeSelected: formData?.country_code,
        value: formData?.mobile_no || "",
        countries: ["AU", "IN"],
        // placeHolder: "Enter Mobile Number",
        inputType: "BORDER_LABEL_FOCUS",
        rightIcon: "fa-mobile",
        validations: Validations.mobile_number,
        isRequired: true,
        max: 10,
        // pattern: ALLOW_NUMERIC,
      },
    },
  ];
  const handleFormMobileOtpSend = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    if (!ValidateFormNew(formData,formElements)) {
      return false;
    }
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");
    let post_data={
      mobile_no:formData.mobile_no,
      // otp_value:formData.otp,
      // otp_id:data.otp_id,
      country_code:formData.country_code
    }
    let url = CUSTOMER_MY_ACCOUNT.SEND_OTP_MOBILE_NEW;
    
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("OTP sent successfully", "success");
        setLoading(false);
        setverifyOtp(response.data)
        setIsVerify(true)
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };
  const handleFormMobileOtpVerify = () => {
    // console.log("formdata", formData);
    setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading.... Please Wait");
    let post_data={
      mobile_no:formData.mobile_no,
      otp_value:formData.otp_1,
      otp_id:verifyOtp.otp_id,
      country_code:formData.country_code
    }
    let url = CUSTOMER_MY_ACCOUNT.UPDATE_MOBILE_NUMBER;
    
    const subscription = post(url, post_data, handleError).subscribe(
      (response) => {
        showAlertAutoClose("Mobile Number Changed successfully", "success");
        setLoading(false);
        loadProfileData()
        closeModal()
        refreshUser()
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };
  const smartForms = () => {
    return (
      <>
      
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
          onChange={(value) => handleInputChange("input_four", value)}
        />
        <div className="mt-6 is-flex is-justify-content-center">
        <SmartSoftButton
          label="Send OTP"
          classList={["smart-customer-primary-button"]}
          onClick={handleFormMobileOtpSend}
        />
        </div>
        
      </>
    );
  };

  const smartChangeForms = () => {
    return (
      <>
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElementsOne}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
          onChange={(value) => handleInputChange("input_four", value)}
        />
            <div className="mt-6 is-flex is-justify-content-center">
        <SmartSoftButton
          label="Update Number"
          classList={["smart-customer-primary-button"]}
          onClick={handleFormMobileOtpVerify}
        />
        </div>
       
      </>
    );
  };

  return (
    <>
       <div>
      <div className="smart-customer-input">
        <div className="is-flex is-justify-content-space-between mr-3">
          <div className="is-size-4 has-text-weight-bold mb-4">
           Update Mobile Number
          </div>
          <div className="mb-3" onClick={closeModal}>
            <div className="customer-modal-close mb-3 ">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        {isVerify!==true?smartForms():''}
        {isVerify===true?smartChangeForms():''}
      </div>
      </div>
      
    </>
  );
};

export default MobileChange;
