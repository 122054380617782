import { SmartSoftButton } from "soft_digi";
import ImageCarousel from "../../../components/site/ImageCarousel";
import {
  CROP_CUSTOMER_HOME_STAR,
  HOME_SLIDE_FOUR,
  HOME_SLIDE_ONE,
  HOME_SLIDE_TOW,
  SINUP_IMAGE,
} from "../../../services/ImageService";
import HomeSlider from "../../../components/site/HomeSlider";

const HomePageSlider = ({ isLoggedIn, navigateLink }) => {
  // const imageSlider = () => {
  //   return (
  <>
    {/* <div className="image is-24x24 is-hidden-mobile">
          <img
            className="home-stat-image"
            src={CROP_CUSTOMER_HOME_STAR}
            alt="Star"
          />
        </div> */}
    {/* <div className="columns p-0 m-0 is-flex-mobile"> */}
    {/* <div className="column is-6">
            <div className="smart-customer-home-page-details ml-0">
              <div>
                <div className="home-accumulation-text">
               
                  ACCUMULATION
                </div>
                <div className="smart-customer-home-text ">
                  <div>
                 
                    Every dollar counts,
                  </div>
                  <div>
                
                    earn CROPs for all
                  </div>
                  <div>
              
                    purchase.
                  </div>
                </div>

                {!isLoggedIn() && (
                  <>
                    <SmartSoftButton
                      label="Sign Up Now"
                      classList={["smart-customer-primary-button", "mt-3"]}
                      rightIcon="fa fa-arrow-circle-right"
                      onClick={() => navigateLink("register")}
                    />
                  </>
                )}
              </div>
            </div>
          </div> */}
    {/* <div className="column is-6 ">
            <div className="customer-home-side-images ">
              <div className=" customer-home-right-image">
                <img src={CUSTOMER_HOME_IMAGE} alt="" />
              </div>
            </div>
          </div> */}
    {/* </div> */}
  </>;
  //   );
  // };

  const imageSlider_two = () => {
    return (
      <div style={{ position: "relative" }}>
        <div className="image is-24x24 is-hidden-mobile">
          <img
            className="home-stat-image"
            src={CROP_CUSTOMER_HOME_STAR}
            alt="Star"
          />
        </div>
        <div className="columns  is-flex-mobile">
          <div className="column is-6 smart-customer-home-page-details-one ">
            {/* <div className=""> */}
            <div>
              <div className="home-accumulation-text">
                {/* Sign Up in 3 easy steps */}
                WELCOME TO CROP
              </div>
              <div className="smart-customer-home-text ">
                <div>
                  {/* Create your membership account  */}A loyalty program that
                  rewards
                </div>
                {/* <div>to avail exclusive</div> */}
                <div>
                  {/* loyalty benefits */}
                  you for your daily spends
                </div>
              </div>
              {!isLoggedIn() && (
                <>
                  <SmartSoftButton
                    label="Sign Up Now"
                    classList={["smart-customer-primary-button", "mt-3"]}
                    rightIcon="fa fa-arrow-circle-right"
                    onClick={() => navigateLink("register")}
                  />
                </>
              )}
              {/* </div> */}
            </div>
          </div>
          <div className="column is-6 is-flex is-justify-content-center">
            <div className="customer-home-side-images ">
              <div className=" customer-home-right-image">
                <img src={HOME_SLIDE_ONE} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const imageSlider = () => {
    return (
      <div style={{ position: "relative" }}>
        <div className="image is-24x24 is-hidden-mobile">
          <img
            className="home-stat-image"
            src={CROP_CUSTOMER_HOME_STAR}
            alt="Star"
          />
        </div>
        <div className="columns  is-flex-mobile">
          <div className="column is-6">
            <div className="smart-customer-home-page-details ">
              <div>
                <div className="home-accumulation-text">
                  SIGN UP IN 3 EASY STEPS
                </div>
                <div className="smart-customer-home-text ">
                  <div>
                    Create your membership account to avail exclusive loyalty
                    benefits
                  </div>
                </div>
                {!isLoggedIn() && (
                  <>
                    <SmartSoftButton
                      label="Sign Up Now"
                      classList={["smart-customer-primary-button", "mt-3"]}
                      rightIcon="fa fa-arrow-circle-right"
                      onClick={() => navigateLink("register")}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column is-6 is-flex is-justify-content-center">
            <div className="customer-home-side-images ">
              <div className=" customer-home-right-image">
                <img src={SINUP_IMAGE} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const imageSlider_three = () => {
    return (
      <div style={{ position: "relative" }}>
        <div className="image is-24x24 is-hidden-mobile">
          <img
            className="home-stat-image"
            src={CROP_CUSTOMER_HOME_STAR}
            alt="Star"
          />
        </div>
        <div className="columns  is-flex-mobile">
          <div className="column is-6">
            <div className="smart-customer-home-page-details-three ">
              <div>
                <div className="home-accumulation-text">
                  EARN CROPs FOR YOUR PURCHASE
                </div>
                <div className="smart-customer-home-text ">
                  <div> Get rewarded for your day to day spends,</div>
                  <div> make every dollar count</div>
                </div>
                {!isLoggedIn() && (
                  <>
                    <SmartSoftButton
                      label="Sign Up Now"
                      classList={["smart-customer-primary-button", "mt-3"]}
                      rightIcon="fa fa-arrow-circle-right"
                      onClick={() => navigateLink("register")}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column is-6 is-flex is-justify-content-center">
            <div className="customer-home-side-images ">
              <div className=" customer-home-right-image ">
                <img src={HOME_SLIDE_TOW} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const imageSlider_four = () => {
    return (
      <div style={{ position: "relative" }}>
        <div className="image is-24x24 is-hidden-mobile">
          <img
            className="home-stat-image"
            src={CROP_CUSTOMER_HOME_STAR}
            alt="Star"
          />
        </div>
        <div className="columns  is-flex-mobile">
          <div className="column is-6">
            <div className="smart-customer-home-page-details-three">
              <div className="customer-slider-text">
                <div className="home-accumulation-text ">
                  USE CROPs FOR UNIQUE REWARDS
                </div>
                <div className="smart-customer-home-text  ">
                  <div>Redeem CROPs across all our participating brands to avail free offers</div>
                  <div> </div>
                </div>
                {!isLoggedIn() && (
                  <>
                    <SmartSoftButton
                      label="Sign Up Now"
                      classList={[
                        "smart-customer-primary-button",
                        "mt-3",
                        "homppsage-imaageslider-register",
                      ]}
                      rightIcon="fa fa-arrow-circle-right"
                      onClick={() => navigateLink("register")}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column is-6 is-flex is-justify-content-center">
            <div className="customer-home-side-images ">
              <div className=" customer-home-right-image">
                <img src={HOME_SLIDE_FOUR} height="300" width="550" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const getImageData = () => {
    const output = [];
    // for(let i=0;i<data.length;i++){
    //let content = data[i].image_content;

    output.push({
      data: imageSlider_two(),
    });
    output.push({
      data: imageSlider(),
    });
    output.push({
      data: imageSlider_three(),
    });
    output.push({
      data: imageSlider_four(),
    });
    //}
    // console.log("output imaghes " , output,imageData);
    return output;
  };

  return (
    <div class="smart-image-home-slider">
      <HomeSlider
        data={getImageData()}
        key="singleImage"
        showButtons={false}
        buttonsClassName={"product-image-buttons"}
        containerClass="smart-home-slider"
      />
    </div>
  );
};

export default HomePageSlider;
