import { USER_URLS } from "../../api/Admin/AurhUrls";
import { ADMIN_OPERATIONS_INTERESTS, ADMIN_OPERATIONS_LOYALTY, ADMIN_OPERATIONS_SECTOR } from "../../api/Admin/OperationsUrl";
import { get } from "../smartApiService";

/**
 * 
 * @param url s
 * @param call_back 
 * @returns 
 */
const admin_generic_select=(url:string,call_back:any)=>{
  const handleError = (errorMessage:any) => {}; 
  const subscription =get(url, handleError).subscribe(
      (response) => {
          call_back(response.data);   
      }
    );
    return () => {
      subscription.unsubscribe();
    };
}
/**
 * 
 * @param call_back 
 * @returns 
 */
const admin_sector_select=(call_back:any)=>{
    const handleError = (errorMessage:any) => {};   
    let url = ADMIN_OPERATIONS_SECTOR.GET_ALL_SELECT;
    const subscription =get(url, handleError).subscribe(
        (response) => {
            call_back(response.data);   
        }
      );
      return () => {
        subscription.unsubscribe();
      };
}

const admin_loyalty_select=(call_back:any)=>{
  let url = ADMIN_OPERATIONS_LOYALTY.GET_ALL_SELECT;
  admin_generic_select(url,call_back);
}

const admin_interest_select=(call_back:any)=>{
  let url = ADMIN_OPERATIONS_INTERESTS.GET_ALL_SELECT
  admin_generic_select(url,call_back);
}

const admin_states_select=(call_back:any)=>{
  let url = USER_URLS.GET_ALL_SELECT_STATES;
  admin_generic_select(url,call_back);
}




export {
  admin_interest_select, admin_loyalty_select, admin_sector_select, admin_states_select
};
