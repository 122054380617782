import {  COLOR_BLUE,  COLOR_ORAGE_LIGHT,  COLOR_ORANGE} from "../../services/ImageService";
import croplogo from "../../assets/images/croplogo.png";
import "./CustomerLoginPage.css";
import CustomerFooter from "./CustomerFooter";
const CustomerLoginLayout = ({sideImage,tabImage,children}) => {


  const loginLogo = () => {
    return (
      <div>
        <figure className=" is-flex is-justify-content-center crop-image-logo">
          <img src={croplogo} alt="Crop Logo" />
        </figure>
      </div>
    );
  };

  // const checkbox = () => {
  //   return <div></div>;
  // };

  const colorshaderounded = () => {
    return (
      <>
        <div className="customer-login-colorshade is-hidden-mobile">
          <span className="customer-login-colororange">
            <img src={COLOR_ORANGE} alt=""></img>
          </span>
          <span className="customer-login-colorblue">
            <img src={COLOR_BLUE} alt=""></img>
          </span>
          <span className="customer-login-colororangelight">
            <img src={COLOR_ORAGE_LIGHT} alt=""></img>
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="Login">
      <div className="columns card Customer-Login-contentpage">
        <div className="column is-6  is-hidden-mobile">
          <div className="Customer-Login-said-image p-6 ">
            <img src={tabImage} alt=""></img>
          </div>
        </div>

        <div className="column customer-login-formmain card  is-6">
          <div className="customer-login-cardform ">
            <span>{loginLogo()}</span>
            <div className=" is-hidden-tablet has-text-centered smart-mobile-view-image  my-2 mt-3">
              <img src={tabImage} alt=""></img>
            </div>
            {children}
            <span>{colorshaderounded()}</span>
          </div>
        </div>
      </div>

      {/* <CustomerFooter /> */}
    </div>
  );
};

export default CustomerLoginLayout;
