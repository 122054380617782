// LoginLayout.js
import { useState, useEffect } from "react";
import { useSiteContext } from "../../contexts/SiteProvider";
import "./CustomerLayoutCss.css";
import CustomerHeader from "./CustomerHeader";
import CustomerFooter from "./CustomerFooter";
import { useNavigate } from "react-router-dom";

const CustomerLayout = ({ children }) => {
  const { user, logout, setLoading } = useSiteContext();
  const [setUser, setPage] = useState("home");
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
    logout();
  };
  const handleNavigation = (page) => {
    setPage(page);
  };

  useEffect(() => {
    // const handleBackButton = (event) => {
    //   // Handle the back button click event here
    //   console.log('Back button clicked');
    //   // Optionally, perform any action or navigate programmatically
    //   // For example: navigate('/some-route');
    // };
    // console.log("triggerd the navigate event");
    // window.addEventListener('popstate', handleBackButton);

    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div
      key="container_one"
      className="container is-fluid smart-crop-customer-container "
    >
      <div className="card smart-customer-header" key="header_one">
        <CustomerHeader
          user={user}
          setPage={handleNavigation}
          logout={handleLogout}
          setLoading={setLoading}
        />
      </div>
      <div key="header_two" className="smart-crop-customer-middle">
        {children}
      </div>
      <div key="header_three" className="smart-crop-customer-footer">
        <CustomerFooter />
      </div>
    </div>
  );
};

export default CustomerLayout;
