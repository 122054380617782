const ADMIN_LOGIN_URLS = {
  LOGIN: "auth/admin_login",
  LOGIN_BUSINESS: "auth/business_login",
  LOGIN_CUSTOMER: "auth/customer_login",
  REFRESH: "auth/refresh",
  HEALTH_CHECK: "auth/health_check",
  CHECK_USER: "auth/check_user",
};

const BUSINESS_LOGIN_URLS = {
  SEND_OTP: "auth/send_otp",
  SEND_OTP_FORGOT: "auth/send_otp_forgot",
  VERIFY_BUSINESS_DETAILS: "auth/verify_business",
  RESET_PASSWORD_OTP: "auth/reset_pass_otp",
};

const USER_URLS = {
  INSERT_BUSINESS: "user/insert_business",
  GET_ONE_PROFILE: "user/get_one_profile",
  GET_ALL_ADMIN: "user/get_all",
  UPDATE_STATUS: "user/update",
  INSERT_CUSTOMER: "user/insert_customer",
  GET_ALL_SELECT_STATES: "/user/get_all_select_states",
};

export { ADMIN_LOGIN_URLS, BUSINESS_LOGIN_URLS, USER_URLS };
