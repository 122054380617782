import { useNavigate } from 'react-router-dom';

const SmartHeader = ({title,sectorName}) => {
    const navigate = useNavigate();
    const baseNav = "";
  
    const navigateLink = (index) => {
      navigate(baseNav + "/" + index);
    };
  return (
    <>
     <nav className="breadcrumb  " aria-label="breadcrumbs">
          <ul>
            <li className='customer-primary-font'>
            <div className='is-flex'>
                <div className='breadcrumb-home'>
             
                  <i className="fa fa-home mt-1 mr-1" aria-hidden="true" onClick={() => navigateLink("")}></i>
           
                <span
                  onClick={() => navigateLink("")}
                >
                  Home
                </span>
                <i class="fa fa-angle-right mt-1 mx-1" aria-hidden="true"></i>
                </div>
                <span className="">
                {title} <span className='mr-1'>{sectorName}</span>
                </span>
              </div>
            </li>
           
          </ul>
        </nav>
    </>
  )
}

export default SmartHeader