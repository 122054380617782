import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSiteContext } from "../contexts/SiteProvider";
import { showAlertAutoClose } from "../services/notifyService";
import { setSessionStorage } from "../services/sessionService";
const PrivateRoute = React.memo(({ children, allowedRoles }) => {
  const { user } = useSiteContext();
  const location = useLocation();
  console.log(" use login ", user);
  //nst user = false;
  // now ony implemeted user logged in or not.. has to impliment checking the roles
  if (!user) {
    const currentPath = location.pathname;
    setSessionStorage("CROP_CUSTOMER_LOGIN", currentPath);
    //console.log("location path ", currentPath);
    // console.log("displayed twice");
    // showNotification("error","unauthorized access");
    showAlertAutoClose("Please Login", "error");
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  }
  setSessionStorage("CROP_CUSTOMER_LOGIN", "");
  return children;
});

export default PrivateRoute;
