import { Route, Routes } from "react-router-dom";
import { AboutUs, EarnCropsDetails, EarnCropsView, HomePage, ProductQuickView, PromosView, RedeemCropsView, Tittle,AboutUsNew} from '../../pages/Customer';
import StarRatings from "../../pages/Customer/Home/StarRatings";





const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/earn-crops" element={<EarnCropsView />} />
      <Route path="/offer-details/:type/:id" element={<EarnCropsDetails />} />
      <Route path="/promos" element={<PromosView />} />
      <Route path="/product-view" element={<ProductQuickView />} />
      <Route path="/tittle/:id" element={<Tittle />} />
      <Route path="/redeem-crops" element={<RedeemCropsView />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/about-us-new" element={<AboutUsNew/>} />
      {/* <Route path="/ratings-new" element={<StarRatings />} /> */}
    </Routes>
  )
}

export default HomeRoute