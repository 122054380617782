import { useState } from "react";
import { SmartSoftSelect, SmartSoftTable } from "soft_digi";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { HEADSET_IMG } from "../../../services/ImageService";

const ShoppingCart = () => {
  const {  closeModal } = useSiteContext();
  const nameFunction = () => {
    return (
      <>
        <div className="is-flex">
          <img className="image is-32x32" src={HEADSET_IMG} alt="headset" />
        </div>
      </>
    );
  };

  const [count, setCount] = useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
  };

  const AddFunction = () => {
    return (
      <>
        <div className="is-flex">
          <button
            className="button is-rounded is-size-7 mr-3 is-info"
            onClick={decrement}
          >
            -
          </button>
          <p>{count}</p>
          <button
            className="button is-rounded is-size-7 ml-3 is-info"
            onClick={increment}
          >
            +
          </button>
        </div>
      </>
    );
  };

  const PriceFunction = (row) => {
    return (
      <>
        <div className="is-flex">
          <div>
            <del>A$ 678</del>
          </div>
          <div className="has-text-info has-text-weight-bold">
            {row["totalprice"] || ""}
          </div>
        </div>
      </>
    );
  };

  const DiscountFunction = (row) => {
    return (
      <>
        <div className="has-text-danger has-text-weight-bold">
          {row["discount"] || ""}
        </div>
      </>
    );
  };

  const columns = [
    {
      title: "Offer Image",
      index: "offerimage",
      // width: "10",
      valueFunction: nameFunction,
    },
    { title: "Offer Title", index: "offertitle" },
    { title: "CROP", index: "crops" },
    {
      title: "Discount",
      index: "discount",
      // width: "10",
      valueFunction: DiscountFunction,
    },
    {
      title: "Quantity",
      index: "qty",
      // width: "10",
      valueFunction: AddFunction,
    },
    {
      title: "Price (A$)",
      index: "price",
      // width: "10",
      valueFunction: PriceFunction,
    },
  ];

  const data = [
    {
      offertitle: "Ear Buds",
      crops: "C 1005.48",
      discount: "10%",
      qty: 10,
      totalprice: "AUD6780",
    },
    {
      offertitle: "Ear Buds",
      crops: "C 1005.48",
      discount: "10%",
      qty: 10,
      totalprice: "AUD6780",
    },
    {
      offertitle: "Ear Buds",
      crops: "C 1005.48",
      discount: "10%",
      qty: 10,
      totalprice: "AUD6780",
    },
  ];
  return (
    <>
      <div className="">
        <div className="is-flex is-justify-content-space-between">
          <div className="is-size-4 has-text-weight-bold mt-4 customer-header">
            SHOPPING CART
          </div>
          <div className="mt-4">
            <SmartSoftSelect placeHolder="Earn CROPs" isHorizontal />
          </div>
          <div onClick={closeModal}>
            <div className="customer-modal-close mt-3 ">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <SmartSoftTable
            columns={columns}
            data={data}
            tableProps={{ className: "crop-customer-table-layout" }}
          />
        </div>
        <div className="button smart-customer-primary-button is-flex is-justify-content-space-between">
          <p>Total Price</p>
          <p>A$ 33900.00</p>
        </div>
        <div className="is-flex is-justify-content-space-around mt-5">
          <button className="button is-info is-rounded">View Cart</button>
          <button className="button is-info is-rounded">Checkout</button>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
