import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  SmartSoftInput,
  SmartSoftTable,
} from "soft_digi";
import { SERVICES_COMPLAINT_URLS } from "../../../../api/Services/ServiceUrls";
import SmartHeader from "../../../../components/site/SmartHeader";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  changeDateTimeZoneFormat,
  displayFiftyDots,
} from "../../../../services/core/CommonService";
import {
  filterDate,
  filterIncludes,
} from "../../../../services/core/FilterService";
import { showAlertAutoClose } from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";
import RequestAndComplaintView from "./RequestAndComplaintView";
import RequestForm from "./RequestForm";
import { CROP_CUSTOMER_REQUEST_IMAGE } from "../../../../services/ImageService";
import moment from "moment";

const RequestAndComplaint = () => {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { setLoading, closeModal } = useSiteContext();
  const [formErrors, setFormErrors] = useState(false);
  const [minEndDate, setMinEndDate] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tab, setTab] = useState("NEW");
  const handleTabs = (index) => {
    // console.log("index " , index);
    setTab(index);
  };
  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "start_date") {
      setMinEndDate(value);
    }
    if (name == "search_string") {
      const out_data = data.filter(filterFunction);
      setFilterData(out_data);
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const DescriptionShowFunction = (item) => {
    return (
      <>
        <div>{displayFiftyDots(item.complaint_description)}</div>
      </>
    );
  };
  const CategoryShowFunction = (item) => {
    return (
      <>
        <div>{displayFiftyDots(item.comp_type)}</div>
      </>
    );
  };
  const showStatus = (item) => {
    return (
      <>
        <div className="customer-order-status-text has-text-left">
          {item.complaint_status == "PROGRESS"
            ? "IN PROGRESS"
            : item.complaint_status}
        </div>
      </>
    );
  };
  const ResolutionShowFunction = (item) => {
    return (
      <>
        <div>{displayFiftyDots(item.complaint_resolution)}</div>
      </>
    );
  };
  const ClosedDateShowFunction = (item) => {
    return (
      <>
        {item.complaint_status == "CLOSED" ? (
          <div>
            {changeDateTimeZoneFormat(item.last_modified_time, "DD-MM-YYYY")}
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };

  const request_complaint = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    return (
      <>
        <div
          className="is-flex is-clickable has-text-info"
          onClick={() => openNewForm({ ...data })}
        >
          {data["complaint_id"] || ""}
        </div>
      </>
    );
  };

  const tabs = [
    //  { index: "CROP", label: "Customer Orders" },
    { index: "NEW", label: "New Request or Complaint", icon: "fa fa-suitcase" },
    {
      index: "OLD",
      label: "Existing Request or Complaint",
      icon: "fa fa-stack-exchange",
    },
  ];

  const tabs_display = () => {
    return (
      <div className="tabs smart-customer-tabs ">
        <ul>
          {tabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleTabs(item.index)}
                className={
                  tab === item.index ? "order-active-tab" : "tabs-list-items"
                }
              >
                <a href="#">
                  <span
                    className={
                      tab === item.index
                        ? "order-active-tab-text pr-5 pl-5"
                        : "pr-5 pl-5"
                    }
                  >
                    {item.label}
                  </span>
                  <span className="icon is-small">
                    <i className={item.icon} aria-hidden="true"></i>
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const toCamelCase = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/[_-\s]+(.)?/g, (match, chr) => (chr ? chr.toUpperCase() : "")) // Replace underscores, hyphens, and spaces
      .replace(/^[a-z]/, (match) => match.toUpperCase()); // Ensure the first letter is uppercase
    //.replace(/^[A-Z]/, (match) => match.toLowerCase()); // Ensure the first letter is lowercase
  };

  const openNewForm = (data) => {
    data.complaint_status =
      data?.complaint_status == "PROGRESS"
        ? "IN PROGRESS"
        : data.complaint_status;
    data.type = toCamelCase(data.type);
    data.complaint_status = toCamelCase(data.complaint_status);
    data.last_modified_time = changeDateTimeZoneFormat(
      data?.last_modified_time,
      "DD-MM-YYYY"
    );
    let modelObject = {
      body: <RequestAndComplaintView closeModal={closeModal} data={data} />,
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };
  const offer_type = (data) => {
    return (
      <>
        <div className="customer-order-status-text has-text-left">
          {data.type}
        </div>
      </>
    );
  };

  const columns = [
    { title: "Type", index: "type", valueFunction: offer_type },
    {
      title: "Category",
      index: "comp_type",
      valueFunction: CategoryShowFunction,
      width: "15",
    },
    {
      title: "Description",
      index: "complaint_description",
      valueFunction: DescriptionShowFunction,
      // width: "15",
    },
    {
      title: "Number",
      index: "complaint_id",
      valueFunction: request_complaint,
      width: "10",
    },
    {
      title: "Date Raised",
      index: "complaint_date",
      dateFormat: "DD-MM-YYYY",
      type: "date",
      width: "10",
      autoTimeZone: true,
    },
    {
      title: "Status",
      index: "complaint_status",
      valueFunction: showStatus,
      width: "10",
    },
    {
      title: "Resolution",
      index: "complaint_resolution",
      valueFunction: ResolutionShowFunction,
      width: "15",
    },

    {
      title: "Last Modified",
      index: "last_modified_time",
      dateFormat: "DD-MM-YYYY",
      type: "date",
      width: "10",
      autoTimeZone: true,
    },

    {
      title: "Date Closed",
      index: "last_modified_time",
      dateFormat: "DD-MM-YYYY",
      // type: "date",
      width: "10",
      valueFunction: ClosedDateShowFunction,
    },
  ];

  const loadTableData = () => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    // setLoading(true, "Loading....Please Wait");
    let url = SERVICES_COMPLAINT_URLS.GET_ALL_USER;
    const subscription = post(url, {}, handleError).subscribe((response) => {
      setLoading(false);
      setData(response.data);
      setFilterData(response.data);
      if (formData.search_string) searchData();
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [tab]);

  useEffect(() => {
    searchData();
  }, [formData]);

  const filterFunction = (item) => {
    let output = filterDate(
      item,
      "complaint_date",
      formData.start_date,
      formData.end_date
    );
    output =
      output &&
      filterIncludes(
        item,
        ["complaint_id", "complaint_description", "complaint_status"],
        formData.search_string
      );
    return output;
  };

  const searchData = () => {
    const out_data = data.filter(filterFunction);
    setFilterData(out_data);
  };

  const { openModal } = useSiteContext();

  const openFormModal = (id, name) => {
    let modalObject = {
      title: "New Request or Complaint ",
      body: (
        <RequestForm
          loadTableData={loadTableData}
          dataIn={{}}
          setTab={setTab}
        />
      ),
    };
    openModal(modalObject);
  };

  const dateFormElements = [
    {
      type: "DATE",
      width: "12",
      name: "start_date",
      element: {
        label: "From",
        placeHolder: "dd-mm-yyyy",
        isHorizontal: true,
        maxDate: currentDate,
      },
    },
  ];
  const dateFormTwoElements = [
    {
      type: "DATE",
      width: "12",
      name: "end_date",
      element: {
        label: "To",
        placeHolder: "dd-mm-yyyy",
        isHorizontal: true,
        minDate: minEndDate,
        maxDate: currentDate,
      },
    },
  ];

  return (
    <>
      <div className=" px-5 ml-5">
        <SmartHeader title={"Request or Complaint"} />
      </div>
      <div className="columns is-centered">
        <div className=" column mt-3 has-text-centered">{tabs_display()}</div>
      </div>
      {tab === "NEW" && (
        <>
          <div className="columns p-6">
            <div className="column is-6">
              <RequestForm
                loadTableData={loadTableData}
                dataIn={{}}
                handleTabs={handleTabs}
              />
              ,
            </div>

            <div className="column is-6">
              <div className="mb-6 has-text-centered">
                <img src={CROP_CUSTOMER_REQUEST_IMAGE} className="" alt="" />
              </div>
            </div>
          </div>
        </>
      )}

      {tab === "OLD" && (
        <div className="container p-4 mt-4 smart-customer-input">
          <div className=" has-text-centered mb-5 ">
            <span className="customer-header"> Request or Complaint</span>
          </div>

          <div className="columns">
            <div className="column px-5">
              <div className="columns ">
                <div className="column RequestAndComplaint-property-center ">
                  <SmartSoftForm
                    formData={formData}
                    setFormData={handleInputChange}
                    elements={dateFormElements}
                    handleErrorChange={handleErrorChange}
                    // onChange={(value) => setFilterData("from_date", value)}
                  />
                </div>
                <div className="column">
                  <SmartSoftForm
                    formData={formData}
                    setFormData={handleInputChange}
                    elements={dateFormTwoElements}
                    handleErrorChange={handleErrorChange}
                    // onChange={(value) => setFilterData("from_date", value)}
                  />
                </div>
                <div className="column ">
                  <div className="smart-customer-earn-crop-input-two">
                    <SmartSoftInput
                      type="text"
                      // rightIcon="fa fa-search"
                      placeHolder="Search "
                      value={formData?.search_string || ""}
                      onChange={(value) =>
                        handleInputChange("search_string", value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <SmartSoftTable
                    columns={columns}
                    data={filterData}
                    tableProps={{
                      className: "crop-bussiness-table-layout-No-bottom",
                    }}
                  />
                  {filterData && filterData.length < 1 && (
                    <div className="no-record-found-text-two">
                      There are no contents to display for your current search
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestAndComplaint;
