import { useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  SmartValid
} from "soft_digi";
// import { isEmptyObject } from "../../../services/core/CommonService";
import {
  ORDER_ITEMS_URLS,
} from "../../../api/OrderUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
// import { getImageContent } from "../../../services/fileService";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";

const OrderReturn = ({ id, loadOrderData }) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal, refreshUser } = useSiteContext();

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    // console.log(" name ", name, "   value ", value);
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const handleError = (errorMessage) => {
    showAlertAutoClose(errorMessage, "error");
    setLoading(false);
  };

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    setLoading(true, "Processing....Please Wait");
    let url = ORDER_ITEMS_URLS.ORDER_RETURN;
    let data_in = { ...formData, id: id };
    // console.log(data_in)
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose("Return Request Submitted Successfully", "success");
        setLoading(false);
        loadOrderData();
        closeModal();
        refreshUser();
        window.scrollTo(0, 0);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const Validations = {
    status: [SmartValid.required("Reason is Required")],
    remarks: [SmartValid.required("Remarks is Required")],
    image: [SmartValid.required("Image is Required")],
  };

  const options = [
    { value: "Order By Mistake", label: "Order By Mistake" },
    { value: "Change of Mind", label: "Change of Mind" },
    { value: "Others", label: "Others" },
  ];
  const formElements = [
    {
      type: "SELECT_BOX",
      width: "12",
      name: "reason",
      element: {
        label: "Reason",
        isRequired: true,
        validations: Validations.status,
        options: options,
      },
      hideFunction:(data)=>{
        // console.log("formdata " , formData)
        return formData.reason && formData.reason.value && formData.reason.value==="Others" ?  true:false;
      }
    },
    {
      type: "TEXTAREA",
      width: "12",
      name: "remarks",
      element: {
        label: "Remarks",
        isRequired: true,
        max: "255",
        validations: Validations.remarks,
      },
      hideFunction:(data)=>{
        // console.log("formdata " , formData)
        return formData.reason && formData.reason.value && formData.reason.value==="Others" ? false : true;
      }
    },
    {
      type: "FILE",
      width: "12",
      name: "return_image",
      element: {
        placeHolder: 
        (
          <p>
           Upload Image <span className="smart-error">*</span>
          </p>
        ),
        fileNameEnable: false,
        leftIcon: "fa fa-cloud-upload",
        isMulti: true,
        isRequired: true,
        filePreview: true,
        validations: Validations.image,
      },
    },
  ];

  return (
    <div>
      <div className="smart-customer-input">
        <div className="is-flex is-justify-content-space-between">
          <div className="is-size-4 has-text-weight-bold mt-4">
            Order Return Request
          </div>
          <div className="mb-3" onClick={closeModal}>
            <div className="customer-modal-close mb-3 ">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="mb-3 smart-customer-return-image">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
            // onChange={(value) => handleInputChange("return_image", value)}
          />
        </div>
        {/* <div className="card-content smart-business-image-file is-flex is-justify-content-center">
              <SmartSofFile
                placeHolder="Upload Image"
                value={formData?.return_image}
                validations= {Validations.image}
                onChange={(value) => handleInputChange("return_image", value)}
                errorEnable={formSubmit}
                fileNameEnable={false}
                leftIcon="fa fa-cloud-upload"
                isMulti={true}
              />
            </div> */}
        <div className="card-image">
          <SmartImageDisplay
            srcType="DATA"
            data={formData?.return_image || []}
            isMulti={true}
            imageClass="is-6"
            updateImages={(images) => handleInputChange("return_image", images)}
          />
        </div>
        {/* <div className="card-content has-text-centered -4">
          <figure class="image is-128x128 ml-6 ">
            <img
              class="is-rounded"
              src={getImageContent(formData?.return_image)}
              alt="test"
            />
          </figure>

          <SmartSofFile
            placeHolder="Upload Image"
            value={formData?.return_image}
            onChange={(value) => handleInputChange("return_image", value)}
            errorEnable={formSubmit}
            fileNameEnable={false}
            validations={Validations.image}
            errorUpdate={(value) => handleErrorChange("profile_image", value)}
          />
        </div> */}
        <div className="mb-3 mt-6 is-flex is-justify-content-center">
          <SmartSoftButton
            label="Submit"
            classList={["smart-customer-primary-button"]}
            onClick={() => handleFormSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderReturn;
