import { useState } from "react";
import {
  SmartSoftButton,
  SmartSoftInput,
  SmartSoftSelect,
  SmartValid,
} from "soft_digi";
import { CROP_CUSTOMER_MORE_PURCHASE_CROP } from "../../../services/ImageService";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { isEmptyObject } from "../../../services/core/CommonService";
import "./CustomerMore.css";

const PurchaseCrops = () => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  // const handleFormSubmit = () => {};
  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!isEmptyObject(formErrors)) {
      return false;
    }
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const options = [
    { value: "Select CROPs", label: "Select CROPs" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const FormValidations = {
    crop_name: [SmartValid.required("Crop Name is Required")],
    crops: [SmartValid.required("Crop is Required")],
    total: [SmartValid.required("Total is Required")],
  };

  const formDisplay = () => {
    return (
      <div className="columns is-multiline mt-5 smart-customer-input">
        <div className="column is-12">
          <SmartSoftSelect
            key="text-1"
            label="Select CROP"
            options={options}
            value={formData?.crop_name || ""}
            onChange={(value) => handleInputChange("crop_name", value)}
            // validations={FormValidations.crop_name}
            errorEnable={formSubmit}
            handleErrorChange={(value) => handleErrorChange("crop_name", value)}
            errorUpdate={(value) => handleErrorChange("crop_name", value)}

            // leftIcon="fa-user"
          />
        </div>
        <div className="column is-12 crop-admin-table-title has-text-weight-medium  has-text-centered is-size-6 head-crop">
          OR
        </div>
        <div className="column is-6  smart-customer-input">
          <SmartSoftInput
            key="text-4"
            label="Enter CROP"
            value={formData?.crops || ""}
            onChange={(value) => handleInputChange("crops", value)}
            placeHolder="Enter CROP"
            pattern={ALLOW_NUMERIC}
            inputProps={{ isFocussed: true }}
            validations={FormValidations.crops}
            errorEnable={formSubmit}
            handleErrorChange={(value) => handleErrorChange("crops", value)}
            errorUpdate={(value) => handleErrorChange("crops", value)}
          />
        </div>
        <div className="column is-6">
          <SmartSoftInput
            key="text-4"
            label="Total A$"
            value={formData?.total || ""}
            onChange={(value) => handleInputChange("total", value)}
            inputProps={{ isFocussed: true }}
            pattern={ALLOW_NUMERIC}
            validations={FormValidations.total}
            errorEnable={formSubmit}
            handleErrorChange={(value) => handleErrorChange("total", value)}
            errorUpdate={(value) => handleErrorChange("total", value)}
          />
        </div>
        <div className="column is-12 has-text-centered mt-4">
          <SmartSoftButton
            label="Proceed to Pay"
            classList={["smart-customer-primary-button", ""]}
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="columns  container  ml-3">
        <div className="column is-6  m-3">
          <div className="column is-12 crop-admin-table-title has-text-centered has-text-weight-medium is-size-3 head-crop">
            Purchase CROP
          </div>
          {formDisplay()}
        </div>
        <div className="column is-6 m-6 p-6 has-text-centered ">
          <img src={CROP_CUSTOMER_MORE_PURCHASE_CROP} alt="" />
        </div>
      </div>
    </>
  );
};

export default PurchaseCrops;
