import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftTable } from "soft_digi";
import { CUSTOMER_WISHLIST_URLS } from "../../../api/OrderUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { CROP_CUSTOMER_CROP_IMAGE } from "../../../services/ImageService";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
// import SmartStripePayment from "../../../components/site/SmartStripePayment";
import { CUSTOMER_OFFERS } from "../../../api/OfferUrls";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import SmartHeader from "../../../components/site/SmartHeader";
import { displayFiftyDots, displayDots } from "../../../services/core/CommonService";
import SmartImageDisplayNew from "../../../components/site/FormElements/SmartImageDisplayNew";

const SingleCart = ({ state }) => {
  const [data, setData] = useState([]);
  const { setLoading, refreshUser } = useSiteContext();
  const navigate = useNavigate();

  const updateItemQuantity = (itemId, amount) => {
    // added to update the quant at backend
    const itemcheck = data.find((item) => item.ID === itemId);
    const updated_quant = Math.max(1, parseInt(itemcheck.quantity) + amount);
    updateSingleQuant(itemId, updated_quant);
    // console.log("updated auant " , updated_quant);

    setData((prevItems) =>
      prevItems.map((item) =>
        item.ID === itemId
          ? { ...item, quantity: Math.max(1, parseInt(item.quantity) + amount) } // Ensure quantity is not less than 1
          : item
      )
    );
  };

  const updateSingleQuant = (id, quant) => {
    const handleError = (errorMessage) => {
      setLoading(false);
    };
    //  setLoading(true, "Please Wait....");
    let url = CUSTOMER_WISHLIST_URLS.UPDATE_SINGLE_QUANTITY;
    let data_in = { id: id, quantity: quant };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        // setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loadWishListData = (e) => {
    //setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Loading....Please Wait");
    let url = CUSTOMER_WISHLIST_URLS.GET_USER_WISHLIST;
    let offer_type = state ? state : "EARN";
    let data_in = { type: "CART", offer_type: offer_type };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);
        setData(response.data);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const updateQuant = () => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Loading.... Please Wait");
    let url = CUSTOMER_WISHLIST_URLS.UPDATE_QUANTITY;
    let data_in = { data: [...data] };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);

        let navLink = "/whish-list/checkout/" + state.toLowerCase();
        // need to navigate to confirm order
        navigate(navLink);
        // showAlertAutoClose("Offer Moved to Cart", "success");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const deleteWhishList = (id) => {
    showYesOrNoAlert(
      "Do you wish to delete this offer?",
      (selection) => deleteWhishlist(id, selection),
      "danger"
    );
  };

  const deleteWhishlist = (id, action) => {
    if (action === "yes") {
      const handleError = (errorMessage) => {
        showAlertAutoClose(errorMessage, "error");
        setLoading(false);
        setData([]);
      };
      setLoading(true, "Deleting....Please Wait");
      let url = CUSTOMER_WISHLIST_URLS.DELETE_WISHLIST;
      let data_in = { id: id };
      const subscription = post(url, data_in, handleError).subscribe(
        (response) => {
          setLoading(false);
          loadWishListData();
          showAlertAutoClose("Offer Deleted from Cart", "success");
          refreshUser();
        }
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  };

  useEffect(() => {
    loadWishListData();
  }, [state]);

  const offer_image = (data) => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data.sd_offers_id !== undefined ? data.sd_offers_id : 0;
    return (
      <>
        <div className="is-flex is-justify-content-center smart-table-order-image">
          <SmartImageDisplayNew
            srcType="URL"
            url={url}
            id={id}
            imageClass="is-64x64 is-centered table-image-height"
          />
        </div>
      </>
    );
  };

  const offer_title_disp = (data) => {
    return (
      <>
        <p>
          {data.offer_title} <br />
          {data.variants.map((item) => {
            return (
              <span className="is-size-7 ml-2">
                <b>{item.variant_group}</b> : {item.variant_name}
              </span>
            );
          })}
                      {data?.custom_note && data?.custom_note?.length >0 && (
   <div className="smart-lable-customer">Customer Notes: <span className="smart-font-style-customer ">  {displayDots(data.custom_note)}</span></div>
)}
        </p>
      </>
    );
  };

  const moveToCart = (id) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
      setData([]);
    };
    setLoading(true, "Moving Please Wait....");
    let url = CUSTOMER_WISHLIST_URLS.UPDTAE_WISHLIST;
    let data_in = { id: id, type: "WISHLIST" };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        refreshUser();
        setLoading(false);
        loadWishListData();
        showAlertAutoClose("Offer Moved to Wishlist", "success");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };


  const buttons = [
    {
      label: "",
      type: "icon",
      leftIcon: "fa fa-trash",
      classList: ["delete-color is-clickable is-size-5"],
      onClick: (data) => {
        deleteWhishList(data["ID"]);
      },
    },
    {
      label: "",
      type: "icon",
      // leftIcon: "fa fa-heart-o",
      leftIconFunction: () => {
        return (
          <>
            <div className="back-heart-icon-view">
              <i class="fa fa-heart" aria-hidden="true"></i>
              <span>
                <i class="fa fa-reply" aria-hidden="true"></i>
              </span>
            </div>
          </>
        );
      },
      classList: [" delete-color is-clickable is-size-5 mt-2 ml-2"],
      onClick: (data) => {
        moveToCart(data["ID"]);
      },
    },
  ];

  const CropTableShowFunction = (data) => {
    return (
      <>
        <div className="is-flex is-justify-content-center">
          {/* <img
            className="image is-16x16 mt-1 mr-2"
            src={CROP_CUSTOMER_CROP_IMAGE}
            alt=""
          /> */}
          <p>{data.crop_points} </p>
        </div>
      </>
    );
  };

  const AddFunction = (data) => {
    return (
      <>
        <div className="is-flex is-justify-content-center ">
          <button
            className="button is-size-7 mr-3 customer-increment-decrement"
            onClick={() => updateItemQuantity(data.ID, -1)}
          >
            -
          </button>
          <p>{data?.quantity}</p>
          <button
            className="button is-size-7 ml-3 customer-increment-decrement"
            onClick={() => updateItemQuantity(data.ID, 1)}
          >
            +
          </button>
        </div>
      </>
    );
  };

  const PriceFunction = (row) => {
    let tot_price = row["quantity"] * row["total_price"];
    let total = Number(tot_price).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      <>
        <div className="has-text-success has-text-weight-bold">
          {total || ""}
        </div>
      </>
    );
  };

  const cropsFunction = (row) => {
    let tot_price = row["quantity"] * row["crop_points"];
    return (
      <>
        <div className="has-text-success has-text-weight-bold">
          {tot_price || ""}
        </div>
      </>
    );
  };

  const columns = [
    {
      title: "Offer Image",
      index: "offer_image",
      width: "10",
      valueFunction: offer_image,
    },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "25",
      valueFunction: offer_title_disp,
    },
    {
      title: "CROPs",
      index: "crop_points",
      width: "10",
      valueFunction: CropTableShowFunction,
    },
    { title:  <>Price <label className="customer-secondary-font " >(A$) </label> </>,
       index: "total_price", width: "10" },
    {
      title: "Quantity",
      index: "qty",
      width: "10",
      valueFunction: AddFunction,
    },
    {
      title: "Total Price",
      index: "totalprice",
      width: "10",
      valueFunction: PriceFunction,
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];

  const redeem_columns = [
   
    {
      title: "Offer Image",
      index: "offer_image",
      width: "10",
      valueFunction: offer_image,
    },
    {
      title: "Offer Title",
      index: "offer_title",
      width: "25",
      valueFunction: offer_title_disp,
    },
    {
      title: "CROPs",
      index: "crop_points",
      width: "10",
      valueFunction: CropTableShowFunction,
    },
    // { title: "Price(AUD)", index: "total_price", width: "10" },
    { title: "", index: "", width: "10" },
    {
      title: "Quantity",
      index: "qty",
      width: "10",
      valueFunction: AddFunction,
    },
    {
      title: "Total CROPs",
      index: "totalprice",
      width: "10",
      valueFunction: cropsFunction,
    },
    {
      title: "Action",
      index: "action",
      type: "buttons",
      buttons: buttons,
      width: "10",
    },
  ];

  const tableButton = () => {
    return (
      <>
        <div className="mt-4">
          <SmartSoftTable
            columns={state === "EARN" ? columns : redeem_columns}
            data={data}
            tableProps={{ className: "crop-customer-table-layout" }}
            footerConfig={[]}
            paginationProps={{ pageSize: 2000 }}
          />
        </div>
        <div className="is-flex is-justify-content-center">
          <button
            className="button smart-customer-primary-button mt-4"
            onClick={() => updateQuant()}
          >
            Checkout
          </button>
        </div>
      </>
    );
  };

  const card_title = () => {
    return state === "REDEEM" ? "Redeem CROPs" : "Earn CROPs";
  };

  const noItemsMessage = () => {
    // const navigate = useNavigate();
    // const navigateLink = (index) => {};

    const baseNav = "";
    const navigateLink = (index) => {
      navigate(baseNav + "/" + index);
    };
    // const navigateLink = (index) => {};
    return (
      <div className=" has-text-centered customer-primary-font smart-crop-customer">
        <div className="my-2 has-text-weight-bold">
          No Offers Available
          <span
            className="login-forgot-corser pl-1 pr-1"
            onClick={() => navigateLink("")}
          >
            Click here
          </span>
          to Add Offers <i className="fa fa-gift" aria-hidden="true"></i>
        </div>

        {/* <SmartSoftButton
              label="Click here to Add items"
              classList={[" smart-customer-primary-button"]}
              onClick={() => navigateLink("")}
            /> */}
      </div>
    );
  };

  return (
    <div className=" ">
      <header className="">
        <p className="primary-color">{card_title()}</p>
      </header>
      <div className="">
        {data && data.length > 0 && tableButton()}
        {data && data.length < 1 && noItemsMessage()}
      </div>
    </div>
  );
};

const ViewCart = () => {
  const navigate = useNavigate();
  // const navigateLink = (index) => {};

  const baseNav = "";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <div className="px-5 ml-5 mt-0">
          <SmartHeader title={"Shopping Cart"} />
        </div>
        {/* <div
          className="smart-customer-back mr-5 px-5 is-hidden-mobile"
          onClick={() => navigateLink("whish-list/whish-list")}
        >
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          {""} Go Back
        </div> */}
      </div>
      <div className=" px-6 pb-6">
        {/* <SmartStripePayment /> */}
        <div className="is-flex is-justify-content-center customer-header mb-3 ">
          Shopping Cart
        </div>
        <div className="is-flex is-justify-content-space-between">
          <div className="smart-customer-whish-list-input">
            {/* <SmartSoftSelect
              label="Select Type"
              isHorizontal
              value={state}
              options={selectOptions}
              onChange={(value) => setState(value)}
            /> */}
          </div>
          {/* <div>
            <SmartSoftButton
              label="Go Back"
              classList={[" smart-customer-primary-button"]}
              onClick={() => navigateLink("")}
            />
          </div> */}
          {/* <div className="smart-customer-back mr-4" onClick={() => navigateLink("whish-list/whish-list")}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
           {""} Go Back
          </div> */}
        </div>
        <SingleCart state="EARN" key="earn" />
        <SingleCart state="REDEEM" key="redeem" />
      </div>
    </>
  );
};

export default ViewCart;
