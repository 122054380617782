import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import "./CustomerLayoutCss.css";


const SideNavComponenet = ({ menuName, navs, open, openNav, }) => {
    const [sidebarOpen, setSidebarOpen] = useState(open);
    // const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigation = (link) => {
        navigate(link);
    };
    const handleToggle = () => {
        setSidebarOpen(prevState => !prevState);
        openNav()
    };
    // const toggleSidebar = () => {
    //     setIsOpen(!isOpen); // Toggle the state
    //   };

    useEffect(() => {
        setSidebarOpen(open)
    }, [open]);
    return (
        <>
        
        <div className='crop-bussiness-side-nav-menu menu ml-3'>
            <div className="menu-label " onClick={handleToggle}>
              
                <hr className="smart-side-nav-hr" />
                 {menuName}
              <span className=' has-text-right'> <i class={"fa side-nav-more-icon " + (sidebarOpen ? "fa-caret-down" : "fa-caret-right")} aria-hidden="true"></i></span> 
            </div>
            {sidebarOpen &&
                <ul className="menu-list">
                    {navs.map((item, key) => (
                        <li key={key} onClick={() => handleNavigation(item?.link) }
                            className={location.pathname === item.link ? 'list-item-active' : 'list-items '}>
                            <span className='is-flex'>
                                <span className='icon is-size-7 has-text-info-dark'>{item.icon}</span>
                                <span className='is-size-7  has-text-weight-bold mt-1'>{item.label}</span>
                            </span>
                        </li>
                    ))
                    }
                </ul>
            }
        </div>
        </>
    )


}

export default SideNavComponenet;