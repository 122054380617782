import { useEffect, useState } from "react";
import {
  SmartSoftButton,
  SmartSoftForm,
  // SmartSoftInput,
  // SmartSoftSelect,
  SmartValid,
} from "soft_digi";
import { CUSTOMER_MY_ACCOUNT } from "../../../api/OfferUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import { isEmptyObject } from "../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { admin_states_select } from "../../../services/site/SelectBoxServices";
import {
  // get,
  post,
} from "../../../services/smartApiService";

const Address = ({loadAddressData}) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [states, setStates] = useState([]);
  const [formErrors, setFormErrors] = useState(false);
  const {  closeModal, setLoading ,refreshUser} = useSiteContext();


  const handleInputChange = (name, value) => {
    //console.log(" name ", name, "   value ", value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };
  const loadSelectOptions = () => {
    // states selection
    admin_states_select((data) => setStates(data));
  };
  useEffect(() => {
    loadSelectOptions();
  }, []);

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if(!isEmptyObject(formErrors)){
      return false;
    }
    //  console.log("formdata", formErrors)
     ;
   
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Updating.... Please Wait");
    let url = CUSTOMER_MY_ACCOUNT.DELIVERY_ADDRESS_INSERT;

    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        if(loadAddressData && loadAddressData instanceof Function){
          loadAddressData()
        }
        showAlertAutoClose(" Address Updated Successfully", "success");
        setLoading(false);
        closeModal();
        refreshUser()
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };
  const Validations = {
    addr_one: [SmartValid.required("Address Line 1 is Required")],
    // addr_two: [SmartValid.required("Address Line 2 is Required")],
    // city: [SmartValid.required("Address Line 3 is Required")],
    state: [SmartValid.required("State is Required")],
    pincode: [SmartValid.required("Pin code is Required")],
  };

  const optionsSelector =[{value:"1", label:"Change this address in my profile too"}]
  const formElements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "addr_one",
      element: {
        label: "Address Line 1",
        validations: Validations.addr_one,
        isRequired: true,
        inputType: "BORDER_LABEL_FOCUS",
        inputProps:true
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "addr_two",
      element: {
        label: "Address Line 2",
        inputType: "BORDER_LABEL_FOCUS",
        inputProps:true
        // validations: Validations.addr_two,
        // isRequired: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "city",
      element: {
        label: "Address Line 3",
        validations: Validations.city,
        // isRequired: true,
        inputType: "BORDER_LABEL_FOCUS",
        inputProps:true
      },
    },

    {
      type: "SELECT_BOX",
      width: "12",
      name: "state",
      element: {
        label: "State",
        isRequired: true,
        validations: Validations.state,
        options: states,
        inputType: "BORDER_LABEL",
        inputProps:true
      },
    },

    {
      type: "TEXT_BOX",
      width: "12",
      name: "pincode",
      element: {
        label: "Pincode",
        validations: Validations.pincode,
        isRequired: true,
        max:4,
        pattern: ALLOW_NUMERIC,
        inputType: "BORDER_LABEL_FOCUS",
        inputProps:true
      },
    },
    {
      type: "CHECK_RADIO",
      width: 12,
      name: "address_update",
      element: {
        isMultiline: true,
        options: optionsSelector,
        isHorizontal: true,
        // isRight: true,
      },
    },

    //  const loadSelectOptions = () => {
    //   // load sector options
    //   admin_loyalty_select((data) => setLoyalty(data));
    //   //
    //   admin_interest_select((data) => setInterest(data));
    //   // states selection
    //   admin_states_select((data)=>setStates(data));
    // }

    /*
   
    },*/
  ];
  return (
    <>
      <div className="smart-customer-input">
        <div className="is-flex is-justify-content-space-between">
          <div className="is-size-4 has-text-weight-bold">
            Delivery Address
          </div>
          <div className="" onClick={closeModal}>
            <div className="customer-modal-close">
              <i class="fa fa-times has-text-white" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="mb-3 mt-3">
          <SmartSoftForm
            formData={formData}
            setFormData={handleInputChange}
            elements={formElements}
            formSubmit={formSubmit}
            handleErrorChange={handleErrorChange}
          />
        </div>
        <div className="mb-3 is-flex is-justify-content-center">
          <SmartSoftButton
            label="Save"
            classList={["smart-customer-primary-button"]}
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default Address;
