
import { Route, Routes } from "react-router-dom";
import { GetAMate, PurchaseCrops } from '../../pages/Customer';
import PrivateRoute from '../PrivateRoute';




const MoreRoute = () => {
  return (
    <Routes>
      <Route path="/get-a-mate" element={<PrivateRoute><GetAMate /></PrivateRoute> } />
      <Route path="/purchase-crops" element={<PurchaseCrops />} />
    </Routes>
  )
}

export default MoreRoute