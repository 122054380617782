// import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SmartSoftButton } from "soft_digi";
import SmartButton from "soft_digi/dist/forms/SmartButton";
import { CUSTOMER_OFFERS } from "../../../api/OfferUrls";
import { ORDER_ITEMS_URLS } from "../../../api/OrderUrls";
import SmartImageDisplay from "../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { decrypt_data } from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";
import "./CropCard.css";
import OrderCancellation from "./OrderCancellation";
import OrderReturn from "./OrderReturn";
import OrderUpload from "./OrderUpload";
import ProfileSideNav from "./ProfileSideNav";
import {
  changeDateTimeFormat,
  changeDateTimeZoneFormat,
} from "../../../services/core/CommonService";
const OrderInfo = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const [isOpen, setIsOpen] = useState(false);
  // const formattedDate = moment(data?.order_time).format("DD-MM-YYYY,h:mm:ss");
  const baseNav = "";
  const navigate = useNavigate();
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  // console.log("decrypted id = ",decrypted_id, " id = " , id);
  // const toggleFormOpen = () => {
  //   setIsOpen(!isOpen);
  // };
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id };
    const subscription = post(
      ORDER_ITEMS_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [decrypted_id]);

  const OrderNumber = () => {
    return (
      <>
        <div className=" m-0  px-2">
          <div
            key={"order-number-columns"}
            className="columns smart-order-number-view"
          >
            <div key={"order-one"} className="column is-6">
              <div className=" has-text-weight-bold">Order No</div>
              <div className="is-size-5">{data?.order_id}</div>
            </div>
            <div key={"order"} className="column is-6  ">
              <div className=" has-text-weight-bold">Order Date & Time</div>
              <div className="is-size-5">
                {changeDateTimeZoneFormat(data?.order_time)}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const Dellivery_Address = () => {
    return (
      <>
        <div className="columns">
          {data?.offer_mode === "DELIVERY" ? (
            <>
              <div className="column is-12 ">
                <div className="">
                  {/* Dispatch Address */}
                  <span className="is-size-5 has-text-weight-bold ">
                    Delivery Address
                  </span>
                  <div className="is-size-6 has-text-weight-normal my-1">
                    <p className="has-text-weight-medium">
                      {data?.addr_data.addr_one}
                    </p>
                    <p className=" has-text-weight-medium">
                      {data?.addr_data.addr_two}
                    </p>
                    <p className="has-text-weight-medium">
                      {data?.addr_data.city}
                    </p>
                    <div className="is-flex">
                      <p className=" has-text-weight-medium">
                        {data?.addr_data.statename}{" "}
                      </p>
                      {data?.addr_data.pincode && (
                        <p className="has-text-weight-medium ml-2">
                          - {data?.addr_data.pincode}
                        </p>
                      )}
                    </div>
                    {/* State */}
                    {/* Zip Code */}
                    {/* {data?.addr_data.pincode} */}
                    <br />
                  </div>
                  {/* <div className="columns">
                  <div className="column is-3">
                    State
                    <div className="has-text-weight-bold">
                      {data?.addr_data.statename}
                    </div>
                  </div>
                  <div className="column is-3">
                    Zip Code
                    <div className="has-text-weight-bold">
                      {data?.addr_data.pincode}
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </>
          ) : (
            " "
          )}
        </div>
      </>
    );
  };
  const ProductImage = () => {
    let url = CUSTOMER_OFFERS.GET_ONE_IMAGE_ID;
    let id = data?.sd_offers_id !== undefined ? data.sd_offers_id : 0;

    return (
      <>
        <div className="mt-4">
          <div className="is-size-5 has-text-weight-bold my-1">
            {data?.offer_title}
          </div>
          <div className="order-info-image">
            <div>
              <SmartImageDisplay srcType="URL" url={url} id={id} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ProductDecs = () => {
    const offer_title_disp = (data) => {
      let varient_data = data.variant_info;
      const parsedData = JSON.parse(varient_data);
      return (
        <>
          <p>
            {data.variant_info != null &&
              parsedData.map((item, index) => {
                return (
                  <span key={index}>
                    <b>{item.variant_group}</b> : {item.variant_name}
                    <br />
                  </span>
                );
              })}
          </p>
        </>
      );
    };

    return (
      <>
        <div className="">
          <div className="order-type ">
            <span className="is-size-5 has-text-weight-bold">Order Type</span>
          </div>
          <div className="is-size-6 has-text-weight-normal my-1">
            {data?.order_type}
          </div>
          <div className="columns is-multiline">
            {data?.order_type === "EARN" ? (
              <>
                <div className="column ">
                  <div className="offer-Price">
                    <span className="is-size-5 has-text-weight-bold">
                      Price{" "}
                      <span className="customer-secondary-font ">(A$) </span>
                    </span>
                    <div className="is-size-6 has-text-weight-normal my-1">
                      {data?.total_price}
                    </div>
                  </div>
                </div>
                <div className="column  ">
                  <div className="offer-Price">
                    <span className="is-size-5 has-text-weight-bold">
                      Earned
                    </span>
                    <div className="is-size-6 has-text-weight-normal my-1">
                      {data?.order_crops}
                    </div>
                  </div>
                  <div className="offer-Price">
                    <span className="is-size-5 has-text-weight-bold">
                      Variants
                    </span>
                    <div className="is-size-6 has-text-weight-normal my-1">
                      {offer_title_disp(data)}
                    </div>
                    {/* <div className="">
                      Customer Note:{""}{data?.custom_note}
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {data?.order_type !== "EARN" ? (
              <div className="column is-6">
                <div className="offer-Price">
                  <span className="is-size-5 has-text-weight-bold">
                    Redeemed
                  </span>
                  <div className="is-size-6 has-text-weight-normal my-1">
                    {data?.order_crops}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  };
  const ProductStatus = () => {
    return (
      <>
        <div className="">
          <div className="offer-Price">
            <span className="is-size-5 has-text-weight-bold">Status</span>
            <div className="is-size-6 has-text-weight-normal my-1">
              {data?.status_short}
            </div>
          </div>
          <span className="is-size-5 has-text-weight-bold">Quantity</span>
          <div className="is-size-6 has-text-weight-normal m-1">
            {data?.quantity}
          </div>
        </div>
      </>
    );
  };

  const trackerDisplay = () => {
    // console.log("maked ", data)
    return data.track.map((item, index) => {
      // const formattedDate = moment(item.last_modified_time).format('DD-MM-YYYY,h:mm:ss');
      // const formattedDateOnly = moment(item.last_modified_time).format(
      //   "DD-MM-YYYY  hh:mm"
      // );
      let ball_classes = [
        "column is-flex-mobile is-6 smart-track-ball-container",
      ];

      //ball_classes.push("smart-ball-is-active");
      let left_classes = [...ball_classes];
      left_classes.push("has-text-right");
      left_classes.push("smart-track-ball-container-left");
      if (item.status) left_classes.push("smart-ball-is-active");
      if (index === data.track.length - 1)
        left_classes.push("smart-track-cont-last");

      let right_classes = [...ball_classes];
      right_classes.push("has-text-left");

      return (
        <div
          className="columns smart-crop-tracker is-mobile is-multiline"
          key={index}
        >
          <div className={left_classes.join(" ")}>
            {item.last_modified_time &&
              changeDateTimeZoneFormat(item.last_modified_time, "DD-MM-YYYY")}
            <br />
            {item.last_modified_time &&
              changeDateTimeZoneFormat(item.last_modified_time, "H:mm")}
            {/* <div className="smart-track-ball"></div> */}
            <div
              className={
                item.status
                  ? "smart-track-ball TrackerFirstLineActive"
                  : "smart-track-ball  TrackerFirstLine"
              }
            ></div>
            {/* {index===data.track.length-1 && 
              <div className="smart-track-ball TrackerFirstLineActive smart-track-ball-last"></div>} */}
          </div>
          <div className={right_classes.join(" ")}>
            {item?.status_value} <br /> <small>{item.remarks}</small>
          </div>
        </div>
      );
    });
  };

  const Tracker = () => {
    //console.log("track " , data.track);
    return (
      <>
        <div className="smart-tracker-card p-4">
          {data && data.track && trackerDisplay()}
        </div>
      </>
    );
  };

  // const Total = () => {
  //   return (
  //     <>
  //       <div className="columns is-vertical is-centered">
  //         <div className="column is-4 is-offset-8 is-flex is-size-5">
  //           <div className="">
  //             {data?.quantity}
  //             <span className="mx-2">Item</span>
  //           </div>
  //           <div className="">Price A$: {data?.total_price}</div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const openNewForm = () => {
    let modelObject = {
      body: (
        <OrderCancellation
          id={data.ID}
          setLoading={setLoading}
          closeModal={closeModal}
          loadOrderData={loadTableData}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };
  const openNImageForm = (title) => {
    let modelObject = {
      title: title,
      body: (
        <OrderUpload
          data={data}
          setLoading={setLoading}
          closeModal={closeModal}
          loadOrderData={loadTableData}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };
  const openReturnNewForm = () => {
    let modelObject = {
      body: (
        <OrderReturn
          id={data.ID}
          setLoading={setLoading}
          closeModal={closeModal}
          loadOrderData={loadTableData}
        />
      ),
      modelClass: "customer-model-layout smart-modal-90",
      bodyClose: false,
    };
    openModal(modelObject);
  };

  const Buttons = () => {
    return (
      <>
        <div className="columns mt-3 is-multiline">
          {isOpen && (
            <div className="column is-4 is-offset-4">
              <OrderCancellation />
            </div>
          )}
          {data && parseInt(data.status) === 10 && (
            <div className="column has-text-right is-6">
              <SmartButton
                label="Cancel Order"
                classList={["smart-customer-primary-button  "]}
                onClick={() => openNewForm()}
              />
            </div>
          )}
          {data &&
            parseInt(data.status) === 25 &&
            parseInt(data.return_available) == 1 && (
              <div className="column is-12 has-text-centered">
                <SmartButton
                  label="Return Order"
                  classList={["smart-customer-primary-button "]}
                  // onClick={toggleFormOpen}
                  onClick={() => openReturnNewForm()}
                />
              </div>
            )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="columns smart-crop-order-info-view">
        {/* <div className="column is-2">
          <ProfileSideNav />
        </div> */}
        <div className="column is-12 customer-primary-font m-5">
          {/* <SmartSoftButton
            label="Go Back"
            classList={[" smart-customer-primary-button mt-3"]}
            onClick={() => navigateLink("profile/my-orders")}
          /> */}
          <div
            className="smart-customer-back has-text-right mr-6 is-hidden-mobile"
            onClick={() => navigateLink("profile/my-orders")}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            {""} Go Back
          </div>
          <div className="m-2 mt-5">
            <div className="columns is-multiline">
              <div className="column is-12">{OrderNumber()}</div>

              <div className="is-flex columns">
                <div className="column is-8 ml-6">{Dellivery_Address()}</div>
                <div className="column is-6">{ProductDecs()}</div>
                <div className="column is-6">{ProductStatus()}</div>
              </div>
            </div>
            {/* <div className="column is-3">{ProductImage()}</div> */}
            <div className="columns is-multiline">
              <div className="column is-6 p-6">
                {" "}
                <div className=" mb-6 ml-5 smart-crop-product-info-image">
                  {ProductImage()}
                </div>
                <div className="columns">
                  {data?.status === 30 || data?.status === 35 ? (
                    <div className="column image-icon-color mt-6">
                      <p className="has-text-weight-bold">Return Reason:</p>
                      <p>{data.cancel_reason}</p>
                      <p className="has-text-weight-bold">Return Remarks:</p>
                      <p>{data.cancel_remarks}</p>
                      <div
                        onClick={() => openNImageForm()}
                        className="Return-image-view"
                      >
                        <i
                          class="fa fa-hand-o-right has-text-success-dark image-icon-color"
                          aria-hidden="true"
                        ></i>
                        <u className="ml-2 has-text-success-blue">
                          Return Image
                        </u>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="column is-6 ">{Dellivery_Address()}</div> */}
              {/* <div className="column is-3">{ProductDecs()}</div> */}
              {/* <div className="column is-3">{ProductStatus()}</div> */}
              <div className="column is-4 p-3 pt-5 card">{Tracker()}</div>
              <div className="column is-12">{Buttons()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInfo;
