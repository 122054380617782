import 'bulma-checkradio/dist/css/bulma-checkradio.min.css';
import 'bulma-switch/dist/css/bulma-switch.min.css';
import 'bulma/css/bulma.min.css'; // Import Bulma CSS
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import ErrorBoundary from "./contexts/ErrorBoundary";
import { SiteProvider } from './contexts/SiteProvider';
import SiteRoute from './routing/SiteRoute';


function App() {
  useEffect(() => {
    // incrementTabCount();

    // // Decrement tab count on tab/window close
    // window.addEventListener('beforeunload', decrementTabCount);

    // return () => {
    //     window.removeEventListener('beforeunload', decrementTabCount);
    // };
}, []);


  return (
    <ErrorBoundary>
      <SiteProvider>
        <div className="App">
          <SiteRoute />
        </div>
      </SiteProvider>
    </ErrorBoundary>
  );
}

export default App;
