import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ORDER_URLS } from "../../../api/OrderUrls";
import SmartStripePayment from "../../../components/site/SmartStripePayment";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import {
  decrypt_data,
  getSessionStorage,
  setSessionStorage,
} from "../../../services/sessionService";
import { post } from "../../../services/smartApiService";

const PaymentOrder = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  //const { id } = useParams();

  const { setLoading, isLoading, refreshUser } = useSiteContext();
  const navigate = useNavigate();

  const getStoreId = () => {
    const loc = location.state || {};
    if (loc.id == undefined) {
      // get it from session storeage
      return getSessionStorage("transaction_id");
    } else {
      setSessionStorage("transaction_id", loc.id);
      return loc.id;
    }
  };
  const id = getStoreId();
  const decodeId = decodeURIComponent(id);
  const decrypted_id = decrypt_data(decodeId);
  //console.log("decrypted id = ",decrypted_id, " id = " , id);
  const loadTableData = () => {
    //setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id };
    const subscription = post(
      ORDER_URLS.GET_ONE,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, [decrypted_id]);

  const handleSuccess = (data) => {
    //console.log("success data " , data);
    setLoading(
      true,
      "Payment Completed... Verifying the payment... Please Wait...."
    );
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage);
      setLoading(false);
    };
    let post_data = { id: decrypted_id, client_secret: data };
    const subscription = post(
      ORDER_URLS.UPDATE,
      post_data,
      handleError
    ).subscribe((response) => {
      refreshUser();
      navigate("/");
      showAlertAutoClose("Order Placed Successfully", "success", 7000);
      //setData(response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const displayAmount = (totalPrice) => {
    return Math.round(totalPrice * 100) / 100;
  };

  const tenPercentOfDeliveryCost = data.delivery_cost;
  const totalWithTenPercent = data.delivery_cost + tenPercentOfDeliveryCost;

  

  const orderInfoDisplay = () => {
    return (
      <>
        <table className="table is-fullwidth">
          <tbody>
            {data?.order_total_base && data?.order_total_base > 0 ? (
              <tr>
                <td>Sub Total(A$)</td>
                <td>{data?.order_total_base}</td>
              </tr>
            ) : (
              ""
            )}
            <tr>
              <td>Delivery Charges(A$)</td>
              {/* <td>{data?.delivery_cost}</td> */}
              <td>{data?.delivery_cost}</td>
            </tr>
            {data?.order_gst && data?.order_gst > 0 ? (
              <tr>
                <td>GST(A$)</td>
                <td>{displayAmount(data?.order_gst)}</td>
              </tr>
            ) : (
              ""
            )}
            <tr>
              <td>Total Payable(A$)</td>
              {/* <td>{data?.order_total}</td> */}
              <td>{displayAmount(data?.order_total)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <div className="is-size-4 is-flex is-justify-content-center has-text-weight-bold mt-4">
          Order Payment
        </div>
        <div className="columns">
          <div className="column is-8">
            <div className="card p-5">
              {data && data.payment_client && (
                <SmartStripePayment
                  clientSecret={data?.payment_client}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  handleSuccess={handleSuccess}
                />
              )}
            </div>
          </div>
          <div className="column is-4">
            <div className="card">{orderInfoDisplay()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentOrder;
