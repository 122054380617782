import { useState } from "react";
import { Link } from "react-router-dom";
import { SmartSoftButton, SmartSoftForm, SmartValid } from "soft_digi";
import { ValidateFormNew } from "soft_digi/dist/services/smartValidationService";
import { BUSINESS_LOGIN_URLS } from "../../../api/Admin/AurhUrls";
import croplogo from "../../../assets/images/croplogo.png";
import { useSiteContext } from "../../../contexts/SiteProvider";
import {
  COLOR_BLUE,
  COLOR_ORAGE_LIGHT,
  COLOR_ORANGE,
  REGISTER_PIC,
} from "../../../services/ImageService";
import { ALLOW_NUMERIC } from "../../../services/PatternSerivce";
import {
  maskEmail,
  maskMobileNumber,
} from "../../../services/core/CommonService";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import CustomerLoginLayout from "../../../themes/CustomerLayout/CustomerLoginLayout";
import PrivacyAndDataRights from "../Help/ImportantNotifications/PrivacyAndDataRights";
import CustomerRegistration from "./CustomerRegistration";
import PrivacyAndDataRightsNew from "../Help/ImportantNotifications/PrivacyAndDataRightsNew";

const RegisterPage = () => {
  const [stage, setStage] = useState("FIRST");
  const [formData, setFormData] = useState({
    sign_up_type: "1",
    type: "CUSTOMER",
    country_code: "AU",
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const { setLoading, closeModal, openModal } = useSiteContext();

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "sign_up_type" || name === "country_code") {
      resetData();
    }
    //console.log(" name " ,name , "   value " , value)
  };
  const resetData = () => {
    handleInputChange("mobile_no", "");
    handleInputChange("email_id", "");
    handleInputChange("password", "");
    handleInputChange("confirm_password", "");
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const openNewForm = () => {
    let modelObject = {
      body: <PrivacyAndDataRightsNew header={false} closeModal={closeModal} />,
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const handleLogin = (e) => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, formElements)) {
      return false;
    }
    // console.log("errors ", formErrors);
    // if (ValidateFormNew(formData,formElements)) {
    //   showAlertAutoClose("Please Clear Errors", "error");
    //   return false;
    // }
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Sending OTP....Please Wait");
    let url = BUSINESS_LOGIN_URLS.SEND_OTP;
    // let data_in = { ...formData };
    // data_in=
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        handleInputChange("otp_id", response.data.otp_id);
        setStage("SECOND");
        setFormSubmit(false);
        showAlertAutoClose("OTP sent successfully", "success");
        // console.log("response " , response);
        // showNotification("success", "OTP sent successfully... Please Enter OTP");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const handleVerify = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, stage2_elements)) {
      return false;
    }
    const handleError = (errorMessage) => {
      handleInputChange("otp_value", "");
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Verifying OTP….Please Wait");
    // console.log("fdata ", formData);
    let url = BUSINESS_LOGIN_URLS.VERIFY_BUSINESS_DETAILS;
    const subscription = post(url, formData, handleError).subscribe(
      (response) => {
        handleInputChange("otp_id", response.otp_id);
        setStage("THIRD");
        showAlertAutoClose(" Verification Successful");
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const loginLogo = () => {
    return (
      <div>
        <figure className=" is-flex is-justify-content-center cropimagelogo mt-0">
          <img
            className="mt-0"
            src={croplogo}
            height="80"
            width="200"
            alt="Crop Logo"
          />
        </figure>
      </div>
    );
  };
  const loginTitle = () => {
    return (
      <div className="mt-1 mb-2 customer-primary-font">
        <p className="has-text-centered is-size-4  mb-2 customer-login-textcolor">
          Sign Up
        </p>
        <p className="has-text-centered ">
          It’s not just free its hassle free!
        </p>
      </div>
    );
  };

  const loginFormValidations = {
    email: [
      SmartValid.required("Email ID is Required"),
      SmartValid.email("Please Enter a Valid Email Address"),
    ],
    mobile_no: [SmartValid.required("Mobile Number is Required")],
    otp: [
      SmartValid.required("OTP is Required"),
      SmartValid.minLength("Minimum 6 Digit", 6),
    ],
    password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit",8),
      // SmartValid.maxLength("Maximum 15 Digit",15),
    ],
    cnf_password: [
      SmartValid.required("Password is Required"),
      // SmartValid.minLength("Minimum 8 Digit",8),
      // SmartValid.maxLength("Maximum 15 Digit",15),
      SmartValid.custom((value) => {
        return formData.password !== value
          ? "Password & Confirm Password are not Same"
          : "";
      }),
    ],
    mobile_no: [SmartValid.required("Mobile Number is Required")],
  };

  const labelTest = () => {
    return (
      <div className="column   is-size-6 has-text-centered sign-up-privacy-text">
        By clicking 'Continue' you agree to CROP
        <label onClick={() => openNewForm()}
         
            to="/help/privacy-data-rights-new"
            className=" ml-1 smart-crop-trems-conditoion-color smart-cursor-pointer"
          >
            Privacy Policy
        
        </label>
        <br />
        and confirm that you are over 18 years of age.
      </div>
    );
  };

  const options = [
    { value: "1", label: "Mobile" },
    { value: "2", label: "Email" },
  ];

  const formElements = [
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "sign_up_type",
      element: {
        options: options,
        isMulti: true,
        classList: ["has-text-weight-medium"],
        type: "radio",
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "mobile_no",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        pattern: ALLOW_NUMERIC,
        inputType: "BORDER_LABEL_FOCUS",
        //validations:numericValidations
        // label: "Enter Mobile Number",
        //   inputType: "BORDER_LABEL_FOCUS",
        validations: loginFormValidations.mobile_no,
        value: formData?.mobile_no || "",
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        isRequired: true,
        // placeHolder: "Enter Mobile Number",
        label: "Enter Mobile Number",
        rightIcon: "fa-mobile",
         autoComplete:"new-password"

        // validations:loginFormValidations.mobile_no,
        // validations:formData?.sign_up_type == "1" ? loginFormValidations.mobile_no : {},
      },
      hideFunction: (data) => {
        return formData?.sign_up_type !== "1" ? true : false;
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "email_id",
      element: {
        // placeHolder: "Enter EMAIL ID",
        rightIcon: "fa fa-envelope-o",
        isRequired: true,
        label: "Enter Email ID",
        inputType: "BORDER_LABEL_FOCUS",
        validations: loginFormValidations.email,
         autoComplete:"new-password"
      },
      hideFunction: (data) => {
        return formData?.sign_up_type === "1" ? true : false;

        // return formData?.sign_up_type === "2" ? true : false;
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "password",
      element: {
        // placeHolder: "Enter Password",
        label: "Enter Password",
        inputType: "BORDER_LABEL_FOCUS",
        validations: loginFormValidations.password,
        passwordValidator: true,
        isRequired: true,
         autoComplete:"new-password"
      },
    },
    {
      type: "PASSWORD",
      width: "12",
      name: "confirm_password",
      element: {
        // placeHolder: "Enter Confirm Password",
        label: "Confirm Password",
        inputType: "BORDER_LABEL_FOCUS",
        isRequired: true,
        validations: loginFormValidations.cnf_password,
        passwordValidator: true,
         autoComplete:"new-password"
      },
    },
    {
      type: "LABEL",
      width: "12",
      labelFunction: labelTest,
    },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Continue",
        classList: ["customer-login-action-button"],
        onClick: () => handleLogin(),
      },
    },
  ];

  const formDisplay = () => {
    return (
      <div className="register-page-business-login-form ">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={formElements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
      </div>
    );
  };

  const loginSignUp = () => {
    return (
      <div>
        <p className="has-text-centered mt-4 mb-5 member-text">
          Already a Member?
          <Link to="/login" className="">
            {" "}
            Login
          </Link>
        </p>
      </div>
    );
  };

  const colorshaderounded = () => {
    return (
      <>
        <div className="customer-login-colorshade is-hidden-mobile">
          <span className="customer-login-colororange">
            <img src={COLOR_ORANGE} alt=""></img>
          </span>
          <span className="customer-login-colorblue">
            <img src={COLOR_BLUE} alt=""></img>
          </span>
          <span className="customer-login-colororangelight">
            <img src={COLOR_ORAGE_LIGHT} alt=""></img>
          </span>
        </div>
      </>
    );
  };

  const stage1_forms = () => {
    return (
      <>
        {loginTitle()}
        {formDisplay()}
        {loginSignUp()}
      </>
    );
  };

  const getPlaceHolder = () => {
    return formData?.sign_up_type === "2"
      ? maskEmail(formData.email_id ?? "")
      : maskMobileNumber(formData.mobile_no ?? "");
  };

  const stage2_elements = [
    {
      type: "TEXT_BOX",
      width: "12",
      name: "euserid",
      element: {
        // rightIcon: "fa-envelope-o",
        inputProps: { disabled: true },
        placeHolder: getPlaceHolder(),
        rightIcon:
          formData?.sign_up_type === "2" ? "fa-envelope-o" : "fa fa-mobile",
        // validations:loginFormValidations.email,
      },
    },
    {
      type: "OTP",
      width: "12",
      name: "otp_value",
      element: {
        //  placeHolder: "6 Digit Otp",
        digits: 6,
        rightIcon: "fa-envelope-o",
        inputType: "BORDER_LABEL",
        validations: loginFormValidations.otp,
        // label: "OTP",
        pattern: ALLOW_NUMERIC,
        max: 6,
         autoComplete:"new-password",
        resendOtpFunction: (callback) => {
          handleLogin(null);
          callback();
        },
        otpButtonText: (status, timer) => {
          return status ? "Resend Code (" + timer + " sec)" : "Resend Code";
        },
      },
    },
  ];
  const verification_form = () => {
    return (
      <div className="crop-business-login-form crop-business-form">
        <SmartSoftForm
          formData={formData}
          setFormData={handleInputChange}
          elements={stage2_elements}
          formSubmit={formSubmit}
          handleErrorChange={handleErrorChange}
        />
        <div className="">
          <SmartSoftButton
            label="Verify"
            classList={["is-rounded", "customer-login-action-button"]}
            onClick={() => handleVerify()}
            //  disabled={!isEmptyObject(formErrors)}
          />
        </div>
      </div>
    );
  };

  const stage2_forms = () => {
    return (
      <>
        <div className="mt-5 mb-6">
          <p className="has-text-centered is-size-4 customer-login-textcolor mt-1 mb-3">
            Sign Up
          </p>
          <p className="has-text-centered mb-2 crop-login-desc">
            <span>
              {" "}
              We have sent you a one time verification code on your{" "}
              {formData?.sign_up_type === "2" ? "Email" : "Mobile"}.{" "}
            </span>
            <div>Please enter the code to authenticate and continue.</div>
          </p>
        </div>
        {verification_form()}
        {loginSignUp()}
      </>
    );
  };

  const first_second_form = () => {
    return (
      <div>
        {stage === "FIRST" && stage1_forms()}
        {stage === "SECOND" && stage2_forms()}
      </div>
    );
  };

  const third_form = () => {
    // return (
    //   <div className=" crop-business-registration-box">
    //     <div className="crop-login-form-main">
    //       {/* {loginLogo()} */}
    //       <CustomerRegistration dataIn={formData} />
    //     </div>
    //     <div className="crop-business-login-top-box"></div>
    //     <div className="crop-business-login-bottom-box"></div>
    //   </div>
    // );
    return (
      <>
        <p className="has-text-centered is-size-4   customer-login-textcolor">
          Create Account
        </p>
        <CustomerRegistration dataIn={formData} />
      </>
    );
  };

  // return (
  //   <div className="Login m-0">
  //     <div className="m-0">
  //       <div className="columns is-flex card Customer-Login-register-contentpage  ">
  //         <div className="column is-6 is-hidden-mobile">
  //           <div className="Customer-Login-said-image crop-image-logo ">
  //             <img src={REGISTER_PIC} alt=""></img>
  //           </div>
  //         </div>

  //         <div className="column customer-login-formmain  card is-6">
  //           <span>{loginLogo()}</span>
  //           <div className=" is-hidden-tablet smart-mobile-view-image has-text-centered my-2 mt-3">
  //             <img src={REGISTER_PIC} alt=""></img>
  //           </div>
  //           {(stage === "FIRST" || stage === "SECOND") && first_second_form()}
  //           {stage === "THIRD" && third_form()}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <CustomerLoginLayout sideImage={REGISTER_PIC} tabImage={REGISTER_PIC}>
      {(stage === "FIRST" || stage === "SECOND") && first_second_form()}
      {stage === "THIRD" && third_form()}
    </CustomerLoginLayout>
  );
};

export default RegisterPage;
