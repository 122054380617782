import React, { useState, useEffect } from "react";
import "./ProductVariens.css";

const ProductVariens = ({
  data_in,
  setSelectedVariants,
  setItemData,
  setCustomNote,
  customNote,
}) => {
  const [data, setData] = useState(null);
  const [subItem, setSubItem] = useState([]);
  const [selectedChildren, setSelectedChildren] = useState({});

  const handleChildSelect = (parentId, childId, price) => {
    setSelectedChildren((prevSelected) => ({
      ...prevSelected,
      [parentId]: prevSelected[parentId] === childId ? null : childId,
    }));
    //console.log("price ", price);
    // if (price && price > 0) {
    //   //console.log("price inside ", price);
    //   setItemData((prevSelected) => ({
    //     ...prevSelected,
    //     price: price,
    //   }));
    // }
  };

  useEffect(() => {
    if (data_in[0] && data_in[0].check!=0) {
      setData(data_in[0]);
    }
  }, [data_in]);

  // update the total item price
  const updateTotalPrice = () => {
    let total_price = 0;
    for (let key in selectedChildren) {
      if (selectedChildren.hasOwnProperty(key)) {
        let children = data_in.find((item) => item.index == key);
        // console.log("children ", children);
        if (children) {
          let cost = children.children.find(
            (item) => item.id == selectedChildren[key]
          );
          if (cost) {
            total_price = total_price + parseFloat(cost.price);
          }
          //console.log("cost  ", cost);
        }
        //console.log(`${key}: ${obj[key]}`);
      }
    }
    setItemData((prevSelected) => ({
      ...prevSelected,
      price: total_price,
    }));
    //console.log("total price ", total_price);
    //return total_price;
  };

  useEffect(() => {
    setSelectedVariants(selectedChildren);
    //console.log("selected childs ", selectedChildren);
    updateTotalPrice();
    //console.log("selected childs ", selectedChildren, data_in);
  }, [selectedChildren]);

  // const AllSize = [
  //   { size: "XS  S  M  L  XL " },
  //   { color: "RED  BLUE  YELLOW  WHITE" },
  //   { texture: "BAD  GOOD" },
  //   { brand: "NIKE  ADIDAS" },
  // ];
  //console.log("variatns ", data_in);
  return (
    <>
      <div className="smart-product-variants">
        <div className="is-small ">
          <ul className="ProductVariens-horizontal-list ">
            {data_in.map((item) => {
              return item.check!=0 && (
                <li className={data?.index === item.index ? "is-active" : ""}>
                  <a onClick={() => setData(item)}>
                    <span>{item.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="smart-variants-display columns is-multiline">
          {data &&
            data.children.map((item) => {
              return item.check==1 && (
                <div
                  className="column has-text-weight-semibold is-narrow m-0 px-2 py-1"
                  onClick={() =>
                    handleChildSelect(data.index, item.id, item.price)
                  }
                >
                  <span
                    className={
                      selectedChildren[data.index] === item.id
                        ? "is-active"
                        : ""
                    }
                  >
                    {item.name}
                  </span>
                </div>
              );
            })}
        </div>
        <div>
          {/* <p>Custom Notes:</p> */}
          <textarea
            className="textarea custom-notes-text-box "
            value={customNote}
            placeholder="Add Custom Note..."
            onChange={(event) => setCustomNote(event.target.value)}
            rows={1}
            maxLength="90"
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default ProductVariens;
