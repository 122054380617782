import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CustomerRoute from "./Customer/CustomerRoute";
const SiteRoute = () => {
  return (
    <>
      <Router>
         <CustomerRoute />
      </Router>
      <ToastContainer />
    </>
  );
};

export default SiteRoute;
