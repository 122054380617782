import { useNavigate,useLayoutEffect } from "react-router-dom";
import { useEffect, useState } from "react";
import { SmartSoftButton } from "soft_digi";
import ImageCarousel from "../../../components/site/ImageCarousel";
import { useSiteContext } from "../../../contexts/SiteProvider";
import {
  CROP_CUSTOMER_HOME_STAR,
  CUSTOMER_HOME_IMAGE,
} from "../../../services/ImageService";
import EarnCropsView from "./EarnCrops/EarnCropsView";
import HomePageImage from "./HomePageImage";
import HomePageSlider from "./HomePageSlider";
import PromosView from "./Promos/PromosView";
import AppBarCode from "./RedeemCrops/AppBarCode";
import RedeemCropsView from "./RedeemCrops/RedeemCropsView";
import { getSessionStorage,setSessionStorage } from "../../../services/sessionService";

const HomePage = () => {
  const { user } = useSiteContext();
  const navigate = useNavigate();  
  const [scrollPosition, setScrollPosition] = useState(0);  
  const baseNav = "";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };
  const isLoggedIn = () => {
    //return true;
    return user && user.euserid ? true : false;
  };

  const handleClick = () => {
   // console.log("saved position " , scrollPosition);
    setSessionStorage("crop-home-scroll-position", scrollPosition);
  };

  useEffect(() => {
    const savedPosition = getSessionStorage("crop-home-scroll-position");
    if (savedPosition) {
     // console.log("load position " , savedPosition);
      requestAnimationFrame(() => {
       // window.scrollTo(0, parseInt(savedPosition, 10));
       window.scrollTo(0,100);
      });
      // setTimeout(() => {
      //   window.scrollTo(0, parseInt(savedPosition,10) - 300);
      // // window.scrollTo(0, 1400);
       
      // }, 0); // Delay to ensure component is fully rendered
    
      sessionStorage.removeItem("crop-home-scroll-position");
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const imageSlider=()=>{
    return (
      <div style={{position:"relative"}}>
      <div className="image is-24x24 is-hidden-mobile">
        <img className="home-stat-image" src={CROP_CUSTOMER_HOME_STAR} alt="Star" />
      </div>
      <div className="columns p-0 m-0 is-flex-mobile">
        <div className="column is-6">
          <div className="smart-customer-home-page-details">
            <div>
              <div className="home-accumulation-text">ACCUMULATION</div>
              <div className="smart-customer-home-text ">
                <div> Every dollar counts,</div>
                <div> earn CROP for all</div>
                <div> purchase</div>
              </div>
              {!isLoggedIn()&&(
                <>
                   <SmartSoftButton
                label="Register Now"
                classList={["smart-customer-primary-button", "mt-3"]}
                rightIcon="fa fa-arrow-circle-right"
                onClick={() => navigateLink("register")}
              />
                </>
              )}
           
            </div>
          </div>
        </div>
        <div className="column is-6 ">
          <div className="customer-home-side-images ">
            <div className=" customer-home-right-image">
              <img src={CUSTOMER_HOME_IMAGE} alt="" />
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }

  const imageSlider_two=()=>{
    return (
      <div style={{position:"relative"}}>
      <div className="image is-24x24 is-hidden-mobile">
        <img className="home-stat-image" src={CROP_CUSTOMER_HOME_STAR} alt="Star" />
      </div>
      <div className="columns p-0 m-0 is-flex-mobile">
        <div className="column is-6">
          <div className="smart-customer-home-page-details">
            <div>
              <div className="home-accumulation-text">ACCUMULATION</div>
              <div className="smart-customer-home-text ">
                <div> Every dollar counts,</div>
                <div> earn CROP for all</div>
                <div> purchase</div>
              </div>
              {!isLoggedIn()&&(
                <>
                   <SmartSoftButton
                label="Register Now"
                classList={["smart-customer-primary-button", "mt-3"]}
                rightIcon="fa fa-arrow-circle-right"
                onClick={() => navigateLink("register")}
              />
                </>
              )}
           
            </div>
          </div>
        </div>
        <div className="column is-6 ">
          <div className="customer-home-side-images ">
            <div className=" customer-home-right-image">
              <img src={CUSTOMER_HOME_IMAGE} alt="" />
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }


  return (
    <>
      <div className="smart-customer-home-page p-0 m-0">
        {/* <HomeHeader /> */}
        <HomePageSlider isLoggedIn={isLoggedIn} navigateLink={navigateLink} />
        <div className="customer-home-page-images ">
          <HomePageImage />
        </div>
      </div>

      <div className="m-0 p-0">
        <EarnCropsView homePage={true} handleClick={handleClick}/>
      </div>
      <div>
        <PromosView />
      </div>
      <div>
        <RedeemCropsView homePage={true} handleClick={handleClick} />
      </div>
      <div>
        <AppBarCode />
      </div>
      <ImageCarousel />
    </>
  );
};

export default HomePage;
