import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import { useSiteContext } from "../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";
import AccountNotification from "./AccountNotification";
import GeneralNotification from "./GeneralNotification";
import PurchaseNotification from "./PurchaseNotification";
import RequestNotification from "./RequestNotification";
// import { useParams } from 'react-router-dom';

const NotificationView = () => {
  const [data, setData] = useState([]);
  const { setLoading } = useSiteContext();
  const { type } = useParams();

  const loadTableData = () => {
    // setLoading(true, "Loading in Please Wait....");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setData([]);
      setLoading(false);
    };
    let post_data = {
      notification_type: type,
      status:5
    };
    const subscription = post(
      SERVICES_NOTIFICATION_URLS.GET_ALL,
      post_data,
      handleError
    ).subscribe((response) => {
      setData(response.data);
      // setLoading(false);
      // console.log("notification data",data)
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const display_notification = () => {
    // console.log("data in", data);
    switch (type) {
      case "ACCOUNT":
        return <AccountNotification NotificationData={data?.data} pats={data?.pats} />       
      case "GENERAL":
       return <GeneralNotification NotificationData={data?.data} pats={data?.pats} />    
      case "PURCHASE":
        return <PurchaseNotification NotificationData={data?.data} pats={data?.pats} />      
      case "REQUEST":
        return <RequestNotification NotificationData={data?.data} pats={data?.pats} />      
      default:
        break;
    }
  };

  useEffect(() => {
    loadTableData();
  }, [type]);

  return data &&  display_notification();
};

export default NotificationView;
