import { useEffect, useState } from "react";
import { SmartSoftForm } from "soft_digi";

import { useNavigate } from "react-router-dom";
import SmartCheckRadio from "soft_digi/dist/forms/SmartCheckRadio";
import {
  SmartValid,
  ValidateFormNew,
} from "soft_digi/dist/services/smartValidationService";
import { USER_URLS } from "../../../api/Admin/AurhUrls";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { ALLOW_NUMERIC, ALPHA_NUMERIC_CAPITAL } from "../../../services/PatternSerivce";
import { showAlertAutoClose } from "../../../services/notifyService";
import { post } from "../../../services/smartApiService";
import TermAndConditionNew from "../Help/ImportantNotifications/TermAndConditionNew";

const CustomerRegistration = ({ dataIn }) => {
  const [formData, setFormData] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [maximumDate, setMaximumDate] = useState(new Date());
  const navigate = useNavigate();
  const { setLoading ,openModal,closeModal } = useSiteContext();

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if(name=='age_group'){
      getAgeGroup(value)
    }
  };

  const openNewForm = () => {
    let modelObject = {
      body: <TermAndConditionNew header={false} closeModal={closeModal} />,
      modalClass: "crop-customer-modal smart-modal-80",
      bodyClose: true,
    };
    openModal(modelObject);
  };

  const loadSelectOptions = () => {
    // console.log("input data ", dataIn);
    setFormData(dataIn || {});
  };

  useEffect(() => {
    loadSelectOptions();
    const currentDate = new Date();
    const newDate = new Date(maximumDate);
    newDate.setFullYear(currentDate.getFullYear() - 13);
    setMaximumDate(newDate);
    console.log(maximumDate);
  }, []);

  useEffect(() => {
    let age_month = formData?.age_month?.value ? formData?.age_month?.value : "";
    let age_date = formData?.age_date?.value ? formData.age_date?.value : "";
    let age_final = "2024-" + age_month + "-" + age_date;
    setFormData((prev) => ({ ...prev, age: age_final }));
    console.log("age final ", age_final);
  }, [formData?.age_month, formData?.age_date]);

  const getAgeGroup=(value)=>{
    const currentDate = new Date();
    const newDate = new Date(maximumDate);
    newDate?.setFullYear(currentDate?.getFullYear() - value?.value);
    setMaximumDate(newDate);
  }

  const handleFormSubmit = () => {
    setFormSubmit(true);
    if (!ValidateFormNew(formData, RegisterFormElements)) {
      return false;
    }

    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    // setLoading(true, "Registration Details are Processing....Please Wait");
    setLoading(true, "Account Creation in Progress....Please Wait")
    let url = USER_URLS.INSERT_CUSTOMER;

    formData.promo_code_status = formData.promo_code_status == 1 ? "Yes" : "No";
    const subscription = post(url, {...formData, user_type:"CUSTOMER"}, handleError).subscribe(
      (response) => {
        setFormSubmit(false);
        showAlertAutoClose(response?.data.msg, "success", 6000);
        setLoading(false);
        navigate("/login");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  // const numericValidations = [];

  const terms_conditions = () => {
    return (
      // <div className="is-12">
      <div className="column pl-3 p-0  is-flex is-12">
        {/* <div className="columns ">
            <div className="column  p-0  is-12"> */}
        <SmartCheckRadio
          name="terms"
          value={formData?.terms}
          options={[{ value: "1", label: "" }]}
          onChange={(value) => handleInputChange("terms", value)}
          validations={RegistrationValidations.first_name}
        />
        <span className="has-text-danger">*</span>I acknowledge and agree to
        CROP
        {/* <Link to="/help/terms-conditions"> */}
          <u onClick={()=>openNewForm()} className=" has-text-info-dark ml-1">Terms & Conditions?</u>
        {/* </Link> */}
      </div>
      //   </div>
      // </div>
      // </div>
    );
  };

  const age_group_options = [
    // { value: "13", label: "13-18 Years" },
    { value: "19", label: "18-25 Years" },
    { value: "26", label: "26-35 Years" },
    { value: "36", label: "36-45 Years" },
    { value: "46", label: "46-55 Years" },
    { value: "55", label: "over 55 Years" },
  ];
  const months = [
    { label: "Jan", value: "01" },
    { label: "Feb", value: "02" },
    { label: "Mar", value: "03" },
    { label: "Apr", value: "04" },
    { label: "May", value: "05" },
    { label: "Jun", value: "06" },
    { label: "Jul", value: "07" },
    { label: "Aug", value: "08" },
    { label: "Sep", value: "09" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" }
  ];


  const days = [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" }
  ];
  const promo_options = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];
  const RegistrationValidations = {
    email_id: [
      SmartValid.required("Email is Required"),
      SmartValid.email("Please enter a valid Email address"),
    ],
    first_name: [
      SmartValid.required("First Name is Required"),
      SmartValid.pattern("Invalid First Name",/^[a-zA-Z\s'-]+$/)
    ],
    middle_name:[
      SmartValid.pattern("Invalid Middle Name",/^[a-zA-Z\s'-]+$/)
    ],
    last_name: [
      SmartValid.required("Last Name is Required"),
      SmartValid.pattern("Invalid First Name",/^[a-zA-Z\s'-]+$/)
    ],
    age_group: [SmartValid.required("Age Group is Required")],
    age: [SmartValid.required("Date of Birth is Required")],
    age_month: [SmartValid.required("Month is Required")],
    age_date: [SmartValid.required("Date is Required")],
    mobile: [SmartValid.required("Mobile Number is Required")],
    promo_code_status: [SmartValid.required("Please Click Yes or No")],
    promo_code: [SmartValid.required("Promo Code is Required"),
    // SmartValid.minLength("Minimum 6 Digit", 6),
    // SmartValid.maxLength("Maximum 8 Digit", 8),
  ],
  };

  const RegisterFormElements = [
    {
      type: "TEXT_BOX",
      width: "6",
      name: "first_name",
      element: {
        label: "First Name",
        placeHolder: "Enter First Name",
        // validations: numericValidations,
        validations: RegistrationValidations.first_name,
        inputType: "BORDER_LABEL",
        isRequired: true,
        pattern: "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$",

        // pattern: VALID_USER_NAME ,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "middle_name",
      element: {
        label: "Middle Name",
        // validations: numericValidations,
        placeHolder: "Enter Middle Name",
        inputType: "BORDER_LABEL",
        pattern: "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$",
        // validations: RegistrationValidations.middle_name,
        // pattern: VALID_USER_NAME ,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "last_name",
      element: {
        label: "Last Name",
        placeHolder: "Enter Last Name",
        // validations: numericValidations,
        validations: RegistrationValidations.last_name,
        inputType: "BORDER_LABEL",
        isRequired: true,
        pattern: "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$",
        // pattern: VALID_USER_NAME ,
      },
    },
    {
      type: "TEXT_BOX",
      width: "6",
      name: "email_id",
      element: {
        label: "Email",
        placeHolder: "Enter Email",
        // validations: numericValidations,
        validations: RegistrationValidations.email_id,
        inputType: "BORDER_LABEL",
        isRequired: true,
        inputProps: {
          disabled: formData?.sign_up_type === "1" ? false : true,
        },
      },
    },
    {
      type: "MOBILE",
      width: "12",
      name: "mobile_no",
      codeName: "country_code",
      classList: "has-text-black",
      element: {
        value: formData?.mobile_no || "",
        pattern: ALLOW_NUMERIC,
        codeSelected: formData?.country_code,
        countries: ["AU", "IN"],
        label: "Enter Mobile Number",
        // placeHolder: "Enter Mobile Number",
        inputType: "BORDER_LABEL",
        isRequired: true,
        rightIcon: "fa-mobile",
        validations: RegistrationValidations.mobile,
        inputProps: {
          disabled: formData?.sign_up_type === "2" ? false : true,
        },
      },
    },
    {
      type: "SELECT_BOX",
      width: "6",
      name: "age_group",
      element: {
        label: "Select Age Group",
        // inputType: "BORDER_LABEL",
        validations: RegistrationValidations.age_group,
        isRequired: true,
        options: age_group_options,
      },
    },
    {
      type: "SELECT_BOX",
      width: "3",
      name: "age_date",
      element: {
        label: "Select DOB",
        
        // label: "Select Age",
        placeHolder:"Date",
        // inputType: "BORDER_LABEL",
        validations: RegistrationValidations.age_date,
        isRequired: true,
        options: days,
      },
    },
    {
      type: "SELECT_BOX",
      width: "3",
      name: "age_month",
      element: {
        label: <span className="display_none"> test</span>,
        placeHolder:" Month",
        // inputType: "BORDER_LABEL",
        validations: RegistrationValidations.age_month,
        // isRequired: true,
        options: months,
      },
    },
    // {
    //   type: "DATE",
    //   width: "6",
    //   name: "age",
    //   element: {
    //     label: "Date of Birth (DD/MM)",
    //     // validations: numericValidations,
    //     validations: RegistrationValidations.age,
    //     inputType: "BORDER_LABEL",
    //     isRequired: true,
    //     dateFormat: "dd/MM",
    //     maxDate: maximumDate,
    //     //readOnly:true
    //   },
    // },
    {
      type: "CHECK_RADIO",
      width: "12",
      name: "promo_code_status",
      element: {
        label: "Do you have a promo code?",
        // isRequired: true,
        options: promo_options,
        type: "radio",
        isRequired: true,
        validations: RegistrationValidations.promo_code_status,
        isMulti: true,
        isHorizontal: true,
      },
    },
    {
      type: "TEXT_BOX",
      width: "12",
      name: "promo_code",
      element: {
        // pattern: VALID_USER_NAME ,
        label: "Promo Code",
        placeHolder: "Enter Promo Code",
        inputType: "BORDER_LABEL",
        isRequired: true,
        validations: RegistrationValidations.promo_code,
        // min:6,
        max: 6,
        pattern: ALPHA_NUMERIC_CAPITAL,
      },
      hideFunction: () => {
        let promo_code =
          formData?.promo_code_status !== undefined
            ? parseInt(formData.promo_code_status)
            : 0;
        return promo_code === 1 ? false : true;
      },
    },
    {
      type: "CHECK_RADIO",
      width: 12,
      name: "terms_new",
      element: {
        classList: ["terms_accept_checkbox"],
        label: (
          <p>
            <span className="smart-error">*</span> I acknowledge and agree to
            CROP
            <label
              onClick={() => openNewForm()}
              className=" smart-cursor-pointer smart-crop-trems-conditoion-color ml-1"
            >
              Terms & Conditions{" "}
            </label>
          </p>
        ),
        options: [{ value: "1", label: "" }],
        validations: [SmartValid.required("Required")],
        //isRight: true,
      },
    },
    // {
    //   type: "LABEL",
    //   labelFunction: terms_conditions,
    // },
    {
      type: "BUTTON",
      width: "12",
      element: {
        label: "Create",
        classList: ["is-rounded", "customer-login-action-button"],
        onClick: () => handleFormSubmit(),
      },
    },
  ];

  const PersonalInfo = () => {
    return (
      <>
        <div className="mt-6 mb-2 p-1">
          <div className="columns is-multiline crop-business-form">
            <div className="column p-0">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={RegisterFormElements}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>

            {/* <SmartMobileNumber
              placeHolder="Mobile Number"
              rightIcon="fa-mobile"
              name="mobile_no"
              value={formData.mobile_no}
              pattern={ALLOW_NUMERIC}
              handleInputChange={handleInputChange}
              inputProps={{
                disabled: formData?.sign_up_type === "2" ? false : true,
              }}
            />

            <div className="column is-12">
              <SmartSoftForm
                formData={formData}
                setFormData={handleInputChange}
                elements={secForm}
                formSubmit={formSubmit}
                handleErrorChange={handleErrorChange}
              />
            </div>
            <div className="column is-12">
              <div className="columns">
                <div className="column is-1">
                  <SmartCheckRadio
                    name="terms"
                    value={formData?.terms}
                    options={[{ value: "1", label: "" }]}
                    onChange={(value) => handleInputChange("terms", value)}
                  />
                </div>
                <div className="column is-11">
                  I acknowledge and agree to CROP’s{" "}
                  <Link to="/help/terms-conditions">
                    <span className=" has-text-info-dark ml-4">
                      Terms & Conditions ?
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className=" column is-12   is-centered">
              <SmartSoftButton
                label="Finish Registration"
                classList={["is-rounded", "customer-login-action-button"]}
                onClick={() => handleFormSubmit()}
                //   disabled={!isEmptyObject(formErrors)}
              />
            </div> */}
          </div>
        </div>
      </>
    );
  };

  return <>{PersonalInfo()}</>;
};

export default CustomerRegistration;
