const CUSTOMER_USER_URLS = {
    INSERT_BUSINESS:"user/insert_business",
    GET_ONE_PROFILE:"user/get_one_profile",
    GET_ALL_ADMIN:"user/get_all",
    UPDATE_STATUS:"user/update",
    CHANGE_PASSWORD:"/user/change_password",
    UPDATE_BUSINESS_PROFILE:"/user/update_bus_profile",
    GET_ALL_ADDRESS:"/user/get_all_select_addr"
}

const SERVICE_URLS={
    INSERT:"services/user_site/insert",
    GET_ONE:"services/user_site/get_one"
}
export {
    CUSTOMER_USER_URLS,
    SERVICE_URLS
}