

import { Route, Routes } from "react-router-dom";
import { Address, CheckOut, Invoice, ShoppingCart, ViewCart, WhishListTable } from '../../pages/Customer';
import InvoiceOrder from "../../pages/Customer/WhishList/InvoiceOrder";
import PaymentOrder from "../../pages/Customer/WhishList/PaymentOrder";
const WhishList = () => {
  return (
    <>
    <Routes>
      <Route path="/whish-list" element={<WhishListTable />} />
      <Route path="/shopping-cart" element={<ShoppingCart />} />
      <Route path="/view-cart" element={<ViewCart />} />      
      <Route path="/checkout/:type" element={<CheckOut />} /> 
      <Route path="/checkout/:type/:order" element={<CheckOut />} />      
      <Route path="/address" element={<Address />} />
      <Route path="/payment" element={<PaymentOrder />}/>
      <Route path="/payment/:id" element={<PaymentOrder />} />     
      <Route path="/invoice/:id" element={<InvoiceOrder />} />
      <Route path="/invoicenew" element={<Invoice />} />
    </Routes>
    </>
  )
}

export default WhishList