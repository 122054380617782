// LoginLayout.js
// import React, { useState } from 'react';
// import { useSiteContext } from '../../contexts/SiteProvider';
import { useEffect, useRef, useState } from "react";
import { SERVICES_NOTIFICATION_URLS } from "../../api/Services/ServiceUrls";
import SmartAccordion from "../../components/site/FormElements/SmartAccordion";
import { useSiteContext } from "../../contexts/SiteProvider";
import "./CustomerLayoutCss.css";
import SingleNotification from "./SingleNotification";

import { showAlertAutoClose } from "../../services/notifyService";
import { post } from "../../services/smartApiService";

const CustomerNotification = ({ noteData, loadProfileData }) => {
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  const { setLoading, refreshUser, user } = useSiteContext();
//  console.log("item ", noteData);
  const handleFormSubmit = () => {
    //console.log("formdata", formData);
    // setFormSubmit(true);
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      // showNotification("error", errorMessage);
      setLoading(false);
    };
    setLoading(true, "Updating....Please Wait");
    let url = SERVICES_NOTIFICATION_URLS.MARK_ALL_READ;
    let postData = { status: 5 };
    const subscription = post(url, postData, handleError).subscribe(
      (response) => {
        showAlertAutoClose(response.data.msg, "success");
        setLoading(false);
        setActive(false);
        //loadProfileData();
        refreshUser();
        // navigate("/");
      }
    );
    return () => {
      subscription.unsubscribe();
    };
    //console.log("form errors ", formErrors);
  };

  useEffect(() => {
    console.log(" use effect is getting called two times " );
    const handleClickOutside = (event) => {
      //  console.log("evenet resgistered " , ref.current , " event ", event.target );
      if (ref.current && !ref.current.contains(event.target)) {
        // console.log("event out");
        setActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
   
  }, []);

  const card_body = (index) => {
    console.log("this is called " , index);
    return <SingleNotification type={index} setActive={setActive} />;
  };

  const getSingleCount = (index) => {
    let item = noteData && noteData.notes ? noteData.notes : [];
    let singleNote = item.find((item) => item.notification_type == index);
    return singleNote && singleNote.count > 0 ? true : false;
  };

  const AccountTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            Account Notification
            {getSingleCount("ACCOUNT") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };
  const GeneralTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            General Notification
            {getSingleCount("GENERAL") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };
  const PurchaseTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            Purchase & Redemption Notification
            {getSingleCount("PURCHASE") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };
  const RequestTitle = () => {
    return (
      <>
        <div>
          <div className="position-relative">
            Request & Complaint Notification
            {getSingleCount("REQUEST") && (
              <span className="notification-red-dot"></span>
            )}
          </div>
        </div>
      </>
    );
  };

  const data = [
    {
      title: <AccountTitle />,
      bodyFunction: () => card_body("ACCOUNT"),
    },
    {
      title: <GeneralTitle />,
      bodyFunction: () => card_body("GENERAL"),
    },
    {
      title: <PurchaseTitle />,
      bodyFunction: () => card_body("PURCHASE"),
    },
    {
      title: <RequestTitle />,
      bodyFunction: () => card_body("REQUEST"),
    },
  ];

  const notificationCard = () => {
    return (
      <div className="card p-0">
        <header className="card-header">
          <p className="card-header-title">
            <span className="is-size-5">
              Notifications
            </span>{" "}
            <span className="tag customer-notification-icons is-rounded ml-2">
              {noteData?.total}
            </span>
          </p>
          <button className="card-header-icon" aria-label="more options">
            <span
              className="customer-notification-text"
              onClick={() => handleFormSubmit()}
            >
              Mark all as read
            </span>
            {/* <span className="icon ">
              <i className="fa fa-ellipsis-v gray-color" aria-hidden="true"></i>
            </span> */}
          </button>
        </header>
        <div className="card-content p-0">
          <div className="content customer-notification-body">
            <SmartAccordion data={data} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={
        "" + active ? "dropdown is-right  is-active" : "dropdown is-right "
      }
      key="s1"
    >
      <span
        className="icon dropdown-trigger customer-header-icon"
        onClick={() => setActive((active) => !active)}
      >
        <i
          className="fa fa-bell"
          aria-hidden="true"
          aria-controls="dropdown-menu"
        ></i>
        {noteData?.total > 0 && (
          <span className="user-bade">{noteData?.total}</span>
        )}
      </span>
      {active && (
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">{notificationCard()}</div>
        </div>
      )}
    </div>
  );
};

export default CustomerNotification;
