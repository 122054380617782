import { useNavigate } from "react-router-dom";

const CustomerDropDownMenu = ({ menu,user }) => {
  const navigate = useNavigate();
  const navigateLink = (index) => {
    navigate(index);
  };

  const isLoggedIn = (item) => {
    // console.log(item)
    if(item.isLoggedIn){
      return user && user.euserid ? true : false;
    }else{
      return true
    }
    //return true;
    
  };

  const menuDisplay = (item) => {
   // console.log("display items",item)
    return (
      <>
        {item.title.length > 1 && <p className="menu-label"> {isLoggedIn(item)  ?item.title:''}</p>}
        <ul className="menu-list ">
          {item?.menus.map((obj, index) => {
            return (  isLoggedIn(obj)  ?
             ( <li key={index}>
                <a href="#" onClick={() => navigateLink(obj.link)}>
                  {/* <i className={"fa " + obj.icon}></i> */}
            
                  <img className="drop-down-images mr-1" src={obj.icon} alt="" />
                  <span>{obj.title}</span>
                </a>
              </li>) : null
            );
          })}
        </ul>
      </>
    );
  };

  return (
    menu && (
      <div key="menu_one" className="menu mx-3">
        {menu.map((item, index) => {
          return (
            <>
              <div className={item?.class} key={index}>{menuDisplay(item)}</div>
            </>
          );
        })}
      </div>
    )
  );
};
export default CustomerDropDownMenu;
