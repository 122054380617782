import React, { useState } from "react";
import SideNavComponenet from "./SideNavComponenet";
import "./CustomerLayoutCss.css";
import { CROP_CARD, MY_ACCOUNT, SHOPPING_CART } from "../../services/ImageService";

const MobileSideNav = ({ToggleSidebar, isLoggedIn}) => {
  const [sidekey, setSideKey] = useState("1");

  // const setKey = (index) => {
  //   //console.log("side index " , index)
  //   setSideKey(index);
  // };


  const HomeItems = [
    {
      id: 1,
      label: "Home",
      link: "/",
      icon: <i class="fa fa-envelope" aria-hidden="true"></i>,
    },
    {
      id: 2,
      label: "Login",
      link: "/login",
      icon: <i class="fa fa-clock-o" aria-hidden="true"></i>,
      isLoggedIn: true,
    },

    {
      id: 2,
      label: "About Us",
      link: "/about-us-new",
      icon: <i class="fa fa-clock-o" aria-hidden="true"></i>,
    },
  ];

  const help_sub_nav = "/help/";

  const SupportItems = [
    {
      id: 1,
      label: "Contact Us",
      link: help_sub_nav + "contact-us",
      icon: <i class="fa fa-envelope" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
    {
      id: 2,
      label: "Claim Missing CROP",
      link: help_sub_nav + "claim-missing-crops",
      icon: <i class="fa fa-clock-o" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
    {
      id: 3,
      label: "Request or Complaint",
      link: help_sub_nav + "request-and-complaint",
      icon: <i class="fa fa-clock-o" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
  ];

  const FeedbackItems = [
    {
      id: 1,
      label: "Rate our Services",
      link: help_sub_nav + "feedback",
      icon: <i class="fa fa-comments" aria-hidden="true"></i>,
      isLoggedIn: false,
    },
    
  ];

  const InformationItems = [
    {
      id: 1,
      label: "Term and Conditions",
      link: help_sub_nav + "terms-conditions-new#",
      icon: <i class="fa fa-file-text-o" aria-hidden="true"></i>,
    },
    {
      id: 2,
      label: "Privacy and Data Rights",
      link: help_sub_nav + "privacy-data-rights-new#",
      icon: <i class="fa fa-universal-access" aria-hidden="true"></i>,
    },
    {
      id: 3,
      label: "Frequently Asked Questions",
      link: help_sub_nav + "FAQ-new#",
      icon: <i class="fa fa-question-circle-o" aria-hidden="true"></i>,
    },
  ];
  const more_sub_menu = "/more/";
  const MoreButtonItems = [
    // {
    //   id: 1,
    //   label: "Purchase CROPs",
    //   link: more_sub_menu + "purchase-crops",
    //   icon: <i class="fa fa-home" aria-hidden="true"></i>,
    // },
    {
      id: 2,
      label: "Get a Mate",
      link: more_sub_menu + "get-a-mate",
      icon: <i class="fa fa-star-o" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
  ];
  const crop_sub_menu = "/profile/";

  const crop_menu_data = [
     {
      id: 1,
      label: "Orders",
      link: crop_sub_menu + "my-orders",
      icon: <i class="fa fa-shopping-cart" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
    {
      id: 2,
      label: "My Account",
      link: crop_sub_menu + "my-account",
      icon: <i class="fa fa-th-large" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
    {
      id: 3,
      label: "CROP Card",
      link: crop_sub_menu + "my-crop-card",
      icon: <i class="fa fa-envelope" aria-hidden="true"></i>,
      isLoggedIn: true,
    },
  ];

  return (
    <>
      <div className="main-side-nave-items">
        {/** Home Button */}
          <div className="smart-close-icon-sideNav" onClick={ToggleSidebar}>
          <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        <>
          <SideNavComponenet
            key="1"
            open={sidekey === "1" ? true : false}
            menuName={"Home"}
            openNav={() => setSideKey("1")}
            navs={HomeItems}
          />
        </>
        <>
        {isLoggedIn() && (
          <SideNavComponenet
            key="6"
            open={sidekey === "3" ? true : false}
            openNav={() => setSideKey("6")}
            menuName={"Support"}
            navs={SupportItems}
          />
        )}
           {isLoggedIn() && (
             <SideNavComponenet
             key="7"
             open={sidekey === "4" ? true : false}
             openNav={() => setSideKey("7")}
             menuName={"Feedback"}
             navs={FeedbackItems}
           />
           )}
         
          <SideNavComponenet
            key="10"
            open={sidekey === "5" ? true : false}
            openNav={() => setSideKey("8")}
            menuName={"Important Information"}
            navs={InformationItems}
          />
        </>

        <>
        {isLoggedIn() && (
          <SideNavComponenet
            key="5"
            open={sidekey === "2" ? true : false}
            openNav={() => setSideKey("5")}
            menuName={"More"}
            navs={MoreButtonItems}
          />
        )}
        </>
        <>
        {isLoggedIn() && (
          <SideNavComponenet
            key="9"
            open={sidekey === "6" ? true : false}
            openNav={() => setSideKey("9")}
            menuName={"Crop"}
            navs={crop_menu_data}
          />
        )}
        </>
      </div>
    </>
  );
};

export default MobileSideNav;
