import React, { useEffect, useState, useRef } from "react";
import { post } from "../../../services/smartApiService";
import "./SmartFormElements.css";
const siteImages = {};
const SmartImageDisplayNew = React.memo((props) => {
  const { url = "", id = 0, imageName = "offer", imageClass } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  // Refs to store previous id and url
  const prevIdRef = useRef();
  const prevUrlRef = useRef();

  const img_class = ["image"];
  if (loading) {
    img_class.push("isLoading");
  } else {
    img_class.push("isLoaded");
  }
  if (imageClass) {
    img_class.push(imageClass);
  }

  const idCheck = imageName + "_" + id;

  const loadImageData = () => {
    const handleError = (errorMessage) => {
      setLoading(false);
      setData("");
    };
    const subscription = post(url, { id: id }, handleError).subscribe(
      (response) => {
        setLoading(false);
        let data_image =
          response.data.content !== undefined
            ? response.data.content
            : response.data;
        if (siteImages[idCheck] == undefined) {
          siteImages[idCheck] = data_image;
        }
        setData(data_image);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    // Get previous id and url
    const prevId = prevIdRef.current;
    const prevUrl = prevUrlRef.current;
    if (id > 0 && (prevId !== id || prevUrl !== url)) {
      //console.log("use entered indide effect called ", id);
      if (siteImages[idCheck]) {
        setData(siteImages[idCheck]);
        setLoading(false);
      } else {
        loadImageData();
      }
      // Update the refs with the current values after the comparison
      prevIdRef.current = id;
      prevUrlRef.current = url;
      // return unsubscribe;
    }
  }, [id, url]);

  const get_image = () => {
    return data && data.length > 17 ? `data:image/png;base64,${data}` : "";
  };

  return (
    <figure className={img_class.join(" ")}>
      {!loading && <img src={get_image()} alt="" />}
    </figure>
  );
});

export default SmartImageDisplayNew;
